import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";
import LocalizedText from "../reusable/LocalizedText";
import { IconButton } from "@material-ui/core";
import VirtualizedList from "../reusable/VirtualizedList";
import { getIntl } from "../../utils";
import QueuePartyOptionMenu from "./QAOptionMenu";
import Typography from "@material-ui/core/Typography";
import Draggable from "react-draggable";
import {
  setQAWindowExpand,
  promoteParty,
  removeParty,
  reorderParty
} from "./actions";
import SvgIcon from "../Icons/SvgIcon";
import { QAWindowPopoutState } from "./";

const styles = theme => ({
  alignItemsCenter: {
    alignItems: "center"
  },
  displayBlock: {
    display: "block"
  },
  displayFlex: {
    display: "flex"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  podiumPartyControl: {
    marginTop: "1px",
    marginBottom: "1px"
  },
  rowHeaderContainer: {
    width: "30px"
  },
  rowBodyContainer: {
    display: "flex",
    width: "100%",
    paddingLeft: "10px"
  },
  rowBodyActionIcon: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  queueContainer: {
    width: "100%",
    height: "350px"
  },
  queuePartyControl: {
    marginTop: "5px",
    marginBottom: "5px"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  podiumDivider: {
    marginRight: "18px"
  },
  icon: {
    height: "100%",
    width: "1.4em"
  },
  reorderIcon: {
    width: "0.7em"
  },
  iconButton: {
    width: "1.4em",
    padding: "6px"
  },
  cursorGrab: {
    cursor: "grab"
  },
  noSelect: {
    userSelect: "none"
  },
  talking: {
    color: theme.colors.talkingColor
  },
  flexGrow: {
    flexGrow: "1"
  },
  fullHeight: {
    height: "100%"
  },
  textUnselectable: {
    "&::-webkit-user-select": "none" /* Chrome all / Safari all */,
    "&::-moz-user-select": "none" /* Firefox all */,
    "&::-ms-user-select": "none" /* IE 10+ */,
    userSelect: "none" /* Likely future */
  }
});

const minVirtualListRowNum = 8;

class AudioQALightView extends Component {
  state = {
    anchorEl: null,
    selectedPartyId: null
  };

  componentDidUpdate() {
    this.forceUpdateVirtualizedListGrid();
  }

  getHorizontalDividerControl = () => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(classes.hrDivPrimary, classes.podiumDivider)}
      />
    );
  };

  //Podium controls
  getPodiumPartyActionsControl = (hasCurrentParty, hasNextParty) => {
    const { classes, qa, session, intl } = this.props;

    let disabled = session.mergedCallState !== "connected";

    const recallButton = (
      <IconButton
        className={classNames(classes.iconButton, classes.rowBodyActionIcon)}
        disabled
      >
        <SvgIcon iconName="qaRecall" color="inactive" />
      </IconButton>
    );

    return (
      <div>
        <IconButton
          className={classNames(classes.iconButton, classes.rowBodyActionIcon)}
          disabled={disabled || !hasCurrentParty}
          onClick={event => {
            this.props.removeParty(qa.floorPartyId, session.userId);
          }}
          title={intl.formatMessage(getIntl("removeFromPodium"))}
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName="qaRemoveParty"
            color={!disabled && hasCurrentParty ? "disconnect" : "inactive"}
          />
        </IconButton>
        <IconButton
          className={classNames(classes.iconButton, classes.rowBodyActionIcon)}
          disabled={disabled || !hasNextParty}
          onClick={this.promoteNextInQueue}
          title={intl.formatMessage(getIntl("next"))}
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName="qaNext"
            color={!disabled && hasNextParty ? "active" : "inactive"}
          />
        </IconButton>
        {false && recallButton}
      </div>
    );
  };

  getPodiumPartyControl = () => {
    const { classes, qa, participants, theme } = this.props;

    const { floorPartyId, floorPartyTalking } = qa;

    let podiumPartyObj;

    if (floorPartyId) {
      podiumPartyObj = participants.parties.find(
        partyObj => partyObj.id === floorPartyId
      );
    }

    let podiumPartyNameControl = (
      <Typography
        color="inherit"
        variant="body2"
        className={floorPartyTalking ? classes.talking : undefined}
      >
        {podiumPartyObj && this.restrainPartyNameLength(podiumPartyObj.name)}
      </Typography>
    );

    return (
      <div
        className={classNames(classes.displayFlex, classes.podiumPartyControl)}
      >
        <div className={classes.rowHeaderContainer}>
          <SvgIcon
            iconName="qaPodium"
            color={podiumPartyObj ? "active" : "inactive"}
            className={classes.icon}
          />
        </div>
        <div
          className={classNames(
            classes.rowBodyContainer,
            classes.alignItemsCenter
          )}
        >
          <div className={classes.displayFlex}>
            {podiumPartyObj ? (
              podiumPartyNameControl
            ) : (
              <LocalizedText
                className={classes.noSelect}
                value="podium"
                variant="body2"
                style={{ color: theme.colors.secondaryTextColor }}
              />
            )}
          </div>
          <div
            className={classNames(
              classes.displayFlex,
              classes.alignItemsCenter,
              classes.flexGrow,

              classes.justifyContentFlexEnd
            )}
          >
            {this.getPodiumPartyActionsControl(
              podiumPartyObj ? true : false,
              qa.queue.length > 0 ? true : false
            )}
          </div>
        </div>
      </div>
    );
  };

  getPodiumControl = () => {
    const { classes } = this.props;
    return (
      <div className={classes.displayFlexColumn}>
        {this.getHorizontalDividerControl()}
        {this.getPodiumPartyControl()}
        {this.getHorizontalDividerControl()}
      </div>
    );
  };

  //Queue controls
  getQueuePartyActionsControl = partyId => {
    const { classes, session, intl } = this.props;

    let disabled = session.mergedCallState !== "connected";

    return (
      <div
        className={classNames(
          classes.displayFlex,
          classes.flexGrow,
          classes.justifyContentFlexEnd
        )}
      >
        <IconButton
          className={classNames(classes.iconButton, classes.rowBodyActionIcon)}
          disabled={disabled}
          onClick={event => {
            this.props.promoteParty(partyId, session.userId);
          }}
          title={intl.formatMessage(getIntl("advanceToPodium"))}
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon iconName="qaPodium" color={disabled && "inactive"} />
        </IconButton>
        <IconButton
          className={classNames(classes.iconButton, classes.rowBodyActionIcon)}
          disabled={disabled}
          onClick={event => {
            this.handleQueuePartyDotsButtonOnClick(event, partyId);
          }}
          title={intl.formatMessage(getIntl("more"))}
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon iconName="qaDots" color={disabled && "inactive"} />
        </IconButton>
      </div>
    );
  };

  getQueuePartyControl = (
    index,
    isScrolling,
    isVisible,
    key,
    parent,
    style
  ) => {
    const { classes, qa, participants, session, intl } = this.props;
    const { queue } = qa;
    const partyId = queue[index];
    const partyObj = participants.parties.find(pty => pty.id === partyId);
    let disabled = session.mergedCallState !== "connected";
    const dragHandlers = {
      onStop: (event, ui) => this.onDragStop(event, ui, index)
    };

    return (
      <Draggable
        key={key}
        {...dragHandlers}
        position={{ x: 0, y: 0 }}
        handle="#dragIcon"
        disabled={disabled}
        axis="y"
      >
        <div
          style={style}
          className={classNames(classes.displayFlex, classes.queuePartyControl)}
        >
          <div
            id="dragIcon"
            className={classNames(
              classes.displayFlex,
              classes.justifyContentCenter,
              classes.rowHeaderContainer,
              !disabled ? classes.cursorGrab : undefined
            )}
            title={intl.formatMessage(getIntl("dragToReorder"))}
          >
            <SvgIcon
              iconName="qaLines"
              className={classes.reorderIcon}
              color={disabled && "inactive"}
            />
          </div>
          <div
            className={classNames(
              classes.rowBodyContainer,
              classes.alignItemsCenter
            )}
          >
            <Typography variant="body2" color="inherit">
              {this.restrainPartyNameLength(partyObj.name)}
            </Typography>
            {this.getQueuePartyActionsControl(partyId)}
          </div>
        </div>
      </Draggable>
    );
  };

  rowRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
    const { qa } = this.props;
    const { queue } = qa;

    if (index >= queue.length) {
      return <div key={key} style={style} />;
    } else {
      return this.getQueuePartyControl(
        index,
        isScrolling,
        isVisible,
        key,
        parent,
        style
      );
    }
  };

  getQueueControl = () => {
    const { classes, qa, qaPopoutWindow } = this.props;
    const { queue } = qa;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    return (
      <div
        className={classNames(
          classes.textUnselectable,
          qaPopoutVisible ? classes.fullHeight : classes.queueContainer
        )}
      >
        <VirtualizedList
          innerRef={r => (this.wrappedRef = r)}
          rowCount={
            queue.length >= minVirtualListRowNum
              ? queue.length
              : minVirtualListRowNum
          }
          rowHeight={40}
          rowRenderer={this.rowRenderer}
          noFocusOutline
        />
      </div>
    );
  };

  getPartyMoreActionsMenuControl = () => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <QueuePartyOptionMenu
        id="queue-party-action-menu"
        partyId={this.state.selectedPartyId}
        anchorEl={anchorEl}
        open={open}
        handleClose={() => {
          this.setState({ anchorEl: undefined });
        }}
      />
    );
  };

  //functions
  handleQueuePartyDotsButtonOnClick = (event, partyId) => {
    this.setState({
      anchorEl: event.currentTarget,
      selectedPartyId: partyId
    });
  };

  forceUpdateVirtualizedListGrid = () => {
    this.wrappedRef.forceUpdateGrid();
  };

  restrainPartyNameLength = partyName => {
    const { qa } = this.props;
    const { expand } = qa;

    if (expand) {
      if (partyName.length <= 25) {
        return partyName;
      } else {
        return partyName.substring(0, 25) + "..";
      }
    } else {
      if (partyName.length <= 30) {
        return partyName;
      } else {
        return partyName.substring(0, 30) + "..";
      }
    }
  };

  promoteNextInQueue = () => {
    const { qa, session } = this.props;
    const { queue } = qa;

    if (queue && queue.length > 0) {
      const partyId = queue[0];
      this.props.promoteParty(partyId, session.userId);
    }
  };

  //drag
  onDragStop = (mouseEvent, nodeUI, index) => {
    const { qa, session } = this.props;
    const { queue } = qa;
    let selectedPartyId = queue[index];
    let userId = session.userId;
    let destinationRowIndex = this.calculateFinalDragRowIndex(index, nodeUI.y);

    if (destinationRowIndex < 0) {
      destinationRowIndex = 0;
    } else if (destinationRowIndex >= queue.length) {
      destinationRowIndex = queue.length - 1;
    }

    if (destinationRowIndex === index) {
      return;
    }

    this.props.reorderParty(selectedPartyId, userId, destinationRowIndex + 1);
  };

  calculateFinalDragRowIndex = (itemIndex, deltaY) => {
    if (deltaY >= 0) {
      return itemIndex + Math.floor(deltaY / 40);
    } else {
      return itemIndex + Math.ceil(deltaY / 40);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classNames(classes.displayFlexColumn, classes.fullHeight)}
      >
        {this.getPodiumControl()}
        {this.getQueueControl()}
        {this.getPartyMoreActionsMenuControl()}
      </div>
    );
  }
}

const mapStateToProps = ({
  conference,
  qa,
  participants,
  session,
  qaPopoutWindow
}) => ({
  conference,
  qa,
  participants,
  session,
  qaPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  setQAWindowExpand: isExpanded => dispatch(setQAWindowExpand(isExpanded)),
  promoteParty: (partyId, userId) => dispatch(promoteParty(partyId, userId)),
  removeParty: (partyId, userId) => dispatch(removeParty(partyId, userId)),
  reorderParty: (partyId, userId, position) =>
    dispatch(reorderParty(partyId, userId, position))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(AudioQALightView))
  )
);
