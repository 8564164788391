import RoomClient from "./RoomClient";
import * as stateActions from "../../components/mediasoup/redux/stateActions";
import { setBlur } from "../../actions";
import UrlParse from "url-parse";
import deviceInfo from "../mediasoup/deviceInfo";
import MediaPipe from "./MediaPipe";
import VideoFilter from "./VideoFilter";

export default class RoomClientProvider {
  constructor(store) {
    this._store = store;
  }

  async init(peerId, userId, guid, sessionUsername) {
    const urlParser = new UrlParse(window.location.href, true);
    let roomId = guid;
    let displayName = sessionUsername;
    const handler = urlParser.query.handler;
    const useSimulcast = urlParser.query.simulcast !== "false";
    const useSharingSimulcast = urlParser.query.sharingSimulcast !== "false";
    const forceTcp = urlParser.query.forceTcp === "true";
    const produce = urlParser.query.produce !== "false";
    const consume = urlParser.query.consume !== "false";
    const forceH264 = urlParser.query.forceH264 === "true";
    const forceVP9 = urlParser.query.forceVP9 === "true";
    const svc = urlParser.query.svc;
    const datachannel = urlParser.query.datachannel !== "false";
    const info = urlParser.query.info === "true";
    const faceDetection = urlParser.query.faceDetection === "true";
    const externalVideo = urlParser.query.externalVideo === "true";
    const throttleSecret = urlParser.query.throttleSecret;

    // Enable face detection on demand.
    //if (faceDetection)
    //await faceapi.loadTinyFaceDetectorModel("/resources/face-detector-models");

    if (info) {
      // eslint-disable-next-line require-atomic-updates
      window.SHOW_INFO = true;
    }

    if (throttleSecret) {
      // eslint-disable-next-line require-atomic-updates
      window.NETWORK_THROTTLE_SECRET = throttleSecret;
    }

    const roomUrlParser = new UrlParse(window.location.href, true);

    for (const key of Object.keys(roomUrlParser.query)) {
      // Don't keep some custom params.
      switch (key) {
        case "roomId":
        case "handler":
        case "simulcast":
        case "sharingSimulcast":
        case "produce":
        case "consume":
        case "forceH264":
        case "forceVP9":
        case "forceTcp":
        case "svc":
        case "datachannel":
        case "info":
        case "faceDetection":
        case "externalVideo":
        case "throttleSecret":
          break;
        default:
          delete roomUrlParser.query[key];
      }
    }
    delete roomUrlParser.hash;

    const roomUrl = roomUrlParser.toString();

    let displayNameSet = true;

    // Get current device info.
    const device = deviceInfo();

    this._store.dispatch(stateActions.setRoomUrl(roomUrl));

    this._store.dispatch(stateActions.setRoomFaceDetection(faceDetection));

    this._store.dispatch(
      stateActions.setMe({ peerId, displayName, displayNameSet, device })
    );

    RoomClient.init({ store: this._store });

    this._roomClient = new RoomClient({
      roomId,
      peerId,
      userId,
      displayName,
      device,
      handlerName: handler,
      useSimulcast,
      useSharingSimulcast,
      forceTcp,
      produce,
      consume,
      forceH264,
      forceVP9,
      svc,
      datachannel,
      externalVideo,
      roomClientProvider: this
    });

    await this._roomClient.join();
  }

  close() {
    if (
      this._roomClient !== null &&
      this._store.getState().room.state === "connected"
    ) {
      this._roomClient.close();
      this._roomClient = null;
      this._store.dispatch(stateActions.setRoomUrl(null));
      this._store.dispatch(stateActions.setRoomState("closed"));
    }
  }

  async enableWebcam(deviceId) {
    if (this._roomClient) {
      await this._roomClient.enableWebcam(deviceId);
    }
  }

  async disableWebcam() {
    if (this._roomClient) {
      await this._roomClient.disableWebcam();
    }
  }

  async enableShare() {
    if (this._roomClient) {
      await this._roomClient.enableShare();
    }
  }

  async disableShare() {
    if (this._roomClient) {
      await this._roomClient.disableShare();
    }
  }

  setBlur(blur) {
    if (this._roomClient) {
      this._roomClient.setBlur(blur);
    } else {
      // The roomClient is not available on the login page
      this._store.dispatch(setBlur(blur));
    }
  }

  createVideoFilter(stream, blur) {
    if (!this._mediaPipe) {
      this._mediaPipe = new MediaPipe();
    }

    return new VideoFilter(stream, blur, this._mediaPipe);
  }
}
