import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import AudioComponent from "../Audio Connection/AudioComponent";
import CTXBasePopper from "../reusable/CTXBasePopper";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import SvgIcon from "../Icons/SvgIcon";
import { getIntl, TitleNewLine } from "../../utils";
import { injectIntl } from "react-intl";
import { setConnectPopperExpansion } from "../../actions";

const styles = theme => ({
  ...theme.style.rightBarPopper,
  layout: {
    ...theme.style.rightBarPopper.layout,
    width: "330px",
    minHeight: "120px"
  }
});

class ConnectPopper extends Component {
  state = {
    anchorEl: null
  };

  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      anchorEl: this.iconRef.current
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let previousMergedCallState = prevProps.session.mergedCallState;
    let mergedCallState = this.props.session.mergedCallState;

    if (
      previousMergedCallState !== "connected" &&
      mergedCallState === "connected"
    ) {
      this.closePopper();
    }
  }

  closePopper = () => {
    this.props.setConnectPopperExpansion(false);
  };

  handleClick = () => {
    this.props.setConnectPopperExpansion(true);
  };

  handleClickAway = () => {
    this.closePopper();
  };

  render() {
    const { classes, intl, session } = this.props;
    const { anchorEl } = this.state;
    const open =
      anchorEl != null && this.props.popper.connect.expandConnectPopper;
    const id = open ? "connectPopper" : null;

    let callConnected = session.mergedCallState === "connected";
    let callConnecting = session.mergedCallState === "connecting";

    return (
      <Fragment>
        <IconButton
          id="connectPopperButton"
          ref={this.iconRef}
          className={classes.popperIconButton}
          onClick={this.handleClick}
          title={
            intl.formatMessage(getIntl("audioConnectionControl")) +
            TitleNewLine +
            (callConnected
              ? intl.formatMessage(getIntl("currentlyConnected")) +
                TitleNewLine +
                intl.formatMessage(getIntl("clickToDisconnectYourAudio"))
              : intl.formatMessage(getIntl("currentlyDisconnected")) +
                TitleNewLine +
                intl.formatMessage(getIntl("clickToConnectYourAudio")))
          }
        >
          {callConnected || callConnecting ? (
            <SvgIcon iconName="disconnectCall" color="disconnect" />
          ) : (
            <SvgIcon iconName="connectCall" color="connect" />
          )}
        </IconButton>

        <CTXBasePopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.layout}
          closePopper={this.handleClickAway}
        >
          <Fragment>
            <AudioComponent
              mergedCallState={session.mergedCallState}
              onClick={this.handleClickAway}
            />
          </Fragment>
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, popper, conference }) => ({
  session,
  popper,
  conference
});

const mapDispatchToProps = dispatch => ({
  setConnectPopperExpansion: payload =>
    dispatch(setConnectPopperExpansion(payload))
});

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConnectPopper))
);
