import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import CTXTable from "../reusable/CTXTable";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  getPortalHostBillingConferences,
  getPortalHostBillingUsers,
  getPortalHostBillingFeatureActions,
  resetPortalBillingConferences,
  setPortalReportDateFrom,
  setPortalReportDateTo,
  resetPortalBillingUsers,
  resetPortalBillingFeatureActions
} from "../../actions";

const styles = theme => ({});

class PortalReportConferenceTable extends Component {
  constructor(props) {
    super(props);
    this.moment = require("moment-timezone");
    this.timezone = this.moment.tz.guess();
    this.sortDateTime = this.sortDateTime.bind(this);
  }

  componentDidMount() {
    let currentDateTime = new Date();
    currentDateTime.setHours(0);
    currentDateTime.setMinutes(0);
    currentDateTime.setSeconds(0);

    let previousDateTime = new Date(
      currentDateTime.getTime() - 30 * 24 * 60 * 60 * 1000
    );

    let currentUTCString = this.getMySqlUTCDateTimeFormat(currentDateTime);
    let previousUTCString = this.getMySqlUTCDateTimeFormat(previousDateTime);

    this.props.setPortalReportDateFrom(previousUTCString);
    this.props.setPortalReportDateTo(currentUTCString);

    let currentUTCQueryString = this.getMySqlUTCDateTimeFormat(
      new Date(currentDateTime.getTime() + 24 * 60 * 60 * 1000)
    );

    this.getHostConferenceList(
      this.props.session.portalSessionId,
      previousUTCString,
      currentUTCQueryString
    );
  }

  componentWillUnmount() {
    this.props.resetPortalBillingConferences();
    this.props.setPortalReportDateFrom(undefined);
    this.props.setPortalReportDateTo(undefined);
  }

  getMySqlUTCDateTimeFormat = date => {
    return (
      date.getUTCFullYear().toString() +
      "-" +
      (date.getUTCMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getUTCDate().toString().padStart(2, "0") +
      " " +
      date.getUTCHours().toString().padStart(2, "0") +
      ":" +
      date.getUTCMinutes().toString().padStart(2, "0") +
      ":" +
      date.getUTCSeconds().toString().padStart(2, "0")
    );
  };

  getHostConferenceList = (sessionId, startDateTime, endDateTime) => {
    this.props.getPortalHostBillingConferences(
      sessionId,
      startDateTime,
      endDateTime
    );
  };

  convertDuration = seconds => {
    //output hh:mm:ss
    let resHH = Math.floor(seconds / 60 / 60);
    let resMM = Math.floor((seconds - 3600 * resHH) / 60);
    let resSS = (seconds - 3600 * resHH - 60 * resMM).toFixed(0);

    return (
      resHH.toString().padStart(2, "0") +
      ":" +
      resMM.toString().padStart(2, "0") +
      ":" +
      resSS.toString().padStart(2, "0")
    );
  };

  getTableData = reportConferences => {
    var getTableDataResult = [];

    if (reportConferences == null) {
      return getTableDataResult;
    }

    for (var key in reportConferences) {
      let tableRowData = {};

      //undefined endTime means conference is still active
      if (reportConferences[key].endTime == null) {
        continue;
      }

      //redux array key
      tableRowData.reduxKey = key;
      //conferenceKey
      tableRowData.conferenceKey = reportConferences[key].conferenceKey;
      //Title
      tableRowData.conferenceName = reportConferences[key].conferenceName;
      //Duration
      let startDateTime = new Date(
        reportConferences[key].startTime.replace(" ", "T")
      );
      let endDateTime = new Date(
        reportConferences[key].endTime.replace(" ", "T")
      );
      let durationDiff =
        (endDateTime.getTime() - startDateTime.getTime()) / 1000;
      tableRowData.duration = this.convertDuration(durationDiff);
      //Recording
      tableRowData.recording = reportConferences[key].recordingUsed;
      //Sharing
      tableRowData.sharing = reportConferences[key].sharingUsed;
      //Convert UTC datetime to local datetime
      let utcStartDateTime = new Date(
        reportConferences[key].startTime.replace(" ", "T") + ".000+00:00"
      );
      //Date Time
      tableRowData.dateTime = utcStartDateTime.toLocaleString();

      getTableDataResult.push(tableRowData);
    }

    return getTableDataResult;
  };

  handleOnRowClick = (event, rowData) => {
    this.props.getPortalHostBillingUsers(
      this.props.session.portalSessionId,
      rowData.conferenceKey
    );

    this.props.getPortalHostBillingFeatureActions(
      this.props.session.portalSessionId,
      rowData.conferenceKey
    );
  };

  handleRefreshButtonClick = () => {
    this.props.resetPortalBillingConferences();
    this.props.resetPortalBillingUsers();
    this.props.resetPortalBillingFeatureActions();

    let reportToDate = new Date(
      this.props.portal.reportDateTo.split(" ")[0] +
        "T" +
        this.props.portal.reportDateTo.split(" ")[1] +
        ".000+00:00"
    );

    let queryToString = this.getMySqlUTCDateTimeFormat(
      new Date(reportToDate.getTime() + 1000 * 60 * 60 * 24)
    );

    this.getHostConferenceList(
      this.props.session.portalSessionId,
      this.props.portal.reportDateFrom,
      queryToString
    );
  };

  handleConferenceDatePickerFromChange = localDateTime => {
    if (localDateTime == null) {
      return;
    }
    let utcDateTime = this.getMySqlUTCDateTimeFormat(localDateTime);
    this.props.setPortalReportDateFrom(utcDateTime);
  };

  handleConferenceDatePickerToChange = localDateTime => {
    if (localDateTime == null) {
      return;
    }
    let utcDateTime = this.getMySqlUTCDateTimeFormat(localDateTime);
    this.props.setPortalReportDateTo(utcDateTime);
  };

  sortDateTime = (object1, object2) => {
    let key1 = object1.reduxKey;
    let key2 = object2.reduxKey;

    let date1 = new Date(
      this.props.portal.reportConferences[key1].startTime.replace(" ", "T") +
        ".000+00:00"
    );
    let date2 = new Date(
      this.props.portal.reportConferences[key2].startTime.replace(" ", "T") +
        ".000+00:00"
    );

    if (date1 >= date2) {
      return 1;
    } else {
      return -1;
    }
  };

  render() {
    const { translations } = this.props.languages;
    const tableTitle = translations.pastMeetings;
    const tableColumns = [
      { title: "Redux Key", field: "reduxKey", hidden: true },
      { title: "Conference Key", field: "conferenceKey", hidden: true },
      {
        title:
          translations.dateTime +
          " ( " +
          this.moment().tz(this.timezone).format("z") +
          " )",
        field: "dateTime",
        type: "datetime",
        customSort: this.sortDateTime
      },
      { title: translations.title, field: "conferenceName" },
      { title: translations.duration, field: "duration" },
      {
        title: translations.recording,
        field: "recording",
        type: "boolean",
        width: "50px"
      },
      {
        title: translations.sharing,
        field: "sharing",
        type: "boolean",
        width: "50px"
      }
    ];

    let tableData = this.getTableData(this.props.portal.reportConferences);

    let reportFromDate = this.props.portal.reportDateFrom
      ? new Date(
          this.props.portal.reportDateFrom.replace(" ", "T") + ".000+00:00"
        )
      : undefined;
    let reportToDate = this.props.portal.reportDateTo
      ? new Date(
          this.props.portal.reportDateTo.replace(" ", "T") + ".000+00:00"
        )
      : undefined;

    return (
      <CTXTable
        title={tableTitle}
        columns={tableColumns}
        data={tableData}
        onRowClick={this.handleOnRowClick}
        maxBodyHeight={this.props.maxBodyHeight}
        displayDatePickers={true}
        handleRefreshButtonClick={this.handleRefreshButtonClick}
        CTXTableDatePickFromValue={reportFromDate}
        CTXTableDatePickToValue={reportToDate}
        onChangeDatePickerFrom={this.handleConferenceDatePickerFromChange}
        onChangeDatePickerTo={this.handleConferenceDatePickerToChange}
        enableSelectedRowStyle
        enableDownloadIcon
      />
    );
  }
}

const mapStateToProps = ({ portal, session, languages, theme }) => ({
  portal,
  session,
  languages,
  theme
});

const mapDispatchToProps = dispatch => ({
  getPortalHostBillingConferences: (sessionId, startDateTime, endDateTime) =>
    dispatch(
      getPortalHostBillingConferences(sessionId, startDateTime, endDateTime)
    ),
  getPortalHostBillingUsers: (sessionId, conferenceKey) =>
    dispatch(getPortalHostBillingUsers(sessionId, conferenceKey)),
  getPortalHostBillingFeatureActions: (sessionId, conferenceKey) =>
    dispatch(getPortalHostBillingFeatureActions(sessionId, conferenceKey)),
  resetPortalBillingConferences: () =>
    dispatch(resetPortalBillingConferences()),
  setPortalReportDateFrom: date => dispatch(setPortalReportDateFrom(date)),
  setPortalReportDateTo: date => dispatch(setPortalReportDateTo(date)),
  resetPortalBillingUsers: () => dispatch(resetPortalBillingUsers()),
  resetPortalBillingFeatureActions: () =>
    dispatch(resetPortalBillingFeatureActions())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(PortalReportConferenceTable)
    )
  )
);
