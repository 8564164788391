import { QAWindowState } from "./HostQAWindow";
import contexWebRest from "../../api/contexWebRest";

//-----------Action types----------//

export const SET_QA_WINDOW_VISIBILITY = "SET_QA_WINDOW_VISIBILITY";
export const UPDATE_QA_QUEUE = "UPDATE_QA_QUEUE";
export const UPDATE_QA_PARTY_STATE = "UPDATE_QA_PARTY_STATE";
export const SET_QA_WINDOW_EXPAND = "SET_QA_WINDOW_EXPAND";
export const SET_QA_POPPER_OPEN_STATE = "SET_QA_POPPER_OPEN_STATE";
export const SET_QA_POPPER_CONTENTS = "SET_QA_POPPER_CONTENTS";
export const SET_DATA_QA_STATE = "SET_DATA_QA_STATE";
export const UPDATE_DATA_QA_ENTRY = "UPDATE_DATA_QA_ENTRY";
export const REMOVE_DATA_QA_ENTRY = "REMOVE_DATA_QA_ENTRY";
export const SET_QA_WINDOW_TAB_INDEX = "SET_QA_WINDOW_TAB_INDEX";
export const RESET_UNREAD_DATA_QA_ENTRY = "RESET_UNREAD_DATA_QA_ENTRY";

//---------Action creators-------------//

export const setQAWindowStatus = isVisible => {
  return {
    type: SET_QA_WINDOW_VISIBILITY,
    payload: isVisible ? QAWindowState.OPEN : QAWindowState.CLOSE
  };
};

export const setQAWindowExpand = isExpanded => {
  return {
    type: SET_QA_WINDOW_EXPAND,
    payload: isExpanded
  };
};

export const promoteParty = (partyId, userId) => async dispatch => {
  const uri = "/qa/" + partyId + "/qa_promote/" + userId;
  const requestBody = "reqSeq=1";
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const removeParty = (partyId, userId) => async dispatch => {
  const uri = "/qa/" + partyId + "/qa_remove/" + userId;
  const requestBody = "reqSeq=1";
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const reorderParty = (partyId, userId, position) => async dispatch => {
  const uri = "/qa/" + partyId + "/qa_reorder/" + userId;
  const requestBody = `reqSeq=1&position=${position}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const startAudioQA = userId => async dispatch => {
  const uri = "/conference/start_qa/" + userId;
  const requestBody = `reqSeq=1`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const stopAudioQA = userId => async dispatch => {
  const uri = "/conference/stop_qa/" + userId;
  const requestBody = `reqSeq=1`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const announcePartyNameToConference = (
  partyId,
  userId
) => async dispatch => {
  const uri = `/party/${partyId}/ivr_partyname_to_conf/${userId}`;
  const requestBody = `reqSeq=1`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const setQAPopperOpenState = isOpen => {
  return {
    type: SET_QA_POPPER_OPEN_STATE,
    payload: isOpen
  };
};

export const setQAPopperContents = contents => {
  return {
    type: SET_QA_POPPER_CONTENTS,
    payload: contents
  };
};

export const setQAWindowTabIndex = index => {
  return {
    type: SET_QA_WINDOW_TAB_INDEX,
    payload: index
  };
};

export const startWebQA = userId => async dispatch => {
  const uri = "/conference/start_data_qa/" + userId;
  const requestBody = `reqSeq=1`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const stopWebQA = userId => async dispatch => {
  const uri = "/conference/stop_data_qa/" + userId;
  const requestBody = `reqSeq=1`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const handleQuestionSend = (userId, question) => async dispatch => {
  const uri = "/conference/add_data_qa_question/" + userId;
  const encodedQuestion = encodeURIComponent(question);
  const requestBody = `reqSeq=1&question=${encodedQuestion}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const handleQuestionRemove = (userId, questionId) => async dispatch => {
  const uri = "/conference/remove_data_qa_question/" + userId;
  const requestBody = `reqSeq=1&questionId=${questionId}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const handleAnswerSend = (
  userId,
  questionId,
  answer
) => async dispatch => {
  const encodedAnswer = encodeURIComponent(answer);
  const uri = "/conference/add_data_qa_answer/" + userId;
  const requestBody = `reqSeq=1&questionId=${questionId}&answer=${encodedAnswer}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const handleAnswerDelete = (
  userId,
  questionId,
  answerId
) => async dispatch => {
  const uri = "/conference/remove_data_qa_answer/" + userId;
  const requestBody = `reqSeq=1&questionId=${questionId}&answerId=${answerId}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const handleAnswerEdit = (
  userId,
  questionId,
  answerId,
  answer
) => async dispatch => {
  const uri = "/conference/edit_data_qa_answer/" + userId;
  const encodedAnswer = encodeURIComponent(answer);
  const requestBody = `reqSeq=1&questionId=${questionId}&answerId=${answerId}&answer=${encodedAnswer}`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  try {
    let response = await contexWebRest.post(uri, requestBody, {
      headers: headers
    });

    console.log(`${uri} response:`, response);
  } catch (error) {
    console.log(error);
  }
};

export const resetUnreadDataQA = () => async dispatch => {
  dispatch({ type: RESET_UNREAD_DATA_QA_ENTRY });
};
