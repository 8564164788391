import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FormattedMessage } from "react-intl";
import AppLogo from "../Icons/AppLogo";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import { IconButton } from "@material-ui/core";
import Peer from "../mediasoup/components/Peer";
import Me from "../mediasoup/components/Me";
import { withRoomContext } from "../mediasoup/RoomContext";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "330px",
    color: theme.colors.primaryBackgroundImageTextColor
  },
  logo: {
    maxHeight: "200px",
    width: "100%",
    objectFit: "contain",
    marginBottom: "20px"
  },
  sharingDiv: {
    height: "100%",
    width: "100%"
  },
  sharedView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    margin: "auto"
  },
  textIcon: {
    width: "1em"
  },
  greetingContainer: {
    padding: "5px",
    fontSize: "large",
    width: "max-content",
    backgroundColor: `${theme.colors.sideBarBackgroundColor}`,
    color: `${theme.colors.primaryTextColor}`,
    border: `2px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "10px"
  },
  sharerView: {
    position: "absolute",
    height: "200px",
    width: "400px",
    right: "64px",
    top: "64px"
  }
});

class HostGreeting extends Component {
  handleShareClick = () => {
    this.props.roomClientProvider.enableShare();
  };

  render() {
    const {
      classes,
      session,
      screenSharePeer,
      screenShareProducer,
      screenShareConsumer
    } = this.props;
    const isSharingActive = session.sharingActive;
    const isSharer = session.isSharer;

    if (isSharingActive && !isSharer && screenShareConsumer) {
      return <Peer id={screenSharePeer.id} screenShare />;
    } else if (isSharingActive && isSharer && screenShareProducer) {
      return (
        <>
          <div className={classes.sharerView}>
            <Me screenShare />
          </div>
          <div className={classes.root}>
            <AppLogo classes={{ root: classes.logo }} logoType="body" />
            <LocalizedText
              value="sharerGreeting"
              className={classes.greetingContainer}
            />
          </div>
        </>
      );
    } else {
      return (
        <div className={classes.root}>
          <AppLogo classes={{ root: classes.logo }} logoType="body" />
          <Typography
            color="inherit"
            className={classes.greetingContainer}
            variant="body2"
          >
            {session.recorder ? (
              session.confName
            ) : (
              <>
                <FormattedMessage id="click" defaultMessage="???" />
                <IconButton
                  disabled={false}
                  onClick={() => this.handleShareClick()}
                >
                  <SvgIcon iconName="share" className={classes.textIcon} />
                </IconButton>
                <FormattedMessage id="toShare" defaultMessage="???" />
              </>
            )}
          </Typography>
        </div>
      );
    }
  }
}

const mapStateToProps = ({ session, peers, producers, consumers }) => {
  const producersArray = Object.values(producers);
  const screenShareProducer = producersArray.find(
    producer => producer.track.kind === "video" && producer.screenShare
  );

  let screenShareConsumer;
  const screenSharePeer = peers[session.sharingUserId];
  if (screenSharePeer != null) {
    const consumersArray = screenSharePeer.consumers.map(
      consumerId => consumers[consumerId]
    );
    screenShareConsumer = consumersArray.find(
      consumer => consumer.track.kind === "video" && consumer.screenShare
    );
  }

  return {
    session,
    screenSharePeer,
    screenShareProducer,
    screenShareConsumer
  };
};

export default withStyles(styles)(
  withRouter(withRoomContext(connect(mapStateToProps)(HostGreeting)))
);
