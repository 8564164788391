import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";

import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import contexWebRest from "../../api/contexWebRest";
import LocalizedText from "../reusable/LocalizedText";
import CTXBasePopper from "../reusable/CTXBasePopper";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine, isConnected } from "../../utils";
import { setAudioPopperExpansion } from "../../actions";

const styles = theme => ({
  ...theme.style.rightBarPopper,
  textIcon: {
    width: "1em"
  }
});

class AudioPopper extends Component {
  state = {
    anchorEl: null
  };

  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      anchorEl: this.iconRef.current
    });
  }

  getPopperIconButton = () => {
    const { classes, intl } = this.props;
    const { callState } = this.props.session;
    const connected = isConnected(callState);
    console.log("AudioPopper", callState, connected);

    let title = intl.formatMessage(getIntl("audioControl")) + TitleNewLine;

    let iconName;
    if (callState === "TalkListen" || callState === "TalkOperator") {
      title += intl.formatMessage(getIntl("currentlyUnmuted")) + TitleNewLine;
      iconName = "audioUnmute";
    } else {
      title += intl.formatMessage(getIntl("currentlyMuted")) + TitleNewLine;
      iconName = "audioMute";
    }

    let disabled;
    let style;
    let click;
    let color;
    if (callState === "Monitor" || callState === "TalkListen") {
      if (callState === "TalkListen") {
        title += intl.formatMessage(getIntl("clickToMuteYourAudio"));
        click = () => this.handleMute();
      } else {
        title += intl.formatMessage(getIntl("clickToUnmuteYourAudio"));
        click = () => this.handleUnmute();
      }
      color = "active";
    } else {
      title += intl.formatMessage(getIntl("controlDisabled"));
      if (connected) {
        disabled = true;
        style = { pointerEvents: "auto" };
      } else {
        click = event => this.handleClickPopper(event);
      }
      color = "inactive";
    }

    return (
      <IconButton
        id="audioPopperButton"
        ref={this.iconRef}
        className={classes.popperIconButton}
        title={title}
        disabled={disabled}
        style={style}
        onClick={click}
      >
        <SvgIcon iconName={iconName} color={color} />
      </IconButton>
    );
  };

  handleMute = () => {
    const { session } = this.props;
    this.handleParticipantApiRequest(session.partyID, session.userId, "mute");
  };

  handleUnmute = () => {
    const { session } = this.props;
    this.handleParticipantApiRequest(session.partyID, session.userId, "unmute");
  };

  handleParticipantApiRequest = (partyIds, userId, apiEndpoint) => {
    console.log(`party ${apiEndpoint}`, partyIds, userId);
    const uri = `/party/${apiEndpoint}/${userId}`;
    const requestBody = `partyIds[]=${partyIds}&reqSeq=1`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    let response;
    try {
      response = contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      console.log(`${uri} response:`, response);
    } catch (error) {
      console.log(error);
    }
  };

  handleClickPopper = event => {
    this.props.setAudioPopperExpansion(true);
  };

  handleConnectClick = () => {
    this.closePopper();
    //TODO - not very react-like
    document.getElementById("connectPopperButton").click();
  };

  closePopper = () => {
    this.props.setAudioPopperExpansion(false);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.session.callState !== prevProps.session.callState &&
      isConnected(this.props.session.callState)
    ) {
      this.closePopper();
    }
  }

  render() {
    const { classes, popper } = this.props;
    const { anchorEl } = this.state;
    const open = anchorEl != null && popper.audio.expandAudioPopper;

    return (
      <Fragment>
        {this.getPopperIconButton()}

        <CTXBasePopper
          id="audioPopper"
          open={open}
          anchorEl={anchorEl}
          closePopper={this.closePopper}
        >
          <LocalizedText
            value="noAudioDetected"
            variant="h6"
            className={classes.header}
          />
          <div className={classes.content}>
            <LocalizedText value="click" inline="true" />
            <IconButton
              disabled={false}
              onClick={() => this.handleConnectClick()}
            >
              <SvgIcon
                iconName="connectCall"
                color="connect"
                className={classes.textIcon}
              />
            </IconButton>
            <LocalizedText value="toGetConnected" inline="true" />
          </div>
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, conference, popper }) => ({
  session,
  conference,
  popper
});

const mapDispatchToProps = dispatch => ({
  setAudioPopperExpansion: isOpen => dispatch(setAudioPopperExpansion(isOpen))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(AudioPopper))
  )
);
