import { ActiveSessionType, SessionState } from "./components/QA/HostQAWindow";
import { isIE, isMobile, isLegacyEdge, isFirefox } from "react-device-detect";
import Logger from "./Logger";

const logger = new Logger("Utils");

export const getUrlParam = paramName => {
  var results = new RegExp("[?&]" + paramName + "=([^&#]*)").exec(
    window.location.href
  );
  if (results == null) {
    return null;
  } else {
    return decodeURI(results[1]) || 0;
  }
};

export const isBlank = value => {
  return value === undefined || value === null || value === "";
};

export const SortDirection = {
  ASCENDING: "ASC",
  DESCENDING: "DESC",
  TOGGLE: "TOG"
};

export const getIntl = id => {
  return {
    id: id,
    defaultMessage: "???"
  };
};

export const TitleNewLine = "\n - ";

export const isHost = session => session.userLevel === "1";

export const redirectUnsupportedBrowsers = () => {
  if (isIE || isLegacyEdge) {
    if (window.location.pathname !== "/notsupported") {
      window.location.replace(
        window.location.protocol + "//" + window.location.host + "/notsupported"
      );
    }
  }
};

export const isWebRTCAudioEnabled = sessionWebRTCCallConfig => {
  return (
    !isMobileOrTablet() &&
    !isFirefox &&
    navigator.mediaDevices.getUserMedia &&
    RTCPeerConnection &&
    window.CtxAppConfigurations.customer !== "TCAD" &&
    sessionWebRTCCallConfig
  );
};

export const isMobileOrTablet = () => {
  return isMobile;
};

export const isAudioConnected = session => {
  // The MuteOperator and TalkOperator states are not included
  return (
    session.callState !== undefined &&
    (session.callState === "Monitor" ||
      session.callState === "TalkListen" ||
      session.callState === "MusicHold" ||
      session.callState === "OnHold")
  );
};

export const isConnected = callState => {
  return (
    callState &&
    callState !== "NotConnected" &&
    callState !== "Disconnecting" &&
    callState !== "Connecting" &&
    callState !== "Removed"
  );
};

export const isConnectedToOperator = callState => {
  return callState === "MuteOperator" || callState === "TalkOperator";
};

export const isHoldStateApplicable = (conference, session) => {
  return (
    window.CtxAppConfigurations.customer !== "TCAD" &&
    !conference.quickStart &&
    !session.hasHostJoined
  );
};

export const convertUTCtoLocalTime = UTCDateTimeString => {
  //Convert UTC datetime to local datetime
  if (UTCDateTimeString != null) {
    let utcDateObj = new Date(
      UTCDateTimeString.replace(" ", "T") + ".000+00:00"
    );

    return utcDateObj.toLocaleString();
  } else {
    return "";
  }
};

export const isQAActive = conference => {
  const { sessionState, activeSessionType } = conference;

  if (
    activeSessionType === ActiveSessionType.QAACTIVE &&
    sessionState === SessionState.IN_PROGRESS
  ) {
    return true;
  } else {
    return false;
  }
};

export const getAvatarString = username => {
  if (username != null && username.trim().length !== 0) {
    const words = username.trim().split(" ");

    if (words.length <= 1) {
      return words[0].charAt(0);
    } else {
      return words[0].charAt(0) + words[words.length - 1].charAt(0);
    }
  } else {
    return "???";
  }
};

/*
  Get the best item bounds to fit in the container. Param object must have
  width, height, itemCount, aspectRatio and maxCols. The itemCount
  must be greater than 0. Result is single object with rowCount, colCount,
  itemWidth, and itemHeight.
*/
export const getBestItemBounds = config => {
  let usableWidth = config.width - 8;
  let usableHeight = config.height - 8;

  const actualRatio = usableWidth / usableHeight;

  // Just make up theoretical sizes, we just care about ratio
  const theoreticalHeight = 100;
  const theoreticalWidth = theoreticalHeight * config.aspectRatio;
  // Go over each row count find the row and col count with the closest ratio.
  let bestWidth = 0;
  for (let colCount = 1; colCount <= config.maxCols; colCount++) {
    // Row count can't be higher than item count
    if (colCount > config.itemCount) continue;
    const rowCount = Math.ceil(config.itemCount / colCount);
    // Get the width/height ratio
    const ratio =
      (theoreticalWidth * colCount) / (theoreticalHeight * rowCount);
    let itemHeight;
    let itemWidth;

    // Build item height and width. If the best ratio is less than the actual
    // ratio, it's the height that determines the width, otherwise vice versa.
    if (ratio < actualRatio) {
      itemHeight = usableHeight / rowCount;
      itemWidth = itemHeight * config.aspectRatio;
    } else {
      itemWidth = usableWidth / colCount;
      itemHeight = itemWidth / config.aspectRatio;
    }
    if (itemWidth > bestWidth) {
      bestWidth = itemWidth;
    }
  }

  if (bestWidth < 176) {
    bestWidth = 176;
  }

  return {
    itemWidth: bestWidth,
    itemHeight: bestWidth / config.aspectRatio
  };
};

export const getBuildVersion = includeHash => {
  let softwareVersion = window.ConnectNowVersion.versionNumber;
  let buildDate = window.ConnectNowVersion.buildDate;
  let commitHash = window.ConnectNowVersion.gitHash;

  let buildVersion = softwareVersion + " - " + buildDate;
  if (includeHash) {
    buildVersion += " " + commitHash;
  }

  return buildVersion;
};

export const isVideoEnabled = sessionVideoConfig => {
  return (
    window.CtxAppConfigurations.videoLicenseActive &&
    (window.CtxAppConfigurations.customer === "TCAD" || sessionVideoConfig)
  );
};

export const getDevices = async props => {
  let devices = await navigator.mediaDevices.enumerateDevices();

  let inputError;
  let outputError;
  let audioInputDevices = [];
  let audioOutputDevices = [];
  let storedInputFound = false;
  let storedOutputFound = false;
  for (var i = 0; i < devices.length; i++) {
    var device = devices[i];
    logger.debug("%s:%s:%s", device.deviceId, device.label, device.kind);
    if (device.kind === "audioinput") {
      if (device.deviceId === "") {
        inputError = "noPermission";
      } else {
        audioInputDevices.push(device);
        if (device.deviceId === localStorage.getItem("audioInput")) {
          storedInputFound = true;
        }
      }
    } else if (device.kind === "audiooutput") {
      if (device.deviceId === "") {
        outputError = "noPermission";
      } else {
        audioOutputDevices.push(device);
        if (device.deviceId === localStorage.getItem("audioOutput")) {
          storedOutputFound = true;
        }
      }
    }
  }

  if (inputError == null && audioInputDevices.length === 0) {
    inputError = "noDevices";
  }
  if (outputError == null && audioOutputDevices.length === 0) {
    let audioElement = document.getElementById("remoteMedia");
    if (audioElement.setSinkId != null) {
      outputError = "noDevices";
    } else {
      // Some browsers like Chrome iOS, Firefox, and Safari do not enumerate output devices
      outputError = "enumeratingNotSupported";
    }
  }

  if (storedInputFound) {
    props.setAudioInput(localStorage.getItem("audioInput"));
  } else if (!inputError) {
    props.setAudioInput(audioInputDevices[0].deviceId);
  }
  if (storedOutputFound) {
    props.setAudioOutput(localStorage.getItem("audioOutput"));
  } else if (!outputError) {
    props.setAudioOutput(audioOutputDevices[0].deviceId);
  }

  return {
    audioInputDevices: audioInputDevices,
    audioOutputDevices: audioOutputDevices,
    inputError: inputError,
    outputError: outputError
  };
};

export const getFullscreenElement = () =>
  document.fullscreenElement || document.webkitFullscreenElement;

export const requestFullscreen = () => {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen();
  }
};

export const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};

// This method is based on format-util.
// https://github.com/tmpfs/format-util
export function format(fmt) {
  var re = /(%?)(%([ojdsc]))/g,
    args = Array.prototype.slice.call(arguments, 1);
  if (args.length) {
    fmt = fmt.replace(re, function (match, escaped, ptn, flag) {
      var arg = args.shift();
      switch (flag) {
        case "o":
        case "j":
          try {
            if (arg instanceof Error) {
              arg = JSON.stringify(arg, Object.getOwnPropertyNames(arg));
            } else {
              arg = JSON.stringify(arg);
            }
          } catch (error) {
            arg = "[Circular]";
          }
          break;
        case "s":
          arg = "" + arg;
          break;
        case "d":
          arg = Number(arg);
          break;
        default:
          // Ignore %c directives
          arg = "";
          break;
      }
      if (!escaped) {
        return arg;
      }
      args.unshift(arg);
      return match;
    });
  }

  // arguments remain after formatting
  if (args.length) {
    fmt += " " + args.join(" ");
  }

  // update escaped %% values
  fmt = fmt.replace(/%{2,2}/g, "%");

  return "" + fmt;
}

export function modifyFilter(component, blur, videoElementID) {
  const videoElement = document.getElementById(videoElementID);

  if (blur === 0) {
    if (component.videoFilter) {
      component.videoFilter.close();
      component.videoFilter = null;
    }
    if (videoElement) {
      videoElement.srcObject = component.stream;
    }
  } else if (component.videoFilter) {
    component.videoFilter.stream = component.stream;
    component.videoFilter.blur = blur;
  } else {
    component.videoFilter =
      component.props.roomClientProvider.createVideoFilter(
        component.stream,
        blur
      );
    if (videoElement) {
      videoElement.srcObject = component.videoFilter.filteredStream;
    }
  }
}

export function resetPreviewStream(component, videoElementID) {
  if (component.stream) {
    // Stop the unfiltered track
    component.stream.getVideoTracks().forEach(track => {
      track.stop();
    });
    component.stream = null;
  }

  if (component.videoFilter) {
    component.videoFilter.close();
    component.videoFilter = null;
  }

  const videoElement = document.getElementById(videoElementID);
  if (videoElement) {
    videoElement.srcObject = null;
  }
}
