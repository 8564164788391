import {
  USER_LOGIN,
  AUTHENTICATE_USER,
  USER_LOGOUT,
  SET_USER_ID,
  LOGIN_PORTAL_USER,
  AUTHENTICATE_PORTAL_USER,
  LOGOUT_PORTAL_USER
} from "../actions/types";

export default function (state = { userId: undefined }, action) {
  let newState = {};
  switch (action.type) {
    case USER_LOGIN:
    case AUTHENTICATE_USER:
      newState = { ...state };
      newState.id = action.payload.id;
      newState.userId = action.payload.userId;
      return newState;
    case USER_LOGOUT:
      newState = { ...state, ...action.payload };
      newState.userId = undefined;
      return newState;
    case SET_USER_ID:
      newState = { ...state };
      newState.id = action.payload;
      return newState;
    case LOGIN_PORTAL_USER:
    case AUTHENTICATE_PORTAL_USER:
      newState = { ...state };
      newState.portalSessionID = action.payload.userID;
      return newState;
    case LOGOUT_PORTAL_USER:
      return {};
    default:
      return state;
  }
}
