import {
  CHATS_UPDATE,
  USERS_UPDATE,
  USER_LOGOUT,
  WEBSOCKET_RECONNECT
} from "../actions/types";

export default function(state = {}, action) {
  let newState = {};
  switch (action.type) {
    case CHATS_UPDATE:
      newState = mergeData({ ...state }, action.payload);
      return newState;
    case USERS_UPDATE:
      newState = { ...state };
      if (!action.payload.connected) {
        newState = deletePrivateChatsOnLogout({ ...state }, action.payload.id);
      }
      return newState;
    case WEBSOCKET_RECONNECT:
    case USER_LOGOUT:
      newState = {};
      return newState;
    default:
      return state;
  }
}

function mergeData(data, updatedData) {
  data[updatedData.id] = { ...data[updatedData.id], ...updatedData };
  return data;
}

function deletePrivateChatsOnLogout(data, senderId) {
  Object.entries(data).forEach(chatEntry => {
    if (
      chatEntry[1] !== undefined &&
      ((chatEntry[1].senderId === senderId && chatEntry[1].receiverId !== 0) ||
        chatEntry[1].receiverId === senderId)
    ) {
      data[chatEntry[0]] = undefined;
    }
  });
  return data;
}
