import React, { Component, Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {
  LoginPage,
  DirectLoginPage,
  MainPage,
  PersistentSpacePage,
  PortalPage,
  WaitingRoomPage,
  UnsupportedBrowserPage
} from "../components/pages";
import { WebSocketHandler } from "../components/websocket";
import { resizeWindow, setAudioInput, setAudioOutput } from "../actions";
import history from "../history/history.js";
import SvgIcon from "./Icons/SvgIcon";
import { ParticipantListPopoutWindow } from "./ParticipantList";
import { ChatPopoutWindow } from "./Chat";
import { CTXLanguageComponent } from "./Language";
import { CTXThemeComponent } from "./Theme";
import { QAPopoutWindow } from "./QA";
import { VideoPopoutWindow } from "./mediasoup/components";
import contexWebRest from "../api/contexWebRest";
import { getDevices, format } from "../utils";
import Logger from "../Logger";

const logger = new Logger("App");

class App extends Component {
  consoleLogs = [];

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);

    if (navigator.mediaDevices) {
      navigator.mediaDevices.addEventListener("devicechange", this.getDevices);
    }

    window.handleLog = this.handleLog;

    if (window.CtxAppConfigurations.clientLogsEnabled) {
      this.sendLogs();
    }
  }

  handleLog = (message, logger, args) => {
    if (
      window.CtxAppConfigurations.clientLogsEnabled &&
      typeof message === "string" &&
      message.match(/^[^\s]+:[^\s]+/)
    ) {
      // Only send messages that use the debug library
      try {
        this.consoleLogs.push(format.apply(null, args));
      } catch (error) {
        logger.error("Error: %o", error);
      }
    }

    if (localStorage.getItem("cnDebug")) {
      logger.apply(null, args);
    }
  };

  sendLogs = async () => {
    if (this.consoleLogs.length > 0 && this.props.session.userId) {
      const uri = `/users/logs/${this.props.session.userId}/1`;
      try {
        await contexWebRest.post(uri, this.consoleLogs);
        this.consoleLogs = [];
      } catch (error) {
        logger.error("Error: %o", error);
      }
    }
    setTimeout(this.sendLogs, 30000);
  };

  shouldComponentUpdate(nextProps) {
    if (nextProps.session !== this.props.session) {
      return false;
    } else {
      return true;
    }
  }

  getDevices = async () => {
    // If a new device is added which matches the device stored in localStorage,
    // use the new device. If the currently selected audio input or output
    // device has been removed, change the device to the first device.
    // Otherwise, there will be no audio input or no audio output.
    try {
      await getDevices(this.props);
    } catch (error) {
      logger.error("Error: %o", error);
    }
  };

  handleWindowResize = () => {
    this.props.resizeWindow(window.innerHeight, window.innerWidth);
  };

  render() {
    const { webSocketUri, browserLanguage } = this.props;
    return (
      <Fragment>
        <WebSocketHandler uri={webSocketUri} history={history} />
        <CTXLanguageComponent browserLanguage={browserLanguage}>
          <CTXThemeComponent>
            <Fragment>
              <ParticipantListPopoutWindow />
              <ChatPopoutWindow />
              <QAPopoutWindow />
              <VideoPopoutWindow />

              <Router history={history}>
                <Switch>
                  <Route path="/" exact component={LoginPage} />
                  <Route path="/login" exact component={LoginPage} />
                  <Route path="/agent" exact>
                    <LoginPage agent />
                  </Route>
                  <Route path="/recorder" exact>
                    <LoginPage recorder />
                  </Route>
                  <Route path="/direct" exact component={DirectLoginPage} />
                  <Route path="/main" exact component={MainPage} />
                  <Route path="/portal" exact component={PortalPage} />
                  <Route path="/waiting" exact component={WaitingRoomPage} />
                  <Route
                    path="/notsupported"
                    exact
                    component={UnsupportedBrowserPage}
                  />
                  <Route path="/*" exact component={PersistentSpacePage} />
                  <Route component={LoginPage} />
                </Switch>
              </Router>

              {/* TODO fix this - prefetch partylist icons so they load when used*/}
              <div style={{ display: "none" }}>
                <SvgIcon iconName="participantList" />
                <SvgIcon iconName="columnSortedAscending" />
                <SvgIcon iconName="columnSortedDescending" />
                <SvgIcon iconName="columnUnsorted" />
                <SvgIcon iconName="connectCall" />
                <SvgIcon iconName="disconnectCall" />
                <SvgIcon iconName="audioMute" />
                <SvgIcon iconName="audioUnmute" />
                <SvgIcon iconName="disconnectAll" />
                <SvgIcon iconName="collapse" />
                <SvgIcon iconName="expand" />
                <SvgIcon iconName="popout" />
                <SvgIcon iconName="close" />
                <SvgIcon iconName="search" />
                <SvgIcon iconName="disconnectedParty" />
                <SvgIcon iconName="audioParty" />
                <SvgIcon iconName="hostParty" />
              </div>
            </Fragment>
          </CTXThemeComponent>
        </CTXLanguageComponent>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  resizeWindow: (height, width) => dispatch(resizeWindow(height, width)),
  setAudioInput: audioInput => dispatch(setAudioInput(audioInput)),
  setAudioOutput: audioOutput => dispatch(setAudioOutput(audioOutput))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
