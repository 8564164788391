import React, { Component } from "react";
import { connect } from "react-redux";
import { withTheme } from "@material-ui/core/styles";
import { hideChatPopout } from "./actions";
import { Chat } from "../";
import CtxNewWindow from "../../NewWindow/CtxNewWindow";

/***
 * If ChatPopoutState.OPEN  ---> PopoutWindow is shown
 * If ChatPopoutState.CLOSE ----> PopoutWindow is closed
 *
 * Stateful component that sets ChatPopoutState.CLOSE in the redux store.
 * When using this, it's upto the implementor to set ChatPopoutState.OPEN in the store.
 * This component reads from the redux store to decide if the popout window should be shown.
 */

// ChatPopoutState is exported for the reducer to set OPEN and CLOSE states.
export const ChatPopoutState = {
  CLOSE: 1,
  OPEN: 2
};

export const chatPopoutWindowName = "ChatWindow";

const defaultWindowWidth = 391;
const defaultWindowHeight = 600;
const minWindowWidth = 391; //375 inner, no scrollbar required in Chat window
const minWidthCheckTimeout = 150;

let resizeTimer;

class ChatPopoutWindowComponent extends Component {
  state = {
    containerHeight: defaultWindowHeight,
    containerWidth: defaultWindowWidth,
    popoutRef: undefined
  };

  ensureMinWidth = (width, ref) => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      if (width < minWindowWidth) {
        ref.window.resizeTo(minWindowWidth, ref.window.outerHeight);
      }
    }, minWidthCheckTimeout);
  };

  handleResize = () => {
    if (this.state.popoutRef) {
      this.ensureMinWidth(
        this.state.popoutRef.window.outerWidth,
        this.state.popoutRef
      );
    }
    this.setState({
      ...this.state,
      containerHeight: this.selfRef.offsetHeight,
      containerWidth: this.selfRef.offsetWidth
    });
  };

  handleUnload = () => {
    this.props.hideChatPopout();
    this.setState({
      ...this.state,
      containerHeight: defaultWindowHeight,
      containerWidth: defaultWindowWidth,
      popoutRef: undefined
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.popoutRef && this.popoutRef) {
      this.setState({ ...this.state, popoutRef: this.popoutRef });
    } else if (
      prevState.popoutRef &&
      this.state.popoutRef &&
      prevState.popoutRef.window.outerWidth !==
        this.state.popoutRef.window.outerWidth
    ) {
      this.setState({
        ...this.state,
        containerHeight: this.selfRef.offsetHeight,
        containerWidth: this.selfRef.offsetWidth
      });
    }
  }

  render() {
    // console.log("chatPopout", this);
    if (this.props.chatPopoutWindow.windowStatus === ChatPopoutState.OPEN) {
      return (
        <CtxNewWindow
          ref={r => (this.popoutRef = r)}
          onUnload={this.handleUnload}
          resizeCallback={this.handleResize}
          features={{
            width: defaultWindowWidth,
            height: defaultWindowHeight,
            location: 0,
            scrollbars: 0
          }}
          title="ConnectNow-Chat"
          name={chatPopoutWindowName}
          backgroundColor={this.props.theme.popoverBackgroundColor}
        >
          <div
            className="chatWindow"
            ref={r => (this.selfRef = r)}
            style={{ width: "100%", height: "100%", overflow: "hidden" }}
          >
            <Chat
              containerHeight={this.state.containerHeight}
              containerWidth={this.state.containerWidth}
            />
          </div>
        </CtxNewWindow>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ chatPopoutWindow, theme }) => ({
  chatPopoutWindow,
  theme
});

const mapDispatchToProps = dispatch => ({
  hideChatPopout: () => dispatch(hideChatPopout())
});

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(ChatPopoutWindowComponent)
);
