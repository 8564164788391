import React, { Component, Fragment } from "react";
import { withStyles, withTheme, Tabs, Tab } from "@material-ui/core";
import CTXTable from "../reusable/CTXTable";
import { connect } from "react-redux";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import {
  selectUserManagementTabView,
  getPortalUserAccountList,
  setUserManagementSelectedUser,
  UserPromptLevel,
  setProfileUserPrompt,
  setNewUserModalStatus,
  setUserManagementSelectedUserList,
  setConfirmDeleteUserModalStatus,
  setNewUserModalUserInformation,
  setUserManagementSelectedUserAudioConference,
  getPortalUserAudioConferenceDetailsAdmin,
  setUserManagementSelectedUserPersistentSpace,
  getPortalUserPersistentSpacesByUserID
} from "./actions";

const styles = theme => ({
  userRoleTabContainer: {
    display: "flex"
  },
  toolbarContainer: {
    paddingRight: "20px"
  },
  userButtonRoot: {
    minWidth: "145px",
    minHeight: "20px"
  },
  userButton: {
    marginLeft: "10px"
  },
  displayFlexRowReverse: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexGrow: {
    flexGrow: 1
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    alignItems: "center"
  }
});

class PortalUserTable extends Component {
  componentDidMount() {
    const { session } = this.props;
    const { portalSessionId } = session;

    this.props.getPortalUserAccountList(portalSessionId);
  }

  componentWillUnmount() {
    this.props.setUserManagementSelectedUser(undefined);
  }

  //controls
  getToolbarControls = () => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.flexGrow,
          classes.justifyContentSpaceBetween,
          classes.alignItemsCenter,
          classes.toolbarContainer
        )}
      >
        {this.getUserRoleTabControls()}
        {this.getUserActionControls()}
      </div>
    );
  };

  getUserRoleTabControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;

    return (
      <div className={classes.userRoleTabContainer}>
        <Tabs
          value={portalUserManagement.userTableTabIndex}
          onChange={this.handleUserTableTabSelectChange}
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab
            label={<LocalizedText value="administrators" variant="caption" />}
          />
          <Tab label={<LocalizedText value="hosts" variant="caption" />} />
        </Tabs>
      </div>
    );
  };

  getUserActionControls = () => {
    const { classes, portal } = this.props;
    const { portalUserManagement } = portal;
    const { userTableTabIndex, showDisabledUser, selectedUser } =
      portalUserManagement;

    return (
      <div className={classNames(classes.displayFlexRowReverse)}>
        {showDisabledUser ? (
          <Fragment>
            <TextButton
              className={classes.userButton}
              disabled={!selectedUser || selectedUser.size === 0}
              classes={{ root: classes.userButtonRoot }}
              onClick={this.handleEnableUserButtonOnClick}
            >
              <LocalizedText
                value={userTableTabIndex === 0 ? "enableAdmin" : "enableHost"}
              />
            </TextButton>
          </Fragment>
        ) : (
          <Fragment>
            <TextButton
              className={classes.userButton}
              classes={{ root: classes.userButtonRoot }}
              onClick={this.handleDisableUserButtonOnClick}
            >
              <LocalizedText
                value={userTableTabIndex === 0 ? "disableAdmin" : "disableHost"}
              />
            </TextButton>
            <TextButton
              className={classes.userButton}
              classes={{ root: classes.userButtonRoot }}
              onClick={this.handleNewUserButtonOnClick}
            >
              <LocalizedText
                value={userTableTabIndex === 0 ? "newAdmin" : "newHost"}
              />
            </TextButton>
          </Fragment>
        )}
      </div>
    );
  };

  getTableData = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { userList, userTableTabIndex, selectedUserMap, showDisabledUser } =
      portalUserManagement;

    var getTableDataResult = [];

    if (userList != null && userList.length > 0) {
      userList.forEach(portalUserAccount => {
        if (
          showDisabledUser ^ portalUserAccount.enabled &&
          userTableTabIndex === portalUserAccount.userRole
        ) {
          let tableRowData = {};
          //Key
          tableRowData.userID = portalUserAccount.userID;
          //firstName
          tableRowData.firstName = portalUserAccount.firstName;
          //lastName
          tableRowData.lastName = portalUserAccount.lastName;
          //full name
          tableRowData.fullName =
            portalUserAccount.lastName + ", " + portalUserAccount.firstName;
          //email
          tableRowData.email = portalUserAccount.email;
          //username
          tableRowData.username = portalUserAccount.username;
          //check
          if (selectedUserMap.has(tableRowData.userID)) {
            tableRowData.tableData = { checked: true };
          }

          getTableDataResult.push(tableRowData);
        }
      });
    }

    return getTableDataResult;
  };

  //function
  handleUserTableTabSelectChange = (event, tabIndex) => {
    this.props.selectUserManagementTabView(tabIndex);
    this.props.setUserManagementSelectedUser(undefined);
    this.props.setUserManagementSelectedUserList([]);
    this.props.setProfileUserPrompt(UserPromptLevel, "");
  };

  handleOnRowClick = (event, rowData) => {
    const { session } = this.props;
    const { portalSessionId } = session;

    let selectedUser = {
      userID: rowData.userID,
      firstName: rowData.firstName,
      lastName: rowData.lastName,
      email: rowData.email,
      username: rowData.username
    };

    this.props.setUserManagementSelectedUser(selectedUser);
    this.props.setProfileUserPrompt(UserPromptLevel.INFO, "");
    this.props.setUserManagementSelectedUserAudioConference(undefined);
    this.props.setUserManagementSelectedUserPersistentSpace(undefined);
    this.props.getPortalUserAudioConferenceDetailsAdmin(
      portalSessionId,
      rowData.userID
    );
    this.props.getPortalUserPersistentSpacesByUserID(
      portalSessionId,
      selectedUser.userID
    );
  };

  handleNewUserButtonOnClick = () => {
    this.props.setNewUserModalStatus(true);
  };

  handleDisableUserButtonOnClick = () => {
    this.props.setConfirmDeleteUserModalStatus(true);
  };

  onSelectionChange = rows => {
    this.props.setUserManagementSelectedUserList(rows);
  };

  handleEnableUserButtonOnClick = () => {
    const { portal } = this.props;
    const { portalUserManagement } = portal;
    const { selectedUser } = portalUserManagement;

    if (selectedUser) {
      this.props.setNewUserModalStatus(true);
      this.props.setNewUserModalUserInformation(selectedUser);
    }
  };

  render() {
    const { languages, portal } = this.props;
    const { translations } = languages;
    const tableColumns = [
      { title: "Key", field: "userID", hidden: true },
      { title: "firstName", field: "firstName", hidden: true },
      { title: "lastNAme", field: "lastName", hidden: true },
      { title: translations.name, field: "fullName" },
      {
        title: translations.email,
        field: "email"
      },
      { title: translations.username, field: "username" }
    ];
    const tableData = this.getTableData();
    const { portalUserManagement } = portal;
    const { showDisabledUser } = portalUserManagement;

    return (
      <CTXTable
        title=""
        columns={tableColumns}
        data={tableData}
        enableSearch={false}
        toolbar={this.getToolbarControls()}
        enableSelection={!showDisabledUser}
        enableSelectedRowStyle
        onRowClick={this.handleOnRowClick}
        PortalUserTable
        onSelectionChange={this.onSelectionChange}
      />
    );
  }
}

const mapStateToProps = ({ languages, portal, session }) => ({
  languages,
  portal,
  session
});

const mapDispatchToProps = dispatch => ({
  selectUserManagementTabView: selectedTabIndex =>
    dispatch(selectUserManagementTabView(selectedTabIndex)),
  getPortalUserAccountList: sessionID =>
    dispatch(getPortalUserAccountList(sessionID)),
  setUserManagementSelectedUser: selectedUser =>
    dispatch(setUserManagementSelectedUser(selectedUser)),
  setProfileUserPrompt: (promptLevel, promptContents) =>
    dispatch(setProfileUserPrompt(promptLevel, promptContents)),
  setNewUserModalStatus: isOpen => dispatch(setNewUserModalStatus(isOpen)),
  setUserManagementSelectedUserList: selectedUserList =>
    dispatch(setUserManagementSelectedUserList(selectedUserList)),
  setConfirmDeleteUserModalStatus: isVisible =>
    dispatch(setConfirmDeleteUserModalStatus(isVisible)),
  setNewUserModalUserInformation: userInfoObj =>
    dispatch(setNewUserModalUserInformation(userInfoObj)),
  setUserManagementSelectedUserAudioConference: conferenceDetails =>
    dispatch(setUserManagementSelectedUserAudioConference(conferenceDetails)),
  getPortalUserAudioConferenceDetailsAdmin: (portalSessionId, dbUserID) =>
    dispatch(
      getPortalUserAudioConferenceDetailsAdmin(portalSessionId, dbUserID)
    ),
  getPortalUserPersistentSpacesByUserID: (portalSessionId, dbUserID) =>
    dispatch(getPortalUserPersistentSpacesByUserID(portalSessionId, dbUserID)),
  setUserManagementSelectedUserPersistentSpace: persistentSpaceDetails =>
    dispatch(
      setUserManagementSelectedUserPersistentSpace(persistentSpaceDetails)
    )
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(PortalUserTable))
  )
);
