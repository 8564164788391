import {
  CHAT_READ_STATE_UPDATE,
  USERID_UPDATE_FOR_CHAT_COUNT,
  USERS_UPDATE,
  USER_LOGIN,
  USER_LOGOUT
} from "../actions/types";
import { InternalDrawerState } from "../components/layouts/internalDrawerStates";
import { ChatPopoutState } from "../components/Chat/PopoutWindow/component";

const initialState = {
  selectedUserId: -1,
  unreadCount: 0,
  unreadChatMap: {}
};

export default function(
  state = initialState,
  internalDrawerState,
  chatPopoutWindowState,
  action
) {
  let newState = {};
  switch (action.type) {
    case CHAT_READ_STATE_UPDATE:
      newState = { ...state };
      newState.unreadChatMap = { ...newState.unreadChatMap };
      newState = updateUnreadChatStatus(newState, action.payload);
      return newState;
    case USERID_UPDATE_FOR_CHAT_COUNT:
      newState = { ...state };
      newState.selectedUserId = action.payload;
      return newState;
    case USERS_UPDATE:
      newState = { ...state };
      if (
        !action.payload.connected &&
        newState.selectedUserId === action.payload.id
      ) {
        if (
          internalDrawerState === InternalDrawerState.CHAT ||
          (internalDrawerState === InternalDrawerState.HIDDEN &&
            chatPopoutWindowState === ChatPopoutState.OPEN)
        ) {
          newState.selectedUserId = 0;
        } else {
          newState.selectedUserId = -1;
        }
      }
      return newState;
    case USER_LOGIN:
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

function updateUnreadChatStatus(data, updatedData) {
  if (data.unreadChatMap[updatedData.chatId] === undefined) {
    //check for all new unread chat messages
    if (!updatedData.status) {
      if (updatedData.senderId === data.selectedUserId) {
        updatedData.status = true; //Chats from active user should be marked as read immediately.
      } else {
        ++data.unreadCount; //increase count only if the chat's status is still false
      }
    }
    //add chatId to unreadChatMap with the updated chat's status
    data.unreadChatMap[updatedData.chatId] = updatedData.status;
  } else if (updatedData.status && !data.unreadChatMap[updatedData.chatId]) {
    //decrease count when an existing unread chat has been read
    data.unreadChatMap[updatedData.chatId] = updatedData.status;
    --data.unreadCount;
  }
  return data;
}
