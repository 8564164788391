import React, { Component } from "react";
import poweredByCpx from "./poweredByCpx.svg";

class PoweredByCpx extends Component {
  render() {
    const { classes } = this.props;
    let rootStyle;
    if (classes != null) {
      rootStyle = classes.root;
    }
    return (
      <img
        src={poweredByCpx}
        className={rootStyle}
        alt="Powered by Compunetix"
      />
    );
  }
}

export default PoweredByCpx;
