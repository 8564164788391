import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import LocalizedText from "../reusable/LocalizedText";
import {
  hideInternalDrawer,
  sortParticipants,
  setConnectPopperExpansion,
  disablePresenterMode,
  enablePresenterMode,
  disableConferencePresenterMode,
  stopScreenShare,
  deleteUser,
  admitUser,
  admitAllUsers
} from "../../actions";
import {
  showParticipantListPopout,
  hideParticipantListPopout,
  ParticipantsPopoutState
} from "./";
import VirtualizedList from "../reusable/VirtualizedList";
import {
  IconButton,
  TextField,
  Typography,
  Avatar,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import contexWebRest from "../../api/contexWebRest";
import {
  SortDirection,
  getIntl,
  TitleNewLine,
  isHost,
  isMobileOrTablet
} from "../../utils";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import TextButton from "../reusable/TextButton";
import CTXIconButtonConfirmModal from "../reusable/CTXIconButtonConfirmModal";
import { participantListPopoutWindowName } from "./PopoutWindow/component";
import {
  showInviteParticipant,
  showInviteByPhone,
  hideInviteByPhone,
  updateInviteByPhoneParticipant
} from "../invite";
import { InviteByPhoneWindowState } from "../invite";
import { withRoomContext } from "../mediasoup/RoomContext";

const componentMinWidthPx = 375;
const rowHeightDefaultPx = 30;
const tableSidePaddingPx = 20;
const scrollBarPaddingPx = 17;
const scrollBarSidePaddingPx = 2;
const placeholder = "-----";

const styles = theme => ({
  root: {
    height: "100%",
    minWidth: componentMinWidthPx
  },
  rootMaxWidth: {
    maxWidth: componentMinWidthPx
  },
  paper: {
    backgroundColor: theme.colors.popoverBackgroundColor,
    color: theme.colors.primaryTextColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px",
    height: "100%",
    width: "100%"
  },
  drawerHeader: {
    width: "100%",
    textAlign: "right",
    height: "34px",
    padding: "3px 3px 3px 0px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "8px 8px 8px 8px"
  },
  closeHeaderIcon: {
    width: "0.7em"
  },
  header: {
    width: "100%",
    textAlign: "left",
    padding: "0px 20px 5px 20px",
    height: "30px",
    lineHeight: "25px",
    fontSize: "1em",
    verticalAlign: "middle",
    marginBottom: "2px",
    display: "flex",
    justifyContent: "space-between"
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row"
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column"
  },
  headerLeft: {
    textAlign: "left",
    float: "left",
    display: "flex",
    height: "100%",
    alignItems: "center"
  },
  headerRight: {
    textAlign: "right",
    float: "right",
    display: "flex",
    height: "100%"
  },
  headerLabel: {
    display: "flex",
    padding: "0px 10px 0px 0px"
  },
  iconButton: {
    fontSize: "1.2em",
    padding: "0px",
    marginRight: "2px",
    marginLeft: "2px",
    width: "1.5em"
  },
  activeButton: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  inviteIcon: {
    width: "0.95em"
  },
  tableSection: {
    padding: "0px 20px 10px 20px"
  },
  listHeaderRow: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "30px",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`,
    borderBottom: `3px solid ${theme.colors.secondaryMainColor}`
  },
  listColumnHeader: {
    height: "100%",
    paddingLeft: "10px",
    paddingRight: "4px",
    position: "relative",
    borderLeft: `1px solid ${theme.colors.secondaryMainColor}`,
    display: "flex",
    justifyContent: "space-between"
  },
  listColumnHeaderFirst: {
    borderLeft: "none"
  },
  listColumnHeaderLabel: {
    height: "100%"
  },
  standardIcon: {
    width: "1em"
  },
  listColumnHeaderSortHover: {
    "&:hover": {
      backgroundColor: theme.colors.primaryBackgroundColor,
      cursor: "pointer"
    }
  },
  listDataRow: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.primaryBackgroundColor
    }
  },
  listDataRowExpandedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    "&:hover": {
      backgroundColor: theme.colors.primaryBackgroundColor
    },
    marginTop: "-1px"
  },
  listDataRowExpanded: {
    borderTop: `1px solid ${theme.colors.secondaryMainColor}`,
    borderBottom: `1px solid ${theme.colors.secondaryMainColor}`,
    width: "100%"
  },
  listColumnData: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
    paddingRight: "5px"
  },
  listColumnDataFirst: {
    paddingLeft: "5px"
  },
  listColumnDataLast: {
    paddingRight: scrollBarPaddingPx
  },
  participantName: {
    width: "100%",
    display: "flex"
  },
  statusIconButton: {
    fontSize: "1.2em",
    padding: "0px",
    marginLeft: "7px",
    marginRight: "7px"
  },
  fullHeightWidth: {
    height: "100%",
    width: "100%"
  },
  hrDiv: {
    width: "calc(100% - 40px)",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  sectionSeparator: {
    width: "100%",
    borderTop: `8px solid ${theme.colors.popoverBackgroundColor}`
  },
  audioPartyIcon: {
    height: "100%",
    width: "1.4em",
    marginLeft: "1px",
    marginRight: "-3px"
  },
  removePartyIconButton: {
    padding: 0,
    fontSize: "1.2em",
    height: "1.2em",
    width: "1.2em"
  },
  removePartyIcon: {
    width: "0.8em"
  },
  hostPartyIcon: {
    width: "1em"
  },
  talking: {
    color: theme.colors.talkingColor
  },
  onHold: {
    color: theme.colors.holdColor
  },
  hostIcon: {
    display: "inline-block",
    width: "20px",
    padding: "2px 5px 0 0"
  },
  statusIcons: {
    display: "flex",
    justifyContent: "flex-end",
    width: "95px"
  },
  emptyPlaceholderIcon: {
    width: "19.19px",
    minWidth: "19.19px",
    padding: "0px",
    marginLeft: "7px",
    marginRight: "7px",
    display: "inline-block"
  },
  expandedStatusLabel: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "173px"
  },
  collapsedName: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "210px"
  },
  closeIcon: {
    width: "12px"
  },
  searchContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  searchField: {
    color: theme.colors.primaryTextColor,
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  searchClearButton: {
    fontSize: "1.2em",
    padding: "6px"
  },
  expandedListDataRowImage: {
    height: "90px",
    minWidth: "90px",
    display: "flex",
    alignItems: "center"
  },
  inline: {
    display: "inline"
  },
  avatar: {
    height: "80%",
    width: "80%",
    color: theme.colors.primaryBackgroundColor,
    backgroundColor: "white"
  },
  expandedListDataRowData: {
    minHeight: "90px",
    width: "100%",
    paddingLeft: "10px",
    paddingRight: "20px",
    display: "flex",
    alignItems: "center",
    wordBreak: "break-all"
  },
  expandedListDataRow1: {
    width: "100%",
    display: "flex",
    paddingLeft: "40px"
  },
  expandedListDataRow2: {
    width: "100%",
    display: "flex",
    paddingLeft: "40px"
  },
  expandedListDataRowData2: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    paddingRight: "20px",
    flexDirection: "column"
  },
  expandedInfoDataCell: {
    width: "100%",
    wordBreak: "break-all",
    paddingLeft: "20px",
    textIndent: "-10px"
  },
  hidden: {
    visibility: "hidden"
  },
  presenterModeControlIcon: {
    fontSize: "1em",
    fill: theme.colors.primaryTextColor
  },
  textButton: {
    fontSize: "1.2em",
    marginRight: "5px"
  },
  waitingRoomHeaderRight: {
    textAlign: "right",
    float: "right",
    display: "flex",
    height: "100%",
    paddingLeft: "10px"
  }
});

/*
  dataKey: reference into the backing participant data
  renderKey: reference to the rendered component for the column
  width: width of the column (minWidth if column resizes) //TODO refactor names
  maxTableWitdh: maximum width of the surrounding table for which the column will appear
  minTableWidth: minimum width of the surrounding table for which the column will appear
  finalColumn: determines if this column gets styling as the final column in the row
  flexColumn: determines if this column resizes with table
  label: translation/localization key
  title: translation/localization key
  presentWhenRowExpanded: determines whether the column will appear in an expanded/selected row
  rowExpandedInfoColumn: determines whether the data for this column will appear in the detailed section of an expanded/selected row
  formatData: callback function to create the rendered component for the column
*/

const PartyType = {
  waitingRoomParty: 0,
  connectedParty: 1,
  idleParty: 2
};

const columns = [
  {
    dataKey: "typeIcon",
    renderKey: "renderedTypeIcon",
    width: 30,
    label: "",
    title: "participantType",
    presentWhenRowExpanded: true,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      //TODO use enums
      switch (participant.connectState) {
        case "NotConnected":
          if (participant.dataConnected) {
            participant.renderedTypeIcon = (
              <SvgIcon
                iconName="dataParty"
                className={classes.audioPartyIcon}
              />
            );
          } else {
            //TODO when merging users and parties, allow guest to control self
            const disabled = !isHost(session);
            participant.renderedTypeIcon = (
              <CTXIconButtonConfirmModal
                onConfirm={event =>
                  component.handleRemoveParticipant(event, participant.partyID)
                }
                data={participant}
                disabled={disabled}
                iconClass={classes.removePartyIconButton}
                svgName="disconnectedParty"
                svgColor="inactive"
                svgClass={classes.removePartyIcon}
                label="removeParticipant"
                name="removeParty"
                title={
                  intl.formatMessage(getIntl("participantDisconnected")) +
                  TitleNewLine +
                  (disabled
                    ? intl.formatMessage(getIntl("controlDisabled"))
                    : intl.formatMessage(getIntl("clickToRemoveParticipant")))
                }
              />
            );
          }
          break;
        case "OnHold":
        case "MusicHold":
        case "Monitor":
        case "TalkOperator":
        case "MuteOperator":
        case "TalkListen":
          if (participant.dataConnected) {
            participant.renderedTypeIcon = participant.usingWebRTCCall ? (
              <SvgIcon
                iconName="webrtcParticipant"
                className={classes.audioPartyIcon}
              />
            ) : (
              <SvgIcon
                iconName="audioDataParty"
                className={classes.audioPartyIcon}
              />
            );
          } else {
            participant.renderedTypeIcon = (
              <SvgIcon
                iconName="audioParty"
                className={classes.audioPartyIcon}
              />
            );
          }
          break;
        default:
          if (participant.dataConnected) {
            participant.renderedTypeIcon = (
              <SvgIcon
                iconName="dataParty"
                className={classes.audioPartyIcon}
              />
            );
          }
      }
    }
  },
  {
    dataKey: "name",
    renderKey: "renderedCollapsedName",
    width: 305,
    maxTableWidth: 2 * tableSidePaddingPx + 30 + 275 + 200, //545
    finalColumn: true,
    flexColumn: true,
    label: "name",
    title: "name",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: true,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      let className;

      let renderedCollapsedName;
      if (participant.talking) {
        className = classes.talking;
      } else if (
        participant.connectState === "OnHold" ||
        participant.connectState === "MusicHold" ||
        participant.connectState === "MuteOperator" ||
        participant.connectState === "TalkOperator"
      ) {
        className = classes.onHold;
      }
      if (participant.userLevel === "1") {
        renderedCollapsedName = (
          <div className={classes.participantName}>
            <div className={classes.hostIcon}>
              <SvgIcon iconName="hostParty" className={classes.hostPartyIcon} />
            </div>
            <Typography variant="subtitle2" color="inherit" noWrap>
              <span className={className}>{participant.name}</span>
              <span className={className}>{participant.me && " (me)"}</span>
            </Typography>
          </div>
        );
      } else {
        renderedCollapsedName = (
          <div className={classes.participantName}>
            <Typography variant="subtitle2" color="inherit" noWrap>
              <span className={className}>{participant.name}</span>
              <span className={className}>{participant.me && " (me)"}</span>
            </Typography>
          </div>
        );
      }
      renderedCollapsedName = (
        <div className={classes.collapsedName}>{renderedCollapsedName}</div>
      );

      let renderedConnectState;
      const emptyPlaceholderIcon = (
        <div className={classes.emptyPlaceholderIcon} />
      );
      const particpantConnected = participant.connectState !== "NotConnected";
      const participantMuted =
        participant.connectState === "Monitor" ||
        participant.connectState === "MuteOperator";
      const participantHolded =
        participant.connectState === "OnHold" ||
        participant.connectState === "MusicHold";
      renderedConnectState = (
        <Fragment>
          <div className={classes.statusIcons}>
            {component.getMuteIcon(
              participant.partyID,
              particpantConnected,
              participantHolded,
              participantMuted,
              emptyPlaceholderIcon,
              participant.me,
              participant.connectState
            )}
            {component.getConnectIcon(participant, particpantConnected)}
            {isHost(session)
              ? component.getPresenterModeControls(
                  participant,
                  emptyPlaceholderIcon
                )
              : component.getHiddenIcon()}
          </div>
        </Fragment>
      );

      let renderedWaitingPartyIcons = (
        <Fragment>
          <div className={classes.statusIcons}>
            {component.getHiddenIcon()}
            {component.getAdmitIcon(participant.id)}
            {component.getRejectIcon(participant.id)}
          </div>
        </Fragment>
      );

      participant.renderedCollapsedName = (
        <Fragment>
          {renderedCollapsedName}
          {partyType === PartyType.connectedParty && renderedConnectState}
          {partyType === PartyType.waitingRoomParty &&
            renderedWaitingPartyIcons}
        </Fragment>
      );
    }
  },
  {
    dataKey: "name",
    renderKey: "renderedName",
    width: 275,
    minTableWidth: 2 * tableSidePaddingPx + 30 + 275 + 200, //545
    flexColumn: true,
    label: "name",
    title: "name",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: true,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      let className;
      if (participant.talking) {
        className = classes.talking;
      } else if (
        participant.connectState === "OnHold" ||
        participant.connectState === "MusicHold" ||
        participant.connectState === "MuteOperator" ||
        participant.connectState === "TalkOperator"
      ) {
        className = classes.onHold;
      }
      if (participant.userLevel === "1") {
        participant.renderedName = (
          <div className={classes.participantName}>
            <div className={classes.hostIcon}>
              <SvgIcon iconName="hostParty" className={classes.hostPartyIcon} />
            </div>
            <Typography variant="subtitle2" color="inherit" noWrap>
              <span className={className}>{participant.name}</span>
              <span className={className}>{participant.me && " (me)"}</span>
            </Typography>
          </div>
        );
      } else {
        participant.renderedName = (
          <div className={classes.participantName}>
            <Typography variant="subtitle2" color="inherit" noWrap>
              <span className={className}>{participant.name}</span>
              <span className={className}>{participant.me && " (me)"}</span>
            </Typography>
          </div>
        );
      }
    }
  },
  {
    dataKey: "userDefined",
    renderKey: "renderedUserDefined",
    width: 185,
    minTableWidth: 2 * tableSidePaddingPx + 30 + 275 + 210 + 185 + 200, //940
    label: "userDefined",
    title: "userDefined",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      participant.renderedUserDefined = isHost(session) && (
        <Typography variant="subtitle2" color="inherit" noWrap>
          <span>{participant.userDefined || placeholder}</span>
        </Typography>
      );
    }
  },
  {
    dataKey: "userDefined2",
    renderKey: "renderedUserDefined2",
    width: 185,
    minTableWidth: 2 * tableSidePaddingPx + 30 + 275 + 210 + 185 + 185 + 200, //1125
    label: "userDefined2",
    title: "userDefined2",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      participant.renderedUserDefined2 = isHost(session) && (
        <Typography variant="subtitle2" color="inherit" noWrap>
          <span>{participant.userDefined2 || placeholder}</span>
        </Typography>
      );
    }
  },
  {
    dataKey: "userDefined3",
    renderKey: "renderedUserDefined3",
    width: 185,
    minTableWidth:
      2 * tableSidePaddingPx + 30 + 275 + 210 + 185 + 185 + 185 + 200, //1310
    label: "userDefined3",
    title: "userDefined3",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      participant.renderedUserDefined3 = isHost(session) && (
        <Typography variant="subtitle2" color="inherit" noWrap>
          <span>{participant.userDefined3 || placeholder}</span>
        </Typography>
      );
    }
  },
  {
    dataKey: "userDefined4",
    renderKey: "renderedUserDefined4",
    width: 185,
    minTableWidth:
      2 * tableSidePaddingPx + 30 + 275 + 210 + 185 + 185 + 185 + 185 + 200, //1495
    label: "userDefined4",
    title: "userDefined4",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      participant.renderedUserDefined4 = isHost(session) && (
        <Typography variant="subtitle2" color="inherit" noWrap>
          <span>{participant.userDefined4 || placeholder}</span>
        </Typography>
      );
    }
  },
  {
    dataKey: "phoneNumber",
    renderKey: "renderedPhoneNumber",
    width: 210,
    minTableWidth: 2 * tableSidePaddingPx + 30 + 275 + 210 + 200, //755
    label: "phone",
    title: "phone",
    presentWhenRowExpanded: false,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      participant.renderedPhoneNumber = isHost(session) && (
        <Typography variant="subtitle2" color="inherit" noWrap>
          <span>{participant.phoneNumber || placeholder}</span>
        </Typography>
      );
    }
  },
  {
    dataKey: "connectState",
    renderKey: "renderedConnectState",
    width: 200,
    minTableWidth: 2 * tableSidePaddingPx + 30 + 275 + 200, //545
    finalColumn: true,
    label: "status",
    title: "status",
    presentWhenRowExpanded: true,
    rowExpandedInfoColumn: false,
    formatData: (
      partyType,
      component,
      participant,
      classes,
      session,
      theme,
      intl
    ) => {
      const emptyPlaceholderIcon = (
        <div className={classes.emptyPlaceholderIcon} />
      );
      const particpantConnected = participant.connectState !== "NotConnected";
      const participantMuted =
        participant.connectState === "Monitor" ||
        participant.connectState === "MuteOperator";
      const participantHolded =
        participant.connectState === "OnHold" ||
        participant.connectState === "MusicHold";
      const connectStateLabel = (
        <LocalizedText
          value={participant.connectState}
          variant="subtitle2"
          color="inherit"
          noWrap
        />
      );

      participant.renderedConnectState =
        partyType === PartyType.connectedParty ? (
          <Fragment>
            <div className={classes.expandedStatusLabel}>
              {connectStateLabel}
            </div>
            <div className={classes.statusIcons}>
              {component.getMuteIcon(
                participant.partyID,
                particpantConnected,
                participantHolded,
                participantMuted,
                emptyPlaceholderIcon,
                participant.me,
                participant.connectState
              )}
              {component.getConnectIcon(participant, particpantConnected)}
            </div>
          </Fragment>
        ) : partyType === PartyType.waitingRoomParty ? (
          <Fragment>
            <div className={classes.expandedStatusLabel}>
              {connectStateLabel}
            </div>
            <div className={classes.statusIcons}>
              {component.getHiddenIcon()}
              {component.getAdmitIcon(participant.id)}
              {component.getRejectIcon(participant.id)}
            </div>
          </Fragment>
        ) : undefined;
    }
  }
];

const expandedInfo = [
  {
    dataKey: "connectState",
    label: <LocalizedText value="status" variant="caption" inline="true" />
  },
  {
    dataKey: "phoneNumber",
    label: <LocalizedText value="phone" variant="caption" inline="true" />
  },
  {
    dataKey: "userDefined",
    label: <LocalizedText value="userDefined" variant="caption" inline="true" />
  },
  {
    dataKey: "userDefined2",
    label: (
      <LocalizedText value="userDefined2" variant="caption" inline="true" />
    )
  },
  {
    dataKey: "userDefined3",
    label: (
      <LocalizedText value="userDefined3" variant="caption" inline="true" />
    )
  },
  {
    dataKey: "userDefined4",
    label: (
      <LocalizedText value="userDefined4" variant="caption" inline="true" />
    )
  }
];

class ParticipantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: {
        id: undefined,
        index: undefined,
        height: rowHeightDefaultPx
      },
      searchActive: false,
      filterString: "",
      filteredParties: []
    };
  }

  componentDidMount() {
    if (this.props.session.sharingActive) {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  componentWillUnmount() {
    if (this.props.session.sharingActive) {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let updateState = false;
    let updateSelectedRow = false;
    let stateChanges = undefined;
    let selectedRowChanges = { ...this.state.selectedRow };
    let recomputeHeights = false;
    let selectedRowIndex = this.state.selectedRow.index;

    //handle selecting a row causing selected row height change
    if (
      this.state.selectedRow.id &&
      this.state.selectedRow.id !== prevState.selectedRow.id
    ) {
      selectedRowChanges = {
        ...selectedRowChanges,
        height: this.selectedRowRef.offsetHeight
      };
      updateSelectedRow = true;
      recomputeHeights = true;
    }
    //handle unselecting a row causing selected row height change
    else if (!this.state.selectedRow.id && prevState.selectedRow.id) {
      selectedRowChanges = {
        ...selectedRowChanges,
        height: rowHeightDefaultPx
      };
      updateSelectedRow = true;
      recomputeHeights = true;
      this.selectedRowRef = undefined;
    }
    //handle width change causing selected row height change due to wrapping
    else if (
      this.selectedRowRef &&
      this.state.selectedRow.id &&
      prevProps.containerWidth !== this.props.containerWidth
    ) {
      console.log("selectedRow responsive change");
      selectedRowChanges = {
        ...selectedRowChanges,
        height: this.selectedRowRef.offsetHeight
      };
      updateSelectedRow = true;
      recomputeHeights = true;
    }

    //handle selected row changes when list changes
    if (
      this.state.selectedRow.id &&
      this.props.participants !== prevProps.participants
    ) {
      selectedRowIndex = this.props.participants.connectedParties.findIndex(
        item => item.id === this.state.selectedRow.id
      );
      if (selectedRowIndex < 0) {
        //selected row removed
        selectedRowChanges = {
          ...selectedRowChanges,
          id: undefined,
          index: undefined,
          height: rowHeightDefaultPx
        };
        updateSelectedRow = true;
        recomputeHeights = true;
      } else {
        if (selectedRowIndex !== prevState.selectedRow.index) {
          //selected row index changed
          selectedRowChanges = {
            ...selectedRowChanges,
            index: selectedRowIndex
          };
          updateSelectedRow = true;
          recomputeHeights = true;
        }
        //selected row height may change due to change in data
        const selectedHeight = this.selectedRowRef
          ? this.selectedRowRef.offsetHeight
          : rowHeightDefaultPx;
        selectedRowChanges = {
          ...selectedRowChanges,
          height: selectedHeight
        };
        updateSelectedRow = true;
        recomputeHeights = true;
      }
    }

    //handle searching
    if (
      this.state.searchActive &&
      prevState.searchActive &&
      this.props.participants !== prevProps.participants
    ) {
      //disable searching if no connected parties
      const searchActive =
        this.props.participants.connectedParties.length < 1
          ? false
          : this.state.searchActive;
      //refilter when participants store changes while filtering is active
      const filteredParties = this.runFilter(this.state.filterString);
      stateChanges = {
        ...stateChanges,
        searchActive: searchActive,
        filteredParties: filteredParties
      };
      updateState = true;
    }

    //perform the necessary state updates
    if (updateState || updateSelectedRow) {
      stateChanges = {
        ...stateChanges,
        selectedRow: { ...selectedRowChanges }
      };
      this.setState(
        {
          ...this.state,
          ...stateChanges
        },
        () => {
          if (recomputeHeights && this.wrappedRef !== null) {
            this.wrappedRef.recomputeRowHeights(
              selectedRowIndex < prevState.selectedRow.index
                ? selectedRowIndex
                : 0
            );
          }
        }
      );
    }
  }

  handleSearch = () => {
    let searchActive, filteredParties;
    if (this.state.searchActive) {
      //turning off search
      searchActive = false;
      filteredParties = [];
    } else {
      //turning on search
      searchActive = true;
      filteredParties = this.runFilter(this.state.filterString);
    }
    this.setState({
      ...this.state,
      searchActive: searchActive,
      filteredParties: filteredParties,
      selectedRow: {
        id: undefined,
        index: undefined,
        height: rowHeightDefaultPx
      }
    });
    if (this.wrappedRef !== null) {
      this.wrappedRef.recomputeRowHeights(0);
    }
  };

  /* TODO
  Determine actual filtering algorithm
  For now, just a simple startsWith on first and last name, phoneNumber, and user defined fields
  */
  runFilter = filterString => {
    const { participants } = this.props;
    let filteredResults;
    if (!filterString || filterString.length < 1) {
      filteredResults = [...participants.connectedParties];
    } else {
      const filterStringStandardized = filterString.toLowerCase();
      filteredResults = participants.connectedParties.filter(
        participant =>
          (participant.firstName &&
            participant.firstName
              .toLowerCase()
              .startsWith(filterStringStandardized)) ||
          (participant.lastName &&
            participant.lastName
              .toLowerCase()
              .startsWith(filterStringStandardized)) ||
          (participant.phoneNumber &&
            participant.phoneNumber
              .toLowerCase()
              .startsWith(filterStringStandardized)) ||
          (participant.userDefined &&
            participant.userDefined
              .toLowerCase()
              .startsWith(filterStringStandardized)) ||
          (participant.userDefined2 &&
            participant.userDefined2
              .toLowerCase()
              .startsWith(filterStringStandardized)) ||
          (participant.userDefined3 &&
            participant.userDefined3
              .toLowerCase()
              .startsWith(filterStringStandardized)) ||
          (participant.userDefined4 &&
            participant.userDefined4
              .toLowerCase()
              .startsWith(filterStringStandardized))
      );
    }
    return filteredResults;
  };

  clearSearch = () => {
    this.setFilterString("");
    this.searchRef.focus();
  };

  setFilterString(filterString) {
    this.setState({
      ...this.state,
      filterString: filterString,
      filteredParties: this.runFilter(filterString),
      selectedRow: {
        id: undefined,
        index: undefined,
        height: rowHeightDefaultPx
      }
    });
    if (this.wrappedRef !== null) {
      this.wrappedRef.recomputeRowHeights(0);
    }
  }

  getHeaderRow = () => {
    const { classes } = this.props;
    const listHeaderRow = this.state.searchActive
      ? this.getSearchBox()
      : this.getColumnHeaders();

    return <div className={classes.listHeaderRow}>{listHeaderRow}</div>;
  };

  getSearchBox = () => {
    const { classes, intl } = this.props;

    return (
      <div className={classes.searchContainer}>
        <TextField
          autoFocus
          placeholder={intl.formatMessage(getIntl("search"))}
          className={classes.searchField}
          fullWidth={true}
          InputProps={{
            disableUnderline: true,
            inputRef: r => (this.searchRef = r),
            inputProps: {
              "aria-label": placeholder
            }
          }}
          value={this.state.filterString}
          onChange={event => this.setFilterString(event.target.value)}
        />
        <IconButton
          className={classes.searchClearButton}
          disabled={false}
          onClick={() => this.clearSearch()}
          title={intl.formatMessage(getIntl("clearSearch"))}
        >
          <SvgIcon iconName="close" className={classes.closeIcon} />
        </IconButton>
      </div>
    );
  };

  getColumnHeaders = () => {
    const { containerWidth } = this.props;
    let headerCells = [];

    for (var i = 0; i < columns.length; i++) {
      let col = columns[i];

      if (col.maxTableWidth) {
        if (containerWidth <= col.maxTableWidth) {
          headerCells.push(this.getColumnHeader(col, headerCells.length));
        }
      } else if (col.minTableWidth) {
        if (containerWidth > col.minTableWidth) {
          headerCells.push(this.getColumnHeader(col, headerCells.length));
        }
      } else {
        headerCells.push(this.getColumnHeader(col, headerCells.length));
      }
    }

    return headerCells;
  };

  getColumnHeader = (column, collectionLength) => {
    const { classes, participants, intl, session } = this.props;
    let sortIcon = null;
    let headerClassNames = [classes.listColumnHeader];
    let headerClick = dataKey => {
      return;
    };
    let headerKeyUp = (event, dataKey) => {
      return;
    };

    if (!column.disableSort) {
      headerClassNames.push(classes.listColumnHeaderSortHover);
      headerClick = dataKey => this.handleHeaderClick(dataKey);
      headerKeyUp = (event, dataKey) => this.handleHeaderKeyUp(event, dataKey);

      if (column.dataKey === participants.sort.by) {
        sortIcon =
          participants.sort.direction === SortDirection.ASCENDING ? (
            <SvgIcon
              iconName="columnSortedAscending"
              className={classes.standardIcon}
            />
          ) : (
            <SvgIcon
              iconName="columnSortedDescending"
              className={classes.standardIcon}
            />
          );
      } else {
        sortIcon = (
          <SvgIcon iconName="columnUnsorted" className={classes.standardIcon} />
        );
      }
    }

    if (collectionLength === 0) {
      headerClassNames.push(classes.listColumnHeaderFirst);
    }

    const formattedHeaderLabel =
      column.label && column.label.length > 0 ? (
        <LocalizedText
          value={column.label}
          variant="subtitle1"
          className={classes.listColumnHeaderLabel}
        />
      ) : (
        ""
      );

    const title =
      column.title && column.title.length > 0
        ? intl.formatMessage(getIntl(column.title)) +
          TitleNewLine +
          (!column.disableSort
            ? column.dataKey === participants.sort.by
              ? participants.sort.direction === SortDirection.ASCENDING
                ? intl.formatMessage(getIntl("sortedAscending")) +
                  TitleNewLine +
                  intl.formatMessage(getIntl("clickToSortDescending"))
                : intl.formatMessage(getIntl("sortedDescending")) +
                  TitleNewLine +
                  intl.formatMessage(getIntl("clickToSortAscending"))
              : intl.formatMessage(getIntl("unsorted")) +
                TitleNewLine +
                intl.formatMessage(getIntl("clickToSortAscending"))
            : intl.formatMessage(getIntl("sortingDisabled")))
        : undefined;

    const hostOnlyColumns = [
      "userDefined",
      "userDefined2",
      "userDefined3",
      "userDefined4",
      "phoneNumber"
    ];

    return (
      (isHost(session) ||
        (!isHost(session) && !hostOnlyColumns.includes(column.dataKey))) && (
        <div
          className={classNames(headerClassNames)}
          key={column.dataKey}
          style={this.getColumnCellStyle(column.flexColumn, column.width, 0)}
          onClick={() => headerClick(column.dataKey)}
          onKeyUp={event => headerKeyUp(event, column.dataKey)}
          role="button"
          tabIndex="0"
          title={title}
        >
          <div className={classes.listColumnHeaderLabel}>
            {formattedHeaderLabel}
          </div>
          {sortIcon}
        </div>
      )
    );
  };

  getDataRow = (
    partyType,
    index,
    isScrolling,
    isVisible,
    key,
    parent,
    style,
    isHost
  ) => {
    const { classes, participants, containerWidth } = this.props;
    let participant;

    switch (partyType) {
      case PartyType.waitingRoomParty:
        participant = { ...participants.waitingRoomParties[index] };
        break;
      case PartyType.idleParty:
        participant = { ...participants.idleParties[index] };
        break;
      case PartyType.connectedParty:
        participant = this.state.searchActive
          ? this.state.filteredParties[index]
          : { ...participants.connectedParties[index] };
        break;
      default:
        participant = null;
    }

    if (participant) {
      if (participant.id === this.state.selectedRow.id) {
        return this.getSelectedRow(
          partyType,
          participant,
          index,
          isScrolling,
          isVisible,
          key,
          parent,
          style,
          isHost
        );
      }

      let dataCells = [];

      for (var i = 0; i < columns.length; i++) {
        let col = columns[i];

        if (col.maxTableWidth) {
          if (containerWidth <= col.maxTableWidth) {
            dataCells.push(
              this.getColumnData(
                partyType,
                col,
                participant,
                dataCells.length === 0
              )
            );
          }
        } else if (col.minTableWidth) {
          if (containerWidth > col.minTableWidth) {
            dataCells.push(
              this.getColumnData(
                partyType,
                col,
                participant,
                dataCells.length === 0
              )
            );
          }
        } else {
          dataCells.push(
            this.getColumnData(
              partyType,
              col,
              participant,
              dataCells.length === 0
            )
          );
        }
      }

      return (
        <div
          className={classes.listDataRow}
          key={key}
          style={style}
          onClick={() =>
            isHost && this.handleRowClick(participant.id, index, partyType)
          }
        >
          {dataCells}
        </div>
      );
    }
  };

  getColumnData = (partyType, column, participant, firstColumn) => {
    const { classes } = this.props;
    let dataCellClassNames = [classes.listColumnData];

    column.formatData(
      partyType,
      this,
      participant,
      classes,
      this.props.session,
      this.props.theme,
      this.props.intl
    );

    let paddingWidth = 0;

    if (firstColumn) {
      dataCellClassNames.push(classes.listColumnDataFirst);
    }
    if (column.finalColumn) {
      dataCellClassNames.push(classes.listColumnDataLast);
      paddingWidth = scrollBarPaddingPx;
    }

    return (
      <div
        className={classNames(dataCellClassNames)}
        key={column.dataKey}
        style={this.getColumnCellStyle(
          column.flexColumn,
          column.width,
          paddingWidth
        )}
      >
        {participant[column.renderKey]}
      </div>
    );
  };

  getSelectedRow = (
    partyType,
    participant,
    index,
    isScrolling,
    isVisible,
    key,
    parent,
    style,
    isHost
  ) => {
    const { classes, containerWidth } = this.props;
    let primaryDataCells = [];

    for (var i = 0; i < columns.length; i++) {
      let col = columns[i];

      if (col.rowExpandedInfoColumn || col.presentWhenRowExpanded) {
        if (col.maxTableWidth) {
          if (containerWidth <= col.maxTableWidth) {
            primaryDataCells.push(
              this.getExpandedPrimaryRow(
                partyType,
                col,
                participant,
                primaryDataCells.length === 0
              )
            );
          }
        } else if (col.minTableWidth) {
          if (containerWidth > col.minTableWidth) {
            primaryDataCells.push(
              this.getExpandedPrimaryRow(
                partyType,
                col,
                participant,
                primaryDataCells.length === 0
              )
            );
          }
        } else {
          primaryDataCells.push(
            this.getExpandedPrimaryRow(
              partyType,
              col,
              participant,
              primaryDataCells.length === 0
            )
          );
        }
      }
    }

    const primaryRow = (
      <div
        className={classes.listDataRow}
        style={{
          height: "30px",
          minWidth:
            componentMinWidthPx - tableSidePaddingPx - scrollBarSidePaddingPx
        }}
      >
        {primaryDataCells}
      </div>
    );

    const expandedRow = isHost && this.getRowExpandedSection(participant);

    return (
      <div
        ref={r => (this.selectedRowRef = r)}
        className={classes.listDataRowExpandedContainer}
        key={key}
        style={style}
        onClick={() =>
          isHost && this.handleRowClick(participant.id, index, partyType)
        }
      >
        <div className={classes.listDataRowExpanded}>
          {primaryRow}
          {expandedRow}
        </div>
      </div>
    );
  };

  getExpandedPrimaryRow = (partyType, column, participant, firstColumn) => {
    const { classes } = this.props;
    let dataCellClassNames = [classes.listColumnData];

    let data;

    column.formatData(
      partyType,
      this,
      participant,
      classes,
      this.props.session,
      this.props.theme,
      this.props.intl
    );

    data = participant[column.renderKey];

    let paddingWidth = 0;

    if (firstColumn) {
      dataCellClassNames.push(classes.listColumnDataFirst);
    }
    if (column.finalColumn) {
      dataCellClassNames.push(classes.listColumnDataLast);
      paddingWidth = scrollBarPaddingPx;
    }

    return (
      <div
        className={classNames(dataCellClassNames)}
        key={column.dataKey}
        style={this.getColumnCellStyle(
          column.flexColumn,
          column.width,
          paddingWidth
        )}
      >
        {data}
      </div>
    );
  };

  getRowExpandedSection = participant => {
    const { classes } = this.props;
    let expandedInfoDataCells = [];
    for (var i = 0; i < expandedInfo.length; i++) {
      let label = expandedInfo[i].label;
      let value = (
        <Typography
          className={classes.inline}
          variant="caption"
          color="inherit"
        >
          <span>
            :{" "}
            {expandedInfo[i].dataKey === "connectState" ? (
              <LocalizedText
                value={participant[expandedInfo[i].dataKey]}
                variant="caption"
                className={classes.inline}
              />
            ) : (
              participant[expandedInfo[i].dataKey] || placeholder
            )}
          </span>
        </Typography>
      );

      expandedInfoDataCells.push(
        <div
          className={classes.expandedInfoDataCell}
          key={expandedInfo[i].dataKey}
        >
          {label}
          {value}
        </div>
      );
    }

    const expandedRow1 = (
      <div className={classes.expandedListDataRow1}>
        <div className={classes.expandedListDataRowImage}>
          <Avatar className={classes.avatar}>
            {participant.firstName[0]}
            {participant.lastName[0]}
          </Avatar>
        </div>
        <div className={classes.expandedListDataRowData}>
          <Typography
            className={classes.inline}
            variant="subtitle2"
            color="inherit"
          >
            <span>{participant.name}</span>
          </Typography>
        </div>
      </div>
    );
    const expandedRow2 = (
      <div className={classes.expandedListDataRow2}>
        <div className={classes.expandedListDataRowData2}>
          {expandedInfoDataCells}
        </div>
      </div>
    );

    return (
      <Fragment>
        {expandedRow1}
        {expandedRow2}
      </Fragment>
    );
  };

  getColumnCellStyle = (flexColumn, columnWidth, padding) => {
    let style;
    const width = columnWidth + padding;
    if (flexColumn) {
      style = {
        minWidth: width,
        flex: `1 0 ${width}px`
      };
    } else {
      style = {
        width: width,
        minWidth: width,
        maxWidth: width,
        flex: `0 0 ${width}px`
      };
    }
    return style;
  };

  handleRowClick = (id, index, partyType) => {
    if (partyType === PartyType.connectedParty) {
      console.log("rowClicked id & index:", id, index);
      if (id !== this.state.selectedRow.id) {
        this.selectRow(id, index);
      } else {
        this.unselectRow();
      }
    }
  };

  selectRow = (id, index) => {
    this.setState({
      ...this.state,
      selectedRow: {
        id: id,
        index: index
      }
    });
  };

  unselectRow = () => {
    this.setState({
      ...this.state,
      selectedRow: {
        id: undefined,
        index: undefined
      }
    });
  };

  getRowHeight = index => {
    const { participants } = this.props;
    let rowHeight;
    if (
      index < 0 ||
      (this.state.searchActive && index >= this.state.filteredParties.length)
    ) {
      return 0;
    }

    let participantId = this.state.searchActive
      ? this.state.filteredParties[index].id
      : participants.connectedParties[index].id;

    if (participantId === this.state.selectedRow.id) {
      //the expanded row is the first child of the original row
      rowHeight = this.selectedRowRef.firstChild.offsetHeight;
    } else {
      rowHeight = rowHeightDefaultPx;
    }
    return rowHeight;
  };

  handleCloseWindow = () => {
    const { isDrawer } = this.props;
    if (isDrawer) {
      this.props.hideInternalDrawer();
    } else {
      this.props.hideParticipantListPopout();
    }
  };

  handleInvite = () => {
    this.props.showInviteParticipant();
  };

  handleMuteAll = event => {
    this.handleConferenceApiRequest(this.props.session.userId, "mute_conf");
  };

  handleUnmuteAll = event => {
    this.handleConferenceApiRequest(this.props.session.userId, "unmute_conf");
  };

  handleDisconnectAll = event => {
    this.handleParticipantApiRequest(
      event,
      this.props.participants.connectedParties.map(
        participant => participant.partyID
      ),
      this.props.session.userId,
      "disconnect"
    );
  };

  handleMuteParticipant = (event, id) => {
    this.handleParticipantApiRequest(
      event,
      id,
      this.props.session.userId,
      "mute"
    );
  };

  handleUnmuteParticipant = (event, id) => {
    this.handleParticipantApiRequest(
      event,
      id,
      this.props.session.userId,
      "unmute"
    );
  };

  handleConnectParticipant = (
    event,
    id,
    partyID,
    name,
    userLevel,
    ud1,
    ud2,
    ud3,
    ud4,
    me
  ) => {
    event && event.stopPropagation(); //don't click the containing row
    if (me) {
      //open ConnectPopper if currentUser
      this.props.setConnectPopperExpansion(true);
    } else if (partyID == null && !me) {
      //open InviteByPhoneWindow if not the current user & partyId is null
      if (
        this.props.inviteParticipant.inviteByPhoneWindowStatus ===
        InviteByPhoneWindowState.OPEN
      ) {
        this.props.hideInviteByPhone();
      }
      this.props.updateInviteByPhoneParticipant({
        userId: id,
        partyName: name,
        partyIsModerator: userLevel === "1",
        partyUserDefined1: ud1,
        partyUserDefined2: ud2,
        partyUserDefined3: ud3,
        partyUserDefined4: ud4,
        partyNameReadOnly: true
      });
      this.props.showInviteByPhone();
    } else {
      //join party to the conference otherwise
      this.handleParticipantApiRequest(
        event,
        partyID,
        this.props.session.userId,
        "call_join"
      );
    }
  };

  handleDisconnectParticipant = (event, id) => {
    this.handleParticipantApiRequest(
      event,
      id,
      this.props.session.userId,
      "disconnect"
    );
  };

  handleRemoveParticipant = (event, id) => {
    console.log("handleRemoveParticipant", event, id);
    this.handleParticipantApiRequest(
      event,
      id,
      this.props.session.userId,
      "remove"
    );
  };

  handleParticipantApiRequest = (event, partyIds, userId, apiEndpoint) => {
    event && event.stopPropagation();
    console.log(`party ${apiEndpoint}`, event, partyIds, userId);

    let ids = "";
    if (partyIds instanceof Array) {
      for (var i = 0; i < partyIds.length; i++) {
        ids += `partyIds[]=${partyIds[i]}`;
        if (i < partyIds.length - 1) {
          ids += "&";
        }
      }
    } else {
      ids = `partyIds[]=${partyIds}`;
    }

    const uri = `/party/${apiEndpoint}/${userId}`;
    const requestBody = `${ids}&reqSeq=1`;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    let response;
    try {
      response = contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      console.log(`${uri} response:`, response);
    } catch (error) {
      console.log(error);
    }
  };

  handleConferenceApiRequest = (userId, apiEndpoint) => {
    console.log(`conference ${apiEndpoint}`, userId);

    const uri = `/conference/${apiEndpoint}/${userId}`;
    const requestBody = "reqSeq=1";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    let response;
    try {
      response = contexWebRest.post(uri, requestBody, {
        headers: headers
      });
      console.log(`${uri} response:`, response);
    } catch (error) {
      console.log(error);
    }
  };

  handleHeaderClick = dataKey => {
    console.log("headerClick", dataKey);
    const { sortParticipants } = this.props;
    let rootDataKey;
    if (dataKey === "typeIcon") {
      rootDataKey = "connectState";
    }
    this.unselectRow();
    sortParticipants(dataKey, rootDataKey);
  };

  handleHeaderKeyUp = (event, dataKey) => {
    console.log("headerKeyUp", event.which, dataKey);
    if (event.which === 13 || event.which === 32) {
      this.handleHeaderClick(dataKey);
    }
  };

  getMuteIcon = (
    participantPartyID,
    particpantConnected,
    participantHolded,
    participantMuted,
    emptyPlaceholderIcon,
    me,
    connectState
  ) => {
    const { classes, intl, session } = this.props;
    //TODO when merging users and parties, allow guest to control self
    const callConnected =
      session.mergedCallState === "connected" ? true : false;
    const disabled =
      participantHolded ||
      connectState === "MuteOperator" ||
      connectState === "TalkOperator" ||
      (!me && (!callConnected || !isHost(session)));
    let muteIcon;

    if (!particpantConnected) {
      muteIcon = emptyPlaceholderIcon;
    } else if (participantHolded) {
      muteIcon = (
        <IconButton
          className={this.props.classes.statusIconButton}
          disabled={disabled}
          title={
            intl.formatMessage(getIntl("participantOnHold")) +
            TitleNewLine +
            intl.formatMessage(getIntl("controlDisabled"))
          }
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName="audioMute"
            color="inactive"
            className={classes.standardIcon}
          />
        </IconButton>
      );
    } else if (participantMuted) {
      muteIcon = (
        <IconButton
          className={this.props.classes.statusIconButton}
          disabled={disabled}
          onClick={event =>
            this.handleUnmuteParticipant(event, participantPartyID)
          }
          title={
            intl.formatMessage(getIntl("participantMuted")) +
            TitleNewLine +
            (disabled
              ? intl.formatMessage(getIntl("controlDisabled"))
              : intl.formatMessage(getIntl("clickToUnmuteParticipant")))
          }
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName="audioMute"
            color={disabled ? "inactive" : "active"}
            className={classes.standardIcon}
          />
        </IconButton>
      );
    } else {
      muteIcon = (
        <IconButton
          className={this.props.classes.statusIconButton}
          disabled={disabled}
          onClick={event =>
            this.handleMuteParticipant(event, participantPartyID)
          }
          title={
            intl.formatMessage(getIntl("participantUnmuted")) +
            TitleNewLine +
            (disabled
              ? intl.formatMessage(getIntl("controlDisabled"))
              : intl.formatMessage(getIntl("clickToMuteParticipant")))
          }
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName="audioUnmute"
            color={disabled ? "inactive" : "active"}
            className={classes.standardIcon}
          />
        </IconButton>
      );
    }

    return muteIcon;
  };

  getConnectIcon = (participant, particpantConnected) => {
    const { classes, session, intl } = this.props;
    const callConnected =
      session.mergedCallState === "connected" ? true : false;
    const disabled = !participant.me && !callConnected;

    return isHost(session) || participant.me ? (
      !particpantConnected ? (
        <IconButton
          className={this.props.classes.statusIconButton}
          disabled={disabled}
          onClick={event =>
            this.handleConnectParticipant(
              event,
              participant.id,
              participant.partyID,
              participant.name,
              participant.userLevel,
              participant.userDefined,
              participant.userDefined2,
              participant.userDefined3,
              participant.userDefined4,
              participant.me
            )
          }
          title={
            intl.formatMessage(getIntl("participantDisconnected")) +
            TitleNewLine +
            (disabled
              ? intl.formatMessage(getIntl("controlDisabled")) +
                TitleNewLine +
                intl.formatMessage(getIntl("connectYourAudioToEnable"))
              : intl.formatMessage(getIntl("clickToConnectParticipant")))
          }
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon
            iconName="connectCall"
            color={disabled ? "inactive" : "connect"}
            className={classes.standardIcon}
          />
        </IconButton>
      ) : (
        <CTXIconButtonConfirmModal
          onConfirm={(event, obj) => {
            this.handleDisconnectParticipant(event, participant.partyID);
            if (obj.eject) {
              this.props.deleteUser(session.userId, participant.id);
            }
          }}
          data={participant}
          removeWebUser={isHost(session)}
          disabled={disabled}
          iconClass={classes.statusIconButton}
          svgName="disconnectCall"
          svgColor={disabled ? "inactive" : "disconnect"}
          svgClass={classes.standardIcon}
          label="disconnectParticipant"
          name="disconnectParty"
          title={
            intl.formatMessage(getIntl("participantConnected")) +
            TitleNewLine +
            (disabled
              ? intl.formatMessage(getIntl("controlDisabled"))
              : intl.formatMessage(getIntl("clickToDisconnectParticipant")))
          }
          style={disabled ? { pointerEvents: "auto" } : undefined}
        />
      )
    ) : (
      <IconButton
        className={classNames(classes.statusIconButton, classes.hidden)}
      >
        <SvgIcon iconName="" className={classes.standardIcon} />
      </IconButton>
    );
  };

  getPresenterModeControls = (participant, emptyPlaceholderIcon) => {
    const { classes, session, intl, roomClientProvider } = this.props;

    return participant.dataConnected ? (
      participant.id === session.sharingUserId ? (
        <IconButton
          className={this.props.classes.statusIconButton}
          title={
            intl.formatMessage(getIntl("screenShareInProcess")) +
            TitleNewLine +
            intl.formatMessage(getIntl("clickToStopScreenShare"))
          }
          onClick={event => {
            event && event.stopPropagation();
            if (session.sharingUserId === session.id) {
              roomClientProvider.disableShare();
            } else {
              this.props.stopScreenShare(session.userId, participant.id);
            }
          }}
        >
          <SvgIcon
            color={"disconnect"}
            iconName="stopShare"
            className={classes.standardIcon}
          />
        </IconButton>
      ) : participant.userLevel === "1" ? (
        emptyPlaceholderIcon
      ) : participant.presenterMode ? (
        <IconButton
          className={this.props.classes.statusIconButton}
          title={
            intl.formatMessage(getIntl("presenterModeEnabled")) +
            TitleNewLine +
            intl.formatMessage(getIntl("clickToDisablePresenterMode"))
          }
          onClick={event => {
            event && event.stopPropagation();
            this.props.disablePresenterMode(session.userId, participant.id);
          }}
        >
          <SvgIcon
            iconName="revokeScreenSharePermission"
            className={classes.standardIcon}
          />
        </IconButton>
      ) : (
        <IconButton
          className={this.props.classes.statusIconButton}
          title={
            intl.formatMessage(getIntl("presenterModeDisabled")) +
            TitleNewLine +
            intl.formatMessage(getIntl("clickToEnablePresenterMode"))
          }
          onClick={event => {
            event && event.stopPropagation();
            this.props.enablePresenterMode(session.userId, participant.id);
          }}
        >
          <SvgIcon
            iconName="grantScreenSharePermission"
            className={classes.standardIcon}
          />
        </IconButton>
      )
    ) : (
      emptyPlaceholderIcon
    );
  };

  getAdmitIcon = participantId => {
    const { classes, session, intl } = this.props;
    const disabled = session.userLevel === "2";

    return (
      <IconButton
        className={classes.statusIconButton}
        disabled={disabled}
        title={
          intl.formatMessage(getIntl("participantInWaitingRoom")) +
          TitleNewLine +
          intl.formatMessage(getIntl("clickToAdmit"))
        }
        style={disabled ? { pointerEvents: "auto" } : undefined}
        onClick={event => {
          event && event.stopPropagation();
          this.setState({
            searchActive: false
          });
          this.props.admitUser(session.userId, participantId);
        }}
      >
        <SvgIcon
          iconName="admitParticipant"
          color={disabled ? "inactive" : "primary"}
          className={classes.standardIcon}
        />
      </IconButton>
    );
  };

  getAdmitAllIcon = () => {
    const { classes, session, intl, participants } = this.props;
    const disabled = session.userLevel === "2";

    let targerUserIds = [];
    participants.waitingRoomParties.forEach(participant =>
      targerUserIds.push(participant.id)
    );

    return (
      <IconButton
        className={classes.statusIconButton}
        disabled={disabled}
        title={
          intl.formatMessage(getIntl("participantInWaitingRoom")) +
          TitleNewLine +
          intl.formatMessage(getIntl("clickToAdmitAll"))
        }
        style={disabled ? { pointerEvents: "auto" } : undefined}
        onClick={event => {
          event && event.stopPropagation();
          this.props.admitAllUsers(session.userId, targerUserIds);
        }}
      >
        <SvgIcon
          iconName="admitParticipant"
          color={disabled ? "inactive" : "primary"}
          className={classes.standardIcon}
        />
      </IconButton>
    );
  };

  getHiddenIcon = () => {
    const { classes } = this.props;
    return (
      <IconButton className={classes.statusIconButton} disabled={true}>
        <SvgIcon color={"inactive"} className={classes.standardIcon} />
      </IconButton>
    );
  };

  getRejectIcon = participantId => {
    const { classes, session, intl } = this.props;
    const disabled = session.userLevel === "2";

    return (
      <IconButton
        className={classes.statusIconButton}
        disabled={disabled}
        title={
          intl.formatMessage(getIntl("participantInWaitingRoom")) +
          TitleNewLine +
          intl.formatMessage(getIntl("clickToReject"))
        }
        style={disabled ? { pointerEvents: "auto" } : undefined}
        onClick={event => {
          event && event.stopPropagation();
          this.props.deleteUser(session.userId, participantId);
        }}
      >
        <SvgIcon
          iconName="rejectParticipant"
          color={disabled ? "inactive" : "error"}
          className={classes.standardIcon}
        />
      </IconButton>
    );
  };

  getMuteAllIcon = () => {
    const { conference, session, classes, intl } = this.props;
    const callConnected =
      session.mergedCallState === "connected" ? true : false;
    const disabled = !callConnected;
    return conference.muteState ? (
      <IconButton
        className={classes.iconButton}
        disabled={disabled}
        onClick={event => this.handleUnmuteAll(event)}
        title={
          intl.formatMessage(getIntl("conferenceMuted")) +
          TitleNewLine +
          (disabled
            ? intl.formatMessage(getIntl("controlDisabled")) +
              TitleNewLine +
              intl.formatMessage(getIntl("connectYourAudioToEnable"))
            : intl.formatMessage(getIntl("clickToUnmuteConference")))
        }
        style={disabled ? { pointerEvents: "auto" } : undefined}
      >
        <SvgIcon
          iconName="audioMute"
          color={disabled ? "inactive" : "active"}
          className={classes.standardIcon}
        />
      </IconButton>
    ) : (
      <IconButton
        className={classes.iconButton}
        disabled={disabled}
        onClick={event => this.handleMuteAll(event)}
        title={
          intl.formatMessage(getIntl("conferenceUnmuted")) +
          TitleNewLine +
          (disabled
            ? intl.formatMessage(getIntl("controlDisabled")) +
              TitleNewLine +
              intl.formatMessage(getIntl("connectYourAudioToEnable"))
            : intl.formatMessage(getIntl("clickToMuteConference")))
        }
        style={disabled ? { pointerEvents: "auto" } : undefined}
      >
        <SvgIcon
          iconName="audioUnmute"
          color={disabled ? "inactive" : "active"}
          className={classes.standardIcon}
        />
      </IconButton>
    );
  };

  getDisconnectAllIcon = () => {
    const { classes, session, intl } = this.props;
    const callConnected =
      session.mergedCallState === "connected" ? true : false;
    const disabled = !callConnected;

    return (
      <CTXIconButtonConfirmModal
        onConfirm={event => this.handleDisconnectAll(event)}
        disabled={disabled}
        iconClass={classes.iconButton}
        svgName={"disconnectAll"}
        svgColor={disabled ? "inactive" : "active"}
        svgClass={classes.standardIcon}
        label="disconnectAllParticipants"
        name="disconnectAll"
        title={
          intl.formatMessage(getIntl("disconnectAllParticipants")) +
          TitleNewLine +
          (disabled
            ? intl.formatMessage(getIntl("controlDisabled")) +
              TitleNewLine +
              intl.formatMessage(getIntl("connectYourAudioToEnable"))
            : intl.formatMessage(getIntl("clickToDisconnectAllParticipants")))
        }
        style={disabled ? { pointerEvents: "auto" } : undefined}
      />
    );
  };

  getInviteIcon = () => {
    const { classes, intl } = this.props;
    return (
      <IconButton
        className={classes.iconButton}
        disabled={false}
        onClick={() => this.handleInvite()}
        title={intl.formatMessage(getIntl("invite"))}
        style={{ pointerEvents: "auto" }}
      >
        <SvgIcon
          iconName="add"
          color={"active"}
          className={classes.inviteIcon}
        />
      </IconButton>
    );
  };

  getSearchIcon = () => {
    const { participants, classes, intl } = this.props;
    const disabled = participants.connectedParties.length < 1;

    return (
      <IconButton
        className={classNames(
          classes.iconButton,
          this.state.searchActive ? classes.activeButton : ""
        )}
        disabled={disabled}
        onClick={() => this.handleSearch()}
        title={intl.formatMessage(getIntl("search"))}
        style={disabled ? { pointerEvents: "auto" } : undefined}
      >
        <SvgIcon
          iconName="search"
          color={disabled ? "inactive" : "active"}
          className={classes.standardIcon}
        />
      </IconButton>
    );
  };

  getPopoutIcon = () => {
    const { classes, isDrawer, participantListPopoutWindow, intl } = this.props;
    const disabled =
      participantListPopoutWindow.windowStatus === ParticipantsPopoutState.OPEN;

    return isDrawer ? (
      <IconButton
        id="participantPopoutClick"
        className={classes.closeHeaderButton}
        disabled={disabled}
        onClick={() => this.handlePopoutClick()}
        title={intl.formatMessage(getIntl("popoutIntoNewWindow"))}
        style={disabled ? { pointerEvents: "auto" } : undefined}
      >
        <SvgIcon
          iconName="popout"
          color={disabled ? "inactive" : "active"}
          className={classes.closeHeaderIcon}
        />
      </IconButton>
    ) : null;
  };

  handlePopoutClick = () => {
    this.props.showParticipantListPopout();
    this.props.hideInternalDrawer();
  };

  getDrawerHeader = () => {
    const { isDrawer, classes, intl } = this.props;
    const disabled = false;
    let content;
    if (isDrawer) {
      content = (
        <div className={classes.drawerHeader}>
          {this.getPopoutIcon()}
          <IconButton
            className={classes.closeHeaderButton}
            disabled={disabled}
            onClick={() => this.handleCloseWindow()}
            title={intl.formatMessage(getIntl("close"))}
            style={disabled ? { pointerEvents: "auto" } : undefined}
          >
            <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
          </IconButton>
        </div>
      );
    } else {
      content = null;
    }
    return content;
  };

  // Force Material-UI to load styles needed in new window
  getClassesForPopout = () => {
    return (
      <div style={{ display: "none" }}>
        <TextField
          fullWidth={true}
          InputProps={{
            disableUnderline: true,
            inputRef: r => (this.searchRef = r),
            inputProps: {
              "aria-label": placeholder
            }
          }}
        />
        <Avatar />
        <TextButton>text</TextButton>
        <CTXIconButtonConfirmModal />
        <FormControlLabel control={<Checkbox />} />
      </div>
    );
  };

  getPresenterModeDemoteAllIcon = () => {
    const { intl, session, classes } = this.props;

    return (
      <IconButton
        className={this.props.classes.statusIconButton}
        title={intl.formatMessage(
          getIntl("disableAllParticipantsPresenterMode")
        )}
        onClick={event => {
          this.props.disableConferencePresenterMode(session.userId);
        }}
      >
        <SvgIcon
          iconName="revokeScreenSharePermission"
          className={classes.standardIcon}
        />
      </IconButton>
    );
  };

  render() {
    const { classes, participants, containerWidth, session, isDrawer } =
      this.props;

    const totalParties =
      participants.waitingRoomParties.length +
      participants.connectedParties.length +
      participants.idleParties.length;

    let width = containerWidth - tableSidePaddingPx - scrollBarSidePaddingPx;
    let idlePartyDivHeight = Math.min(
      25,
      (100 / totalParties) * participants.idleParties.length
    );

    let waitingRoomDivHeight = Math.min(
      25,
      (100 / totalParties) * participants.waitingRoomParties.length
    );

    let connectedPartyDivHeight =
      100 - waitingRoomDivHeight - idlePartyDivHeight;

    let idlePartyDivHeightValue = idlePartyDivHeight + "%";
    let waitingRoomDivHeightValue = waitingRoomDivHeight + "%";
    let connectedPartyDivHeightValue = connectedPartyDivHeight + "%";

    return (
      <div
        className={classNames(
          classes.root,
          isDrawer ? classes.rootMaxWidth : null
        )}
      >
        <Paper className={classes.paper}>
          {!isMobileOrTablet() && this.getDrawerHeader()}

          {participants.waitingRoomParties.length > 0 && (
            <Fragment>
              <div className={classes.header}>
                <div className={classes.headerLeft}>
                  <div className={classes.headerLabel}>
                    <LocalizedText value="waitingRoom" variant="h6" />
                    <Typography variant="h6">
                      &nbsp;({participants.waitingRoomParties.length})
                    </Typography>
                  </div>
                </div>
                <div className={classes.waitingRoomHeaderRight}>
                  {this.getAdmitAllIcon()}
                  {this.getHiddenIcon()}
                </div>
              </div>
              <div className={classes.hrDiv} />
              <div
                className={classes.tableSection}
                style={{
                  minHeight: "40px",
                  height: waitingRoomDivHeightValue
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: width
                  }}
                >
                  <VirtualizedList
                    innerRef={r => (this.idlePartyWrappedRef = r)}
                    deferredMeasurementCache={this.cache}
                    noFocusOutline
                    rowCount={participants.waitingRoomParties.length}
                    rowHeight={rowHeightDefaultPx}
                    rowRenderer={({
                      index,
                      isScrolling,
                      isVisible,
                      key,
                      parent,
                      style
                    }) =>
                      this.getDataRow(
                        PartyType.waitingRoomParty,
                        index,
                        isScrolling,
                        isVisible,
                        key,
                        parent,
                        style,
                        isHost(session)
                      )
                    }
                  />
                </div>
              </div>
              <div className={classes.sectionSeparator} />
            </Fragment>
          )}

          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <div className={classes.headerLabel}>
                <LocalizedText value="participantList" variant="h6" />
                <Typography variant="h6">
                  &nbsp;({participants.connectedParties.length})
                </Typography>
              </div>
              {isHost(session) && this.getInviteIcon()}
              {this.getSearchIcon()}
            </div>
            <div className={classes.headerRight}>
              {isHost(session) && this.getMuteAllIcon()}
              {isHost(session) && this.getDisconnectAllIcon()}
              {isHost(session) && this.getPresenterModeDemoteAllIcon()}
            </div>
          </div>
          <div className={classes.header}>{this.getHeaderRow()}</div>
          <div
            className={classes.tableSection}
            style={{
              minHeight: "40px",
              height: connectedPartyDivHeightValue
            }}
          >
            <div
              style={{
                height: "100%",
                width: width
              }}
            >
              <VirtualizedList
                innerRef={r => (this.wrappedRef = r)}
                rowCount={participants.connectedParties.length}
                rowHeight={({ index }) => this.getRowHeight(index)}
                rowRenderer={({
                  index,
                  isScrolling,
                  isVisible,
                  key,
                  parent,
                  style
                }) =>
                  this.getDataRow(
                    PartyType.connectedParty,
                    index,
                    isScrolling,
                    isVisible,
                    key,
                    parent,
                    style,
                    isHost(session)
                  )
                }
              />
            </div>
          </div>
          <div className={classes.sectionSeparator} />

          {participants.idleParties.length > 0 && (
            <Fragment>
              <div className={classNames(classes.header, classes.columnFlex)}>
                <div className={classes.headerLeft}>
                  <div className={classes.headerLabel}>
                    <LocalizedText value="idleParticipants" variant="h6" />
                    <Typography variant="h6">
                      &nbsp;({participants.idleParties.length})
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.hrDiv} />

              <div
                className={classes.tableSection}
                style={{
                  minHeight: "40px",
                  height: idlePartyDivHeightValue
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: width
                  }}
                >
                  <VirtualizedList
                    innerRef={r => (this.idlePartyWrappedRef = r)}
                    deferredMeasurementCache={this.cache}
                    noFocusOutline
                    rowCount={participants.idleParties.length}
                    rowHeight={rowHeightDefaultPx}
                    rowRenderer={({
                      index,
                      isScrolling,
                      isVisible,
                      key,
                      parent,
                      style
                    }) =>
                      this.getDataRow(
                        PartyType.idleParty,
                        index,
                        isScrolling,
                        isVisible,
                        key,
                        parent,
                        style,
                        isHost(session)
                      )
                    }
                  />
                </div>
              </div>
              <div className={classes.sectionSeparator} />
            </Fragment>
          )}
        </Paper>
        {this.getClassesForPopout()}
      </div>
    );
  }
}

const mapStateToProps = ({
  participants,
  conference,
  session,
  participantListPopoutWindow,
  inviteParticipant
}) => ({
  participants,
  conference,
  session,
  participantListPopoutWindow,
  inviteParticipant
});

const mapDispatchToProps = dispatch => ({
  showParticipantListPopout: () =>
    dispatch(showParticipantListPopout(participantListPopoutWindowName)),
  hideInternalDrawer: () => dispatch(hideInternalDrawer()),
  hideParticipantListPopout: () => dispatch(hideParticipantListPopout()),
  sortParticipants: (dataKey, rootDataKey) =>
    dispatch(sortParticipants({ dataKey, rootDataKey })),
  showInviteParticipant: () => dispatch(showInviteParticipant()),
  setConnectPopperExpansion: payload =>
    dispatch(setConnectPopperExpansion(payload)),
  showInviteByPhone: () => dispatch(showInviteByPhone()),
  hideInviteByPhone: () => dispatch(hideInviteByPhone()),
  updateInviteByPhoneParticipant: newState =>
    dispatch(updateInviteByPhoneParticipant(newState)),
  enablePresenterMode: (userId, targetUserId) =>
    dispatch(enablePresenterMode(userId, targetUserId)),
  disablePresenterMode: (userId, targetUserId) =>
    dispatch(disablePresenterMode(userId, targetUserId)),
  disableConferencePresenterMode: userId =>
    dispatch(disableConferencePresenterMode(userId)),
  stopScreenShare: (userId, targetUserId) =>
    dispatch(stopScreenShare(userId, targetUserId)),
  deleteUser: (hostID, userID) => dispatch(deleteUser(hostID, userID)),
  admitUser: (hostID, userID) => dispatch(admitUser(hostID, userID)),
  admitAllUsers: (hostID, targetUserIds) =>
    dispatch(admitAllUsers(hostID, targetUserIds))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      withRoomContext(
        connect(mapStateToProps, mapDispatchToProps, null, {
          forwardRef: true
        })(ParticipantList)
      )
    )
  )
);
