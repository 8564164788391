import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine, isHost } from "../../utils";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import { getTextColor } from "../../style";
import { withTheme } from "@material-ui/core/styles";
import { handleConferenceApiRequest } from "../../actions";
import {
  showNotificationWindow,
  setNotificationLevel,
  setNotificationType
} from "../notification";

const styles = theme => ({
  item: {
    width: "6em",
    textAlign: "center",
    padding: "0px 2px 0px 2px"
  },
  iconButton: {
    width: "64px"
  }
});

class RecordIcon extends Component {
  handleConferenceStartRecord = event => {
    const { session } = this.props;

    this.props.handleConferenceApiRequest(session.userId, "start_cn_recording");

    //close MorePopper
    this.handleClickAway();
  };

  handleConferenceStopRecord = () => {
    const { session } = this.props;

    this.props.handleConferenceApiRequest(session.userId, "stop_cn_recording");

    //close MorePopper
    this.handleClickAway();
  };

  handleClickAway = value => {
    console.log("In handleClickAway of recordIcon");
    this.props.onClick(value);
  };

  getConferenceRecordControl = () => {
    const { classes, session, intl, theme } = this.props;
    let controlElement = null;

    if (isHost(session)) {
      controlElement = (
        <Fragment>
          <div className={classes.item}>
            {!session.recordingActive ? (
              <Fragment>
                <IconButton
                  className={classes.iconButton}
                  onClick={event => this.handleConferenceStartRecord(event)}
                  title={
                    intl.formatMessage(
                      getIntl("conferenceIsNotBeingRecorded")
                    ) +
                    TitleNewLine +
                    intl.formatMessage(getIntl("clickToRecordConference"))
                  }
                >
                  <SvgIcon iconName="record" color="active" />
                </IconButton>
                <div>
                  <LocalizedText
                    value="record"
                    variant="subtitle2"
                    style={{ color: getTextColor(theme, false) }}
                  />
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <IconButton
                  className={classes.iconButton}
                  onClick={event => this.handleConferenceStopRecord(event)}
                  title={
                    intl.formatMessage(getIntl("conferenceIsBeingRecorded")) +
                    TitleNewLine +
                    intl.formatMessage(
                      getIntl("clickToStopRecordingConference")
                    )
                  }
                >
                  <SvgIcon iconName="stopRecord" color="active" />
                </IconButton>
                <div>
                  <LocalizedText
                    value="stopRecording"
                    variant="subtitle2"
                    style={{ color: getTextColor(theme, false) }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </Fragment>
      );
    }

    return controlElement;
  };

  render() {
    return <Fragment>{this.getConferenceRecordControl()}</Fragment>;
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  handleConferenceApiRequest: (userId, apiEndpoint) =>
    dispatch(handleConferenceApiRequest(userId, apiEndpoint)),
  showNotificationWindow: () => dispatch(showNotificationWindow()),
  setNotificationLevel: level => dispatch(setNotificationLevel(level)),
  setNotificationType: type => dispatch(setNotificationType(type))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(RecordIcon))
  )
);
