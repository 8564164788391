import { CHAT_POPOUT_SHOW, CHAT_POPOUT_HIDE } from "./actions";
import { ChatPopoutState } from "./component";
import { USER_LOGOUT } from "../../../actions/types";

export default function(
  state = {
    windowStatus: ChatPopoutState.CLOSE,
    windowName: undefined
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case CHAT_POPOUT_SHOW:
      newState = {
        ...state,
        windowStatus: ChatPopoutState.OPEN,
        windowName: action.payload
      };
      return newState;
    case USER_LOGOUT:
    case CHAT_POPOUT_HIDE:
      newState = {
        ...state,
        windowStatus: ChatPopoutState.CLOSE,
        windowName: undefined
      };
      return newState;
    default:
      return state;
  }
}
