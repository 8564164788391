import React, { Component } from "react";
import LocalizedText from "../reusable/LocalizedText";
import classNames from "classnames";
import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getIntl } from "../../utils";
import { setThemeEditorModalState } from "./actions";
import { IconButton } from "@material-ui/core";
import PortalHeader from "./PortalHeader";
import CTXTable from "../reusable/CTXTable";
import TextButton from "../reusable/TextButton";
import SvgIcon from "../Icons/SvgIcon";
import Typography from "@material-ui/core/Typography";
import { MainPageDummy } from "../pages";
import Stop from "@material-ui/icons/Stop";
import ThemeEditorModal from "./ThemeEditorModal";
import {
  getThemes,
  uploadImage,
  updateDefaultTheme,
  setPortalSelectedThemeId
} from "./actions";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  header: {
    minWidth: "1160px",
    height: "50px",
    flexDirection: "row",
    margin: "10px 20px 10px 20px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  hrDiv: {
    borderTop: `4px solid ${theme.colors.primaryTextColor}`
  },
  mainContentContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    margin: "10px 15px 10px 15px"
  },
  mainContentTopContainer: {
    width: "100%",
    paddingRight: "10px"
  },
  mainContentMidContainer: {
    width: "100%",
    minWidth: "1000px"
  },
  mainContentBottomContainer: {
    width: "100%",
    minWidth: "1000px",
    paddingRight: "10px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.colors.popoverBackgroundColor,
    backgroundClip: "content-box"
  },
  detailsContainer: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: "40%",
    display: "flex",
    flexDirection: "column"
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "1.5em",
    paddingLeft: "20px",
    paddingBottom: "10px"
  },
  detailsAndcolorPicker: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "15px 10px 15px 20px"
  },
  detailsHeading: {
    padding: "30px 10px 20px 40px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  paletteContainer: {
    width: "60%",
    margin: "20px 20px 20px 10px",
    border: `5px solid ${theme.colors.secondaryMainColor}`
  },
  textColor: {
    paddingRight: "10px",
    color: theme.colors.primaryTextColor
  },
  iconBorder: {
    borderColor: theme.colors.primaryTextColor,
    fontSize: "1.5em"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  flexGrow: {
    flexGrow: 1
  },
  toolbarContainer: {
    paddingRight: "20px"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  input: {
    display: "none"
  },
  iconButton: {
    width: "64px",
    paddingTop: "0px"
  },
  graphicIcon: {
    color: theme.colors.activeIconColor,
    width: "2em"
  },
  marginRight: {
    marginRight: "10px"
  }
});

class PortalThemeManager extends Component {
  constructor(props) {
    super(props);
    this.inputReference = React.createRef();
  }

  componentDidMount() {
    this.props.getThemes(this.props.session.portalSessionId);
  }

  componentWillUnmount() {
    this.props.setPortalSelectedThemeId(0);
  }

  getToolbarControls = () => {
    const { classes } = this.props;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          classes.alignItemsCenter,
          classes.toolbarContainer,
          classes.flexGrow
        )}
      >
        {this.getThemeActionControls()}
      </div>
    );
  };

  getThemeActionControls = () => {
    const { classes } = this.props;
    return (
      <div className={classes.displayFlexRow}>
        <TextButton
          onClick={this.handleNewTheme}
          className={classes.marginRight}
        >
          <LocalizedText value="newTheme" />
        </TextButton>
        <TextButton component="span" onClick={this.fileUploadAction}>
          <LocalizedText value="uploadGraphics" />
        </TextButton>
        <input
          accept="image/*"
          className={classes.input}
          id={"uploadGraphics"}
          type="file"
          ref={this.inputReference}
          onChange={this.handleUploadGraphics}
        />
      </div>
    );
  };

  getTableData = themes => {
    var getTableDataResult = [];

    if (themes != null) {
      for (let [, theme] of Object.entries(themes)) {
        if (theme.id !== 0) {
          let tableRowData = {};
          tableRowData.id = theme.id;
          tableRowData.isDefault = theme.isDefault;
          tableRowData.themeName = theme.themeName;
          tableRowData.color1 = theme.color1.toUpperCase();
          tableRowData.color2 = theme.color2.toUpperCase();
          tableRowData.color3 = theme.color3.toUpperCase();
          tableRowData.color4 = theme.color4.toUpperCase();
          tableRowData.themePalette = (
            <div>
              <Stop style={{ color: theme.color1 }} />
              <Stop style={{ color: theme.color2 }} />
              <Stop style={{ color: theme.color3 }} />
              <Stop style={{ color: theme.color4 }} />
            </div>
          );
          tableRowData.backgroundImage = theme.backgroundImage;
          tableRowData.headerLogo = theme.headerLogo;
          tableRowData.bodyLogo = theme.bodyLogo;
          tableRowData.portalLogo = theme.portalLogo;
          tableRowData.portalHeaderLogo = theme.portalHeaderLogo;
          getTableDataResult.push(tableRowData);
        }
      }
    }
    return getTableDataResult;
  };

  handleOnRowClick = (event, rowData) => {
    if (rowData !== null) {
      this.props.setPortalSelectedThemeId(rowData.id);
    }
  };

  getColorRow = (color1Title, colorValue) => {
    const { classes } = this.props;
    return (
      <div className={classes.detailsAndcolorPicker}>
        <div className={classes.detailsRow}>
          <LocalizedText value={color1Title} variant="subtitle2" />
          <Typography className={classes.textColor} variant="subtitle2">
            {": "}
          </Typography>
          <Stop style={{ color: colorValue }} className={classes.iconBorder} />
          <Typography className={classes.textColor} variant="subtitle2">
            {colorValue.toUpperCase()}
          </Typography>
        </div>
      </div>
    );
  };

  handleSetDefaultTheme = () => {
    const { selectedThemeId, selectedTheme } = this.props.portal.theme;
    if (selectedThemeId !== 0 && selectedTheme != null) {
      this.props.updateDefaultTheme(
        this.props.session.portalSessionId,
        selectedThemeId,
        true
      );
    }
  };

  handleEditTheme = () => {
    this.props.setThemeEditorModalState(true);
  };

  fileUploadAction = () => this.inputReference.current.click();

  handleUploadGraphics = event => {
    let formData = new FormData();
    formData.append("file", event.target.files[0]);
    this.props.uploadImage(this.props.session.portalSessionId, formData);
  };

  handleNewTheme = () => {
    const themes = this.props.portal.theme.themeMap;
    if (themes != null) {
      this.props.setPortalSelectedThemeId(0);
      this.props.setThemeEditorModalState(true);
    }
  };

  getThemeEditorModal = () => {
    return <ThemeEditorModal open={true} />;
  };

  render() {
    const { classes, intl } = this.props;
    const { translations } = this.props.languages;
    const { themeMap, selectedThemeId, selectedTheme, isThemeEditorModalOpen } =
      this.props.portal.theme;

    const tableTitle = translations.themes;
    const tableColumns = [
      { title: "Key", field: "id", hidden: true },
      {
        title: translations.default,
        field: "isDefault",
        type: "boolean",
        width: "50px"
      },
      { title: translations.themeName, field: "themeName" },
      { title: translations.color1Title, field: "color1", hidden: true },
      { title: translations.color2Title, field: "color2", hidden: true },
      { title: translations.color3Title, field: "color3", hidden: true },
      { title: translations.color4Title, field: "color4", hidden: true },
      { title: translations.themePalette, field: "themePalette" },
      { title: translations.backgroundImage, field: "backgroundImage" },
      { title: translations.headerLogo, field: "headerLogo" },
      { title: translations.bodyLogo, field: "bodyLogo" },
      { title: translations.portalHeaderLogo, field: "portalHeaderLogo" },
      { title: translations.portalLogo, field: "portalLogo" }
    ];

    let tableData = this.getTableData(themeMap);
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <PortalHeader title={"themeManager"} />
        </div>
        <div
          className={classNames(
            classes.mainContentContainer,
            classes.scrollBar
          )}
        >
          <div className={classes.mainContentTopContainer} />
          <div className={classes.mainContentMidContainer}>
            <CTXTable
              title={tableTitle}
              columns={tableColumns}
              data={tableData}
              onRowClick={this.handleOnRowClick}
              toolbar={this.getToolbarControls()}
              enableSelectedRowStyle
            />
          </div>
          {selectedThemeId !== 0 && selectedTheme != null ? (
            <div className={classes.mainContentBottomContainer}>
              <div className={classes.detailsContainer}>
                <Typography
                  variant="h6"
                  className={classes.detailsHeading}
                  color="inherit"
                >
                  {selectedTheme.themeName}
                </Typography>
                <div className={classes.hrDiv} />
                {this.getColorRow("color1Title", selectedTheme.color1)}
                {this.getColorRow("color2Title", selectedTheme.color2)}
                {this.getColorRow("color3Title", selectedTheme.color3)}
                {this.getColorRow("color4Title", selectedTheme.color4)}
                <div className={classes.detailsRow}>
                  {!selectedTheme.isDefault ? (
                    <IconButton
                      onClick={this.handleSetDefaultTheme}
                      className={classes.iconButton}
                      title={intl.formatMessage(getIntl("setAsDefaultTheme"))}
                    >
                      <SvgIcon iconName="setAsDefault" color="active" />
                    </IconButton>
                  ) : undefined}
                  {selectedTheme.id !== 1 && selectedTheme.id !== 2 ? (
                    <IconButton
                      onClick={this.handleEditTheme}
                      className={classes.iconButton}
                      title={intl.formatMessage(getIntl("editTheme"))}
                    >
                      <SvgIcon iconName="edit" color="active" />
                    </IconButton>
                  ) : undefined}
                </div>
              </div>
              <div className={classes.paletteContainer}>
                <MainPageDummy
                  color1={selectedTheme.color1}
                  color2={selectedTheme.color2}
                  color3={selectedTheme.color3}
                  color4={selectedTheme.color4}
                  headerLogo={selectedTheme.headerLogo}
                  bodyLogo={selectedTheme.bodyLogo}
                  backgroundImage={selectedTheme.backgroundImage}
                  imagesLocation={selectedTheme.imagesLocation}
                  rightBarWithButtons
                />
              </div>
            </div>
          ) : undefined}
        </div>
        {isThemeEditorModalOpen && this.getThemeEditorModal()}
      </div>
    );
  }
}

const mapStateToProps = ({ portal, session, languages }) => ({
  portal,
  session,
  languages
});

const mapDispatchToProps = dispatch => ({
  getThemes: sessionId => dispatch(getThemes(sessionId)),
  updateDefaultTheme: (sessionId, themeId, value) =>
    dispatch(updateDefaultTheme(sessionId, themeId, value)),
  setThemeEditorModalState: isOpen =>
    dispatch(setThemeEditorModalState(isOpen)),
  setPortalSelectedThemeId: themeId =>
    dispatch(setPortalSelectedThemeId(themeId)),
  uploadImage: (sessionId, formData) =>
    dispatch(uploadImage(sessionId, formData))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(PortalThemeManager))
  )
);
