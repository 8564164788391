import { WINDOW_RESIZE } from "../actions/types";

export default function(state = { height: 0, width: 0 }, action) {
  let newState = {};
  switch (action.type) {
    case WINDOW_RESIZE:
      newState = {
        ...state,
        height: action.payload.height,
        width: action.payload.width
      };
      return newState;
    default:
      return state;
  }
}
