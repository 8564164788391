import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import classNames from "classnames";
import { getIntl } from "../../utils";
import {
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Typography,
  FormControlLabel
} from "@material-ui/core";
import TextButton from "../reusable/TextButton";
import LocalizedText from "../reusable/LocalizedText";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { updatePersistentSpaceSettingsByUserID } from "./actions";

const styles = theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  rootFullHeight: {
    height: "100%"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.primaryTextColor}`
  },
  rootBackgroundColor: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  cardHeader: {
    padding: "16px 40px 5px 40px"
  },
  cardHeaderTitle: {
    height: "35px"
  },
  cardContent: {
    padding: "0px 40px 5px 40px",
    flexGrow: 1
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  settingsContainer: {
    padding: "10px 50px 0px 30px"
  },
  settingsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "75%",
    height: "48px"
  },
  actionButton: {
    paddingRight: "10px"
  },
  checkBox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.primaryMainTextColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  }
});

class PersistentSpaceSettingsCard extends Component {
  state = {
    userID: null,
    persistentSpaceSettings: {
      persistentSpaceName: "",
      callMeConfig: false,
      webRTCCallConfig: false,
      vettingConfig: false,
      videoConfig: false
    }
  };

  constructor(props) {
    super(props);

    const { persistentSpace } = this.props;
    this.state = {
      userID: this.props.userID,
      persistentSpaceSettings: {
        persistentSpaceName: persistentSpace.persistentSpaceName,
        callMeConfig: persistentSpace.callMeConfig,
        webRTCCallConfig: persistentSpace.webRTCCallConfig,
        videoConfig: persistentSpace.videoConfig,
        vettingConfig: persistentSpace.vettingConfig
      }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userID != null) {
      if (
        prevProps.userID !== this.props.userID ||
        prevProps.persistentSpace.persistentSpaceName !==
          this.props.persistentSpace.persistentSpaceName
      ) {
        const { persistentSpace } = this.props;

        this.setState({
          userID: this.props.userID,
          persistentSpaceSettings: {
            persistentSpaceName: persistentSpace.persistentSpaceName,
            callMeConfig: persistentSpace.callMeConfig,
            webRTCCallConfig: persistentSpace.webRTCCallConfig,
            videoConfig: persistentSpace.videoConfig,
            vettingConfig: persistentSpace.vettingConfig
          }
        });
      }
    }
  }

  getCardHeaderControl = () => {
    const { classes, intl } = this.props;
    const persistentSpaceName =
      this.state.persistentSpaceSettings.persistentSpaceName;

    return (
      <CardHeader
        title={
          persistentSpaceName
            ? persistentSpaceName
            : intl.formatMessage(getIntl("na"))
        }
        titleTypographyProps={{ color: "inherit", variant: "h5" }}
        classes={{
          root: classes.cardHeader,
          title: classes.cardHeaderTitle,
          action: classes.actionButton
        }}
        action={
          <TextButton
            disabled={this.props.buttonLoader}
            onClick={this.handleSaveButtonOnClick}
          >
            <LocalizedText value="save" />
          </TextButton>
        }
      />
    );
  };

  getCardContentControl = () => {
    const { classes } = this.props;

    return (
      <CardContent
        classes={{
          root: classes.cardContent
        }}
      >
        <div className={classes.hrDivPrimary} />
        {this.getPersistentSpaceSettings()}
      </CardContent>
    );
  };

  getPersistentSpaceSettings = () => {
    const { classes } = this.props;
    let settingsArray = [];

    for (let [settingName, settingValue] of Object.entries(
      this.state.persistentSpaceSettings
    )) {
      if (window.CtxAppConfigurations.customer !== "TCAD") {
        if (
          settingName === "callMeConfig" ||
          settingName === "webRTCCallConfig" ||
          (settingName === "videoConfig" &&
            window.CtxAppConfigurations.videoLicenseActive)
        ) {
          settingsArray.push(this.getSettingsRow(settingName, settingValue));
        }
      }

      if (
        settingName === "vettingConfig" &&
        window.CtxAppConfigurations.vettingEnabled
      ) {
        settingsArray.push(this.getSettingsRow(settingName, settingValue));
      }
    }

    return <div className={classes.settingsContainer}>{settingsArray}</div>;
  };

  getSettingsRow = (settingName, settingValue) => {
    const { classes, intl } = this.props;
    return (
      <div className={classes.settingsRow} key={settingName}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkBox}
              checked={settingValue}
              id={settingName}
              onChange={eventObj => {
                this.handleSettingChange(eventObj);
              }}
              color="primary"
              icon={
                <CheckBoxOutlineBlankIcon
                  viewBox="3 3 18 18"
                  className={classes.checkboxOutlineBlankIcon}
                />
              }
              checkedIcon={
                <CheckBoxIcon
                  viewBox="3 3 18 18"
                  className={classes.checkboxIcon}
                />
              }
            />
          }
        />
        <Typography variant="body1" color="inherit">
          {intl.formatMessage(getIntl(settingName))}
        </Typography>
      </div>
    );
  };

  handleSettingChange = event => {
    let settingName = event.target.id;
    let settingValue = event.target.checked;

    this.setState(prevState => ({
      ...prevState,
      persistentSpaceSettings: {
        ...prevState.persistentSpaceSettings,
        [settingName]: settingValue
      }
    }));
  };

  handleSaveButtonOnClick = () => {
    const { session } = this.props;
    const { userID, persistentSpaceSettings } = this.state;

    this.props.updatePersistentSpaceSettingsByUserID(
      session.portalSessionId,
      userID,
      persistentSpaceSettings.persistentSpaceName,
      persistentSpaceSettings.callMeConfig,
      persistentSpaceSettings.webRTCCallConfig,
      persistentSpaceSettings.vettingConfig,
      persistentSpaceSettings.videoConfig
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={classNames(
          classes.root,
          this.props.fullHeight ? classes.rootFullHeight : undefined,
          this.props.backgroundColor ? undefined : classes.rootBackgroundColor
        )}
        style={{
          backgroundColor: this.props.backgroundColor
            ? this.props.backgroundColor
            : undefined
        }}
      >
        {this.getCardHeaderControl()}
        {this.getCardContentControl()}
      </Card>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  updatePersistentSpaceSettingsByUserID: (
    portalSessionId,
    userID,
    name,
    callMeConfig,
    webRTCCallConfig,
    vettingConfig,
    videoConfig
  ) =>
    dispatch(
      updatePersistentSpaceSettingsByUserID(
        portalSessionId,
        userID,
        name,
        callMeConfig,
        webRTCCallConfig,
        vettingConfig,
        videoConfig
      )
    )
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(PersistentSpaceSettingsCard)
    )
  )
);
