import {
  CONFERENCE_UPDATE,
  USER_LOGOUT,
  WEBSOCKET_RECONNECT
} from "../actions/types";

export default function(state = { active: false }, action) {
  let newState;
  switch (action.type) {
    case CONFERENCE_UPDATE:
      if (action.payload.active === false) {
        newState = { active: false };
      } else {
        newState = { ...state, ...action.payload };
      }
      console.log("new conference: ", newState);
      return newState;
    case WEBSOCKET_RECONNECT:
    case USER_LOGOUT:
      newState = { active: false };
      return newState;
    default:
      return state;
  }
}
