import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme } from "@material-ui/core/styles";
import { hideSettingsMenu } from "../../actions";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import DeviceSettings from "./DeviceSettings";
import VideoDeviceSettings from "./VideoDeviceSettings";
import { InternalDrawerState } from "../layouts/internalDrawerStates";
import { isWebRTCAudioEnabled, isVideoEnabled } from "../../utils";
import LocalizedText from "../reusable/LocalizedText";

const styles = theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  headerText: {
    textAlign: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  container: {
    display: "flex",
    zIndex: 11,
    width: "auto",
    height: "auto",
    alignSelf: "center",
    position: "absolute"
  },
  bottomDiv: {
    height: "35px"
  },
  layout: {},
  drawerHeader: {},
  closeHeaderIcon: {},
  closeHeaderButton: {},
  mainContent: {}
});

class SettingsMenu extends Component {
  overrideHandleClose = () => {
    this.props.hideSettingsMenu();
  };

  render() {
    const { classes, internalDrawer, session } = this.props;
    const settingsInDrawer = internalDrawer === InternalDrawerState.SETTINGS;

    const component = (
      <Fragment>
        <div className={classes.header}>
          <LocalizedText
            value="settings"
            variant="h6"
            className={classes.headerText}
          />
          <div className={classes.hrDiv} />
        </div>
        {isWebRTCAudioEnabled(session.webRTCCallConfig) && <DeviceSettings />}
        {isVideoEnabled(session.videoConfig) && <VideoDeviceSettings />}
        <div className={classes.bottomDiv} />
      </Fragment>
    );

    return (
      <div id="settingMenu" className={classes.container}>
        <CTXBaseWindow
          component={component}
          overrideHandleClose={this.overrideHandleClose}
          disableClickAwayClose={true}
          disableContainerStyle
          hideHeader={settingsInDrawer}
          className={settingsInDrawer ? classes.layout : undefined}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ internalDrawer, session }) => ({
  internalDrawer,
  session
});

const mapDispatchToProps = dispatch => ({
  hideSettingsMenu: () => dispatch(hideSettingsMenu())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsMenu))
  )
);
