//-----------Action types----------//

export const CHAT_POPOUT_SHOW = "showChatPopout";
export const CHAT_POPOUT_HIDE = "hideChatPopout";

//---------Action creators-------------//

export const showChatPopout = windowName => {
  return {
    type: CHAT_POPOUT_SHOW,
    payload: windowName
  };
};

export const hideChatPopout = () => {
  return {
    type: CHAT_POPOUT_HIDE
  };
};
