import React, { Component } from "react";
import ReactPlayer from "react-player";
import LocalizedText from "../reusable/LocalizedText";
import classNames from "classnames";
import { withStyles, withTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getIntl } from "../../utils";
import {
  getPortalConferenceRecordings,
  setPortalRecordingDateFrom,
  setPortalRecordingDateTo,
  resetPortalRecordings
} from "../../actions";
import { IconButton } from "@material-ui/core";
import PortalHeader from "./PortalHeader";
import CTXTable from "../reusable/CTXTable";
import SvgIcon from "../Icons/SvgIcon";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  header: {
    minWidth: "1160px",
    height: "50px",
    flexDirection: "row",
    margin: "10px 20px 10px 20px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  hrDiv: {
    borderTop: `4px solid ${theme.colors.primaryTextColor}`
  },
  mainContentContainer: {
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    margin: "10px 15px 10px 15px"
  },
  mainContentTopContainer: {
    width: "100%",
    paddingRight: "10px"
  },
  mainContentMidContainer: {
    width: "100%",
    minWidth: "1000px",
    marginBottom: "10px"
  },
  mainContentBottomContainer: {
    width: "100%",
    minWidth: "1000px",
    paddingRight: "10px",
    paddingBottom: "10px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.colors.popoverBackgroundColor,
    backgroundClip: "content-box"
  },
  detailsContainer: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: "40%",
    display: "flex",
    flexDirection: "column"
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "15px 10px 15px 70px",
    fontSize: "1.5em"
  },
  detailsHeading: {
    padding: "30px 10px 20px 40px"
  },
  playerContainer: {
    width: "60%",
    margin: "20px 20px 20px 10px",
    border: `5px solid ${theme.colors.secondaryMainColor}`
  },
  iconButton: {
    width: "64px"
  }
});

class PortalConferenceRecordings extends Component {
  constructor(props) {
    super(props);
    this.moment = require("moment-timezone");
    this.timezone = this.moment.tz.guess();
  }

  state = {
    url: null,
    playing: false,
    conferenceName: null,
    startTime: null,
    recordingDate: null,
    duration: 0,
    format: "MP4",
    size: 0,
    guid: null
  };

  componentDidMount() {
    let currentDateTime = new Date();
    currentDateTime.setHours(0);
    currentDateTime.setMinutes(0);
    currentDateTime.setSeconds(0);

    let previousDateTime = new Date(
      currentDateTime.getTime() - 30 * 24 * 60 * 60 * 1000
    );

    let currentUTCString = this.getMySqlUTCDateTimeFormat(currentDateTime);
    let previousUTCString = this.getMySqlUTCDateTimeFormat(previousDateTime);

    this.props.setPortalRecordingDateFrom(previousUTCString);
    this.props.setPortalRecordingDateTo(currentUTCString);

    let currentUTCQueryString = this.getMySqlUTCDateTimeFormat(
      new Date(currentDateTime.getTime() + 24 * 60 * 60 * 1000)
    );

    this.props.getPortalConferenceRecordings(
      this.props.session.portalSessionId,
      previousUTCString,
      currentUTCQueryString
    );
  }

  componentWillUnmount() {
    this.props.resetPortalRecordings();
    this.props.setPortalRecordingDateFrom(undefined);
    this.props.setPortalRecordingDateTo(undefined);
  }

  getMySqlUTCDateTimeFormat = date => {
    return (
      date.getUTCFullYear().toString() +
      "-" +
      (date.getUTCMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getUTCDate().toString().padStart(2, "0") +
      " " +
      date.getUTCHours().toString().padStart(2, "0") +
      ":" +
      date.getUTCMinutes().toString().padStart(2, "0") +
      ":" +
      date.getUTCSeconds().toString().padStart(2, "0")
    );
  };

  formatBytes = (bytes, decimals) => {
    if (bytes === 0) return "0 Bytes";
    var k = 1024;
    var dm = decimals <= 0 ? 0 : decimals || 2;
    var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  convertDuration = seconds => {
    //output hh:mm:ss
    let resHH = Math.floor(seconds / 60 / 60);
    let resMM = Math.floor((seconds - 3600 * resHH) / 60);
    let resSS = (seconds - 3600 * resHH - 60 * resMM).toFixed(0);
    return (
      resHH.toString().padStart(2, "0") +
      ":" +
      resMM.toString().padStart(2, "0") +
      ":" +
      resSS.toString().padStart(2, "0")
    );
  };

  sortStartTime = (object1, object2) => {
    let splitStartTime1 = object1.recordingStartTime.split(" ");
    let splitStartTime2 = object2.recordingStartTime.split(" ");
    let AMPM1 = splitStartTime1[1];
    let AMPM2 = splitStartTime2[1];
    let time1 = splitStartTime1[0].split(":");
    let time2 = splitStartTime2[0].split(":");

    if (AMPM1 !== AMPM2) {
      return AMPM1 === "AM" ? -1 : 1;
    } else {
      if (parseInt(time1[0]) > parseInt(time2[0])) {
        return 1;
      } else if (parseInt(time1[0]) < parseInt(time2[0])) {
        return -1;
      } else {
        if (parseInt(time1[1]) > parseInt(time2[1])) {
          return 1;
        } else if (parseInt(time1[1]) < parseInt(time2[1])) {
          return -1;
        } else {
          if (parseInt(time1[2]) > parseInt(time2[2])) {
            return 1;
          } else if (parseInt(time1[2]) < parseInt(time2[2])) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    }
  };

  sortFileSize = (object1, object2) => {
    let splitFileSize1 = object1.fileSize.split(" ");
    let splitFileSize2 = object2.fileSize.split(" ");
    let unit1 = splitFileSize1[1];
    let unit2 = splitFileSize2[1];
    let num1 = splitFileSize1[0].split(":");
    let num2 = splitFileSize2[0].split(":");

    let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    let unit1Index = 0;
    let unit2Index = 0;

    for (; unit1Index < sizes.length; unit1Index++) {
      if (sizes[unit1Index] === unit1) {
        break;
      }
    }

    for (; unit2Index < sizes.length; unit2Index++) {
      if (sizes[unit2Index] === unit2) {
        break;
      }
    }

    if (unit1Index < unit2Index) {
      return -1;
    } else if (unit1Index > unit2Index) {
      return 1;
    } else {
      return parseFloat(num1) - parseFloat(num2);
    }
  };

  getTableData = (recordingData, isAdmin) => {
    var getTableDataResult = [];

    if (recordingData === undefined) {
      return getTableDataResult;
    }

    for (var key in recordingData) {
      let tableRowData = {};
      //Key
      tableRowData.recordingKey = recordingData[key].recordingKey;
      //Billing Code
      if (isAdmin) {
        tableRowData.billingCode = recordingData[key].billingCode;
      }
      //Convert UTC Conf datetime to local datetime
      let utcConfStartDateTime = new Date(
        recordingData[key].startTime.replace(" ", "T") + ".000+00:00"
      );
      //Title
      tableRowData.title =
        recordingData[key].conferenceName +
        "_" +
        utcConfStartDateTime.toLocaleString();

      //Convert UTC Recording datetime to local datetime
      let utcRecordingStartDateTime = new Date(
        recordingData[key].recordingStartTime.replace(" ", "T") + ".000+00:00"
      );

      let localStartDateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      };
      let localRecordingtartDate = utcRecordingStartDateTime.toLocaleDateString(
        undefined,
        localStartDateOptions
      );
      let localStartTimeOptions = {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
      };
      let localRecordingStartTime =
        utcRecordingStartDateTime.toLocaleTimeString(
          undefined,
          localStartTimeOptions
        );

      //Recording Date
      tableRowData.recordingDate = localRecordingtartDate;
      //Recording StartTime
      tableRowData.recordingStartTime = localRecordingStartTime;

      //FileSize
      tableRowData.fileSize = this.formatBytes(recordingData[key].fileSize);

      //File Location
      tableRowData.fileLocation =
        window.CtxAppConfigurations.recordingUri +
        recordingData[key].guid +
        ".mp4";

      //guid
      tableRowData.guid = recordingData[key].guid;

      getTableDataResult.push(tableRowData);
    }

    return getTableDataResult;
  };

  handleOnRowClick = (event, rowData) => {
    this.setState({
      playing: true,
      url: rowData.fileLocation,
      recordingDate: rowData.recordingDate,
      conferenceName: rowData.title,
      size: rowData.fileSize,
      guid: rowData.guid
    });
  };

  ref = player => {
    this.player = player;
  };

  handleDuration = duration => {
    this.setState({ duration });
  };

  handleRecordingDownload = () => {
    const { conferenceName, url } = this.state;
    var downloadLink = document.createElement("a");
    downloadLink.download = conferenceName + ".mp4";
    downloadLink.href = url;
    downloadLink.click();
  };

  handleRecordingDatePickerFromChange = date => {
    if (date == null) {
      return;
    }
    let localDateTime = date;
    let utcDateTime = this.getMySqlUTCDateTimeFormat(localDateTime);
    this.props.setPortalRecordingDateFrom(utcDateTime);
  };

  handleRecordingDatePickerToChange = date => {
    if (date == null) {
      return;
    }
    let localDateTime = date;
    let utcDateTime = this.getMySqlUTCDateTimeFormat(localDateTime);
    this.props.setPortalRecordingDateTo(utcDateTime);
  };

  handleRefreshButtonClick = () => {
    this.props.resetPortalRecordings();

    let recordingToDate = new Date(
      this.props.portal.recordingDateTo.split(" ")[0] +
        "T" +
        this.props.portal.recordingDateTo.split(" ")[1] +
        ".000+00:00"
    );

    let queryToString = this.getMySqlUTCDateTimeFormat(
      new Date(recordingToDate.getTime() + 1000 * 60 * 60 * 24)
    );

    this.props.getPortalConferenceRecordings(
      this.props.session.portalSessionId,
      this.props.portal.recordingDateFrom,
      queryToString
    );
  };

  render() {
    const { classes, intl, session } = this.props;
    const { translations } = this.props.languages;
    const { conferenceName, recordingDate, duration, size, format } =
      this.state;
    const startTimeLabel =
      translations.recordingStart +
      " ( " +
      this.moment().tz(this.timezone).format("z") +
      " )";

    const tableTitle = translations.recordedMeetings;
    const tableColumns = [
      { title: "Key", field: "recordingKey", hidden: true },
      {
        title: translations.billingRecordingCode,
        field: "billingCode",
        hidden: !session.portalUser.admin
      },
      { title: translations.title, field: "title" },
      { title: translations.date, field: "recordingDate", type: "date" },
      {
        title: startTimeLabel,
        field: "recordingStartTime",
        type: "time",
        customSort: this.sortStartTime
      },
      {
        title: translations.fileSize,
        field: "fileSize",
        customSort: this.sortFileSize
      },
      { title: translations.fileLocation, field: "fileLocation", hidden: true },
      { title: "Guid", field: "guid", hidden: true }
    ];

    let tableData = this.getTableData(
      this.props.portal.conferenceRecordings,
      session.portalUser.admin
    );

    let recordingFromDate = this.props.portal.recordingDateFrom
      ? new Date(
          this.props.portal.recordingDateFrom.replace(" ", "T") + ".000+00:00"
        )
      : undefined;
    let recordingToDate = this.props.portal.recordingDateTo
      ? new Date(
          this.props.portal.recordingDateTo.replace(" ", "T") + ".000+00:00"
        )
      : undefined;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <PortalHeader
            title={
              session.portalUser && session.portalUser.admin
                ? "allRecordings"
                : "myRecordings"
            }
          />
        </div>
        <div
          className={classNames(
            classes.mainContentContainer,
            classes.scrollBar
          )}
        >
          <div className={classes.mainContentTopContainer} />
          <div className={classes.mainContentMidContainer}>
            <CTXTable
              title={tableTitle}
              columns={tableColumns}
              data={tableData}
              onRowClick={this.handleOnRowClick}
              CTXTableDatePickFromValue={recordingFromDate}
              CTXTableDatePickToValue={recordingToDate}
              onChangeDatePickerFrom={this.handleRecordingDatePickerFromChange}
              onChangeDatePickerTo={this.handleRecordingDatePickerToChange}
              handleRefreshButtonClick={this.handleRefreshButtonClick}
              displayDatePickers
              enableSelectedRowStyle
            />
          </div>
          {this.state.url && (
            <div className={classes.mainContentBottomContainer}>
              <div className={classes.detailsContainer}>
                <Typography
                  variant="h6"
                  className={classes.detailsHeading}
                  color="inherit"
                >
                  {conferenceName}
                </Typography>
                <div className={classes.hrDiv} />
                <div className={classes.detailsRow}>
                  <LocalizedText value="date" variant="subtitle2" />
                  <Typography variant="subtitle2" color="inherit">
                    {": " + recordingDate}
                  </Typography>
                </div>
                <div className={classes.detailsRow}>
                  <LocalizedText value="duration" variant="subtitle2" />
                  <Typography variant="subtitle2" color="inherit">
                    {": " + this.convertDuration(duration)}
                  </Typography>
                </div>
                <div className={classes.detailsRow}>
                  <LocalizedText value="size" variant="subtitle2" />
                  <Typography variant="subtitle2" color="inherit">
                    {": " + size}
                  </Typography>
                </div>
                <div className={classes.detailsRow}>
                  <LocalizedText value="format" variant="subtitle2" />
                  <Typography variant="subtitle2" color="inherit">
                    {": " + format}
                  </Typography>
                </div>
                <div className={classes.detailsRow}>
                  <IconButton
                    onClick={this.handleRecordingDownload}
                    className={classes.iconButton}
                    title={intl.formatMessage(getIntl("downloadRecording"))}
                  >
                    <SvgIcon iconName="download" color="active" />
                  </IconButton>
                  <IconButton
                    onClick={this.handleRecordingDownload}
                    className={classes.iconButton}
                    title={intl.formatMessage(getIntl("shareRecording"))}
                    disabled={true}
                  >
                    <SvgIcon iconName="shareToFriend" color="inactive" />
                  </IconButton>
                </div>
              </div>
              <div className={classes.playerContainer}>
                <ReactPlayer
                  width="100%"
                  height="100%"
                  controls={true}
                  ref={this.ref}
                  url={this.state.url}
                  playing={this.state.playing}
                  onDuration={this.handleDuration}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ portal, session, languages }) => ({
  portal,
  session,
  languages
});

const mapDispatchToProps = dispatch => ({
  getPortalConferenceRecordings: (sessionId, startDateTime, endDateTime) =>
    dispatch(
      getPortalConferenceRecordings(sessionId, startDateTime, endDateTime)
    ),
  setPortalRecordingDateFrom: date =>
    dispatch(setPortalRecordingDateFrom(date)),
  setPortalRecordingDateTo: date => dispatch(setPortalRecordingDateTo(date)),
  resetPortalRecordings: () => dispatch(resetPortalRecordings())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps)(PortalConferenceRecordings)
    )
  )
);
