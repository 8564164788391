import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import { withTheme } from "@material-ui/core/styles";
import LocalizedText from "../reusable/LocalizedText";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import { getTextColor } from "../../style";
import { hideInviteParticipant, showInviteByPhone } from "./";
import Link from "@material-ui/core/Link";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  showNotificationWindow,
  setNotificationLevel,
  NotificationLevel,
  setNotificationType,
  NotificationType
} from "../notification";
import { isAudioConnected } from "../../utils";

/***
 * If InviteParticipantWindowState.OPEN  ---> InviteParticipantPopper is shown
 * If InviteParticipantWindowState.CLOSE ----> InviteParticipantPopper is closed
 *
 * Stateful component that sets InviteParticipantWindowState.CLOSE in the redux store.
 * When using this, it's upto the implementor to set InviteParticipantWindowState.OPEN in the store.
 * This component reads from the redux store to decide if the popper should be shown. *
 */

// InviteParticipantWindowState is exported for the reducer to set OPEN and CLOSE states.
export const InviteParticipantWindowState = {
  CLOSE: 1,
  OPEN: 2
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  headerText: {
    float: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  mainContent: {
    display: "flex",
    flexDirection: "column"
  },
  mainContentText: {
    float: "left"
  },
  iconButton: {
    width: "64px"
  },
  item: {
    width: "6em",
    textAlign: "center",
    padding: "0px 2px 0px 2px"
  },
  iconButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    marginTop: "5px"
  }
});

class InvitePopperWindow extends Component {
  handleInviteByEmail = () => {
    this.props.hideInviteParticipant();
  };

  handleInviteByCopyLink = (text, result) => {
    //Copy URL message to clipboard
    //Hide Invite Participant window
    this.props.hideInviteParticipant();
    //Feedback to user
    if (result === true) {
      this.props.setNotificationLevel(NotificationLevel.SUCCESS);
      this.props.setNotificationType(NotificationType.COPY_LINK);
    } else {
      this.props.setNotificationLevel(NotificationLevel.ERROR);
      this.props.setNotificationType(NotificationType.COPY_LINK);
    }
    this.props.showNotificationWindow();
  };

  handleInviteByPhone = () => {
    this.props.hideInviteParticipant();
    this.props.showInviteByPhone();
  };

  composeEmail = text => {
    let res = text;
    const session = this.props.session;
    const conference = this.props.conference;
    const translations = this.props.languages.translations;
    const { persistentSpaceName } = session;

    //UserName
    let userName = session.username === undefined ? "Host" : session.username;
    res = res.replace(/%UserName%/g, userName === undefined ? "" : userName);
    //WebSocketURI
    let webSocketURI = window.CtxAppConfigurations.webSocketUri;
    res = res.replace(
      /%WebSocketURI%/g,
      webSocketURI === undefined ? "" : webSocketURI
    );
    //PersistentSpaceIdentifier
    //TODO: replace guest code with persistent space identifier
    let persistentSpaceIdentifier = persistentSpaceName
      ? persistentSpaceName
      : "?passcode=" + conference.guestCode;
    res = res.replace(
      /%PersistentSpaceIdentifier%/g,
      persistentSpaceIdentifier === undefined ? "" : persistentSpaceIdentifier
    );
    //GuestPasscode
    let guestPasscode = conference.guestCode;
    res = res.replace(
      /%GuestPasscode%/g,
      guestPasscode === undefined ? "" : guestPasscode
    );
    //DialInNumber
    let dialInNumber = translations.phoneNumber;
    res = res.replace(
      /%DialInNumber%/g,
      dialInNumber === undefined ? "" : dialInNumber
    );
    //ProviderCode
    //TODO: get correct provider code
    let providerCode = "CTXHD";
    res = res.replace(
      /%ProviderCode%/g,
      providerCode === undefined ? "" : providerCode
    );
    //

    return res;
  };

  getInviteByEmailControl = () => {
    const { classes, theme } = this.props;

    let controlElement = null;
    let disabled = false;
    let mailTo =
      "mailto:?subject=" +
      (window.CtxAppConfigurations.inviteEmailSubject !== undefined
        ? window.CtxAppConfigurations.inviteEmailSubject
        : "Join my ConnectNow conference") +
      "&body=" +
      (window.CtxAppConfigurations.inviteEmailBody !== undefined
        ? this.composeEmail(window.CtxAppConfigurations.inviteEmailBody)
        : "");

    controlElement = (
      <div className={classes.item}>
        <Link href={mailTo} target="_blank" rel="noopener">
          <IconButton
            className={classes.iconButton}
            onClick={() => this.handleInviteByEmail()}
          >
            <SvgIcon iconName="email" color="active" />
          </IconButton>
        </Link>
        <div>
          <LocalizedText
            value="email"
            variant="subtitle2"
            style={{ color: getTextColor(theme, disabled) }}
          />
        </div>
      </div>
    );

    return controlElement;
  };

  getInviteByPhoneControl = () => {
    const { classes, theme, session } = this.props;

    let controlElement = null;
    let disabled = !isAudioConnected(session);

    controlElement = (
      <div className={classes.item}>
        <IconButton
          className={classes.iconButton}
          onClick={() => this.handleInviteByPhone()}
          disabled={disabled}
        >
          <SvgIcon
            iconName="connectCall"
            color={disabled ? "inactive" : "active"}
          />
        </IconButton>
        <div>
          <LocalizedText
            value="call"
            variant="subtitle2"
            style={{ color: getTextColor(theme, disabled) }}
          />
        </div>
      </div>
    );

    return controlElement;
  };

  getInviteByCopyLinkControl = () => {
    const { classes, theme, session } = this.props;
    const { persistentSpaceName } = session;

    let controlElement = null;
    let disabled = false;
    const copyText =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      persistentSpaceName;

    controlElement = (
      <div className={classes.item}>
        <CopyToClipboard
          text={copyText}
          onCopy={(text, result) => this.handleInviteByCopyLink(text, result)}
          options={{ format: "text/plain" }}
        >
          <IconButton className={classes.iconButton}>
            <SvgIcon iconName="copyLink" color="active" />
          </IconButton>
        </CopyToClipboard>
        <div>
          <LocalizedText
            value="copyLink"
            variant="subtitle2"
            style={{ color: getTextColor(theme, disabled) }}
          />
        </div>
      </div>
    );

    return controlElement;
  };

  overrideHandleClose = () => {
    this.props.hideInviteParticipant();
  };

  render() {
    const { classes } = this.props;

    const component = (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.header}>
            <div>
              <LocalizedText
                value="invite"
                variant="h6"
                className={classes.headerText}
              />
            </div>
            <div className={classes.hrDiv} />
          </div>

          <div className={classes.mainContent}>
            <div>
              <LocalizedText
                value="inviteSelectOption"
                variant="subtitle1"
                className={classes.mainContentText}
              />
            </div>
            <div className={classes.iconButtonsContainer}>
              {this.getInviteByEmailControl()}
              {this.getInviteByPhoneControl()}
              {this.getInviteByCopyLinkControl()}
            </div>
          </div>
        </div>
      </Fragment>
    );

    return (
      <Fragment>
        <CTXBaseWindow
          component={component}
          overrideHandleClose={this.overrideHandleClose}
          //disableClickAwayClose={true}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  session,
  inviteParticipant,
  conference,
  languages
}) => ({
  session,
  inviteParticipant,
  conference,
  languages
});

const mapDispatchToProps = dispatch => ({
  hideInviteParticipant: () => dispatch(hideInviteParticipant()),
  showNotificationWindow: () => dispatch(showNotificationWindow()),
  showInviteByPhone: () => dispatch(showInviteByPhone()),
  setNotificationLevel: level => dispatch(setNotificationLevel(level)),
  setNotificationType: type => dispatch(setNotificationType(type))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(InvitePopperWindow))
  )
);
