import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classnames from "classnames";
import { Appear } from "./transitions";
import Peer from "./Peer";
import Me from "./Me";
import { getBestItemBounds } from "../../../utils";

const styles = theme => ({
  root: {
    display: "inline-grid",
    gridTemplateColumns: "repeat(auto-fit, var(--item-width))",
    gridTemplateRows: "repeat(auto-fit, var(--item-height))",
    justifyContent: "center",
    width: "100%"
  },
  rootAlignCenter: {
    height: "100%",
    alignContent: "safe center"
  },
  rootAlignTop: {
    padding: "2px",
    height: "calc(100%)",
    alignContent: "flex-start"
  },
  videoHeader: {
    height: "calc(100% - 36px)"
  },
  scroll: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} transparent`,
    scrollbarWidth: "thin"
  },
  peerContainer: {
    width: "100%",
    height: "100%",
    borderColor: "inherit",
    borderStyle: "solid"
  },
  activeSpeaker: {
    borderColor: theme.colors.connectIconColor
  }
});

class Peers extends React.Component {
  constructor(props) {
    super(props);
    this.selfRef = React.createRef();
  }

  componentDidMount() {
    this.handleWindowResize();
  }

  componentDidUpdate(prevProps) {
    const { windowWidth, windowHeight, internalDrawer, fullScreenMode, peers } =
      this.props;
    if (
      prevProps.windowWidth !== windowWidth ||
      prevProps.windowHeight !== windowHeight ||
      prevProps.internalDrawer !== internalDrawer ||
      prevProps.fullScreenMode !== fullScreenMode ||
      Object.values(prevProps.peers).length !== Object.values(peers).length
    ) {
      this.handleWindowResize();
    }
  }

  handleWindowResize = () => {
    let maxCols = this.props.isDrawer ? 2 : 20;
    let numPeers = Object.values(this.props.peers).length;
    if (!this.props.recorder) {
      numPeers += 1;
    }
    this.resetContainerItems(numPeers, maxCols);
  };

  // Change the item size via CSS property
  resetContainerItems = (itemCount, maxCols) => {
    if (!itemCount) return;
    const peersContainer = this.selfRef.current;
    if (peersContainer) {
      const rect = peersContainer.getBoundingClientRect();
      // Get best item bounds and apply property
      const { itemWidth, itemHeight } = getBestItemBounds({
        width: rect.width,
        height: rect.height,
        itemCount,
        aspectRatio: 16 / 9,
        maxCols: maxCols
      });
      peersContainer.style.setProperty("--item-width", itemWidth + "px");
      peersContainer.style.setProperty("--item-height", itemHeight + "px");
    }
  };

  render() {
    const { classes, peers, isDrawer, merged, talkers, me, recorder } =
      this.props;

    let amActiveSpeaker = false;
    let activeSpeakers = [];

    if (talkers.size > 0) {
      merged.forEach(mergedUser => {
        if (mergedUser != null && mergedUser.talking)
          activeSpeakers.push(mergedUser.id);
      });

      console.log(activeSpeakers);
      amActiveSpeaker = activeSpeakers.includes(me.id);
    }

    return (
      <div
        ref={this.selfRef}
        data-component="Peers"
        className={classnames(classes.root, classes.scroll, {
          [classes.rootAlignTop]: isDrawer,
          [classes.videoHeader]: isDrawer && !recorder,
          [classes.rootAlignCenter]: !isDrawer
        })}
      >
        {!recorder && (
          <div
            className={classnames(classes.peerContainer, {
              [classes.activeSpeaker]: amActiveSpeaker
            })}
          >
            <Me isDrawer={isDrawer} />
          </div>
        )}
        {Object.values(peers).map(peer => {
          return (
            <Appear key={peer.id} duration={1000}>
              <div
                className={classnames(classes.peerContainer, {
                  [classes.activeSpeaker]:
                    activeSpeakers.length > 0 &&
                    activeSpeakers.includes(parseInt(peer.id, 10))
                })}
              >
                <Peer id={peer.id} isDrawer={isDrawer} />
              </div>
            </Appear>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    me: state.me,
    peers: state.peers,
    merged: state.participants.merged,
    talkers: state.session.talkerPartySet,
    internalDrawer: state.internalDrawer,
    fullScreenMode: state.session.fullScreenMode,
    recorder: state.session.recorder
  };
};

export default withStyles(styles)(connect(mapStateToProps)(Peers));
