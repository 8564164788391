import "core-js"; // Used by Babel for polyfills

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import "typeface-roboto";
import App from "./components/App";
import reducers from "./reducers";
import RoomContext from "./components/mediasoup/RoomContext";
import RoomClientProvider from "./components/mediasoup/RoomClientProvider";

//locale stuff
const browserLanguage = navigator.language.split("-")[0]; // language without region code

//redux-persist stuff
const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["internalDrawer", "popper", "unreadChats", "userPriority"]
};
const persistedReducer = persistReducer(persistConfig, reducers);

//redux stuff
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const persistor = persistStore(store);

const roomClientProvider = new RoomClientProvider(store);

ReactDOM.render(
  <Provider store={store}>
    <RoomContext.Provider value={roomClientProvider}>
      <PersistGate loading={null} persistor={persistor}>
        <App
          webSocketUri={window.CtxAppConfigurations.webSocketUri}
          browserLanguage={browserLanguage}
        />
      </PersistGate>
    </RoomContext.Provider>
  </Provider>,
  document.querySelector("#root")
);
