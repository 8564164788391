import React, { Component, Fragment } from "react";
import TextButton from "../reusable/TextButton";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import LocalizedText from "./../reusable/LocalizedText";
import contexWebRest from "../../api/contexWebRest";
import { setAudioInput, setAudioOutput, setAudioOff } from "../../actions";
import {
  createDataConfIDParty,
  updateWebRTCCallState,
  showSettingsMenu,
  setShakeConferenceSecureIcon,
  notifyServerUsingWebRTCCall
} from "./../../actions";
import { isHost, isWebRTCAudioEnabled, getDevices } from "../../utils";
import JsSIP from "jssip";
import Logger from "../../Logger";

const logger = new Logger("ConnectWithDevice");

const styles = {
  root: {
    alignSelf: "flex-start",
    marginBottom: "10px"
  }
};

class ConnectWithDevice extends Component {
  componentDidMount() {
    console.log("ConnectWithDevice mount");
    window.addEventListener("unload", this.handleWindowUnload);

    const { session } = this.props;
    if (!session.dataConfID) {
      if (session.confActive && session.username) {
        const party = {
          name: session.username,
          userDefined: session.userDefined,
          userDefined2: session.userDefined2,
          userDefined3: session.userDefined3,
          userDefined4: session.userDefined4
        };

        if (
          isWebRTCAudioEnabled(session.webRTCCallConfig) &&
          session.audioEnabled
        ) {
          this.props.setAudioOff();
          this.props
            .createDataConfIDParty(party, session.userId)
            .then(() => {
              this.makeWebRTCCall();
            })
            .catch(() => {
              logger.error("Cannot connect WebRTC call");
            });
        } else {
          this.props.createDataConfIDParty(party, session.userId);
        }
      } else if (!session.confActive) {
        this.activateConf(session.userId);
      }
    }
  }

  handleWindowUnload = () => {
    if (window.webRTCSession) {
      window.webRTCSession.terminate();
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("ConnectWithDevice update");
    const { session } = this.props;
    if (
      session.confActive &&
      session.username &&
      (!prevProps.session.confActive || !prevProps.session.username)
    ) {
      //conference has been activated while component has been mounted so we can proceed to generate confId
      const party = {
        name: session.username,
        userDefined: session.userDefined,
        userDefined2: session.userDefined2,
        userDefined3: session.userDefined3,
        userDefined4: session.userDefined4
      };
      if (
        isWebRTCAudioEnabled(session.webRTCCallConfig) &&
        session.audioEnabled
      ) {
        this.props.setAudioOff();
        this.props
          .createDataConfIDParty(party, session.userId)
          .then(() => {
            this.makeWebRTCCall();
          })
          .catch(() => {
            logger.error("Cannot connect WebRTC call");
          });
      } else {
        this.props.createDataConfIDParty(party, session.userId);
      }
    }
  }

  activateConf = userId => {
    const uri = `/conference/activate/${userId}`;
    contexWebRest.post(uri).catch(function (error) {
      console.log(error);
    });
  };

  handleAudioTestClick = () => {
    this.props.showSettingsMenu();
    this.props.handleClickAway();
  };

  restoreShakeConferenceIcon = () => {
    this.props.setShakeConferenceSecureIcon(false);
  };

  makeWebRTCCall = async () => {
    const { session, conference } = this.props;

    //Stop calling if conference is secured
    if (conference.secure) {
      this.props.setShakeConferenceSecureIcon(true);
      setTimeout(this.restoreShakeConferenceIcon, 600);
      return;
    }

    console.log("In makeWebRTCCall with audio constraints: ");
    const passcode = isHost(session)
      ? conference.hostCode
      : conference.guestCode;
    console.log(
      "In makeWebRTCCall with passcode and dataConfID as: " +
        passcode +
        ":" +
        session.dataConfID
    );

    let webRTCConfig = window.CtxWebRTCConfigurations;

    var socket = new JsSIP.WebSocketInterface(
      webRTCConfig.webRTCWebSocketAddress
    );

    // The audio output will permanently stop working if the currently selected
    // audio output device is removed when the call is not active. The
    // workaround for this issue is to recreate the audio tag.
    var prevWebRTCMedia = document.getElementById("webRTCMedia");
    var webRTCMedia = document.createElement("audio");
    webRTCMedia.id = "webRTCMedia";
    document.querySelector("body").replaceChild(webRTCMedia, prevWebRTCMedia);

    this.props.updateWebRTCCallState("connecting");

    try {
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: this.props.session.audioInput
        }
      });
      stream.getTracks().forEach(track => {
        track.stop();
      });
    } catch (error) {
      this.errorCallback(error);
    }

    try {
      await getDevices(this.props);
    } catch (error) {
      this.errorCallback(error);
    }

    var userAgent = new JsSIP.UA({
      sockets: socket,
      uri: "connectnow@connectnow.invalid",
      register: false,
      session_timers_force_refresher: true
    });
    userAgent.start();

    var options;

    if (session.recorder) {
      options = {
        mediaConstraints: { audio: false },
        rtcOfferConstraints: {
          offerToReceiveAudio: true
        }
      };
    } else {
      options = {
        mediaConstraints: {
          audio: {
            deviceId: this.props.session.audioInput
          }
        }
      };
    }

    let subjectHeader =
      "<CallTreatment>Conference</CallTreatment>" +
      "<passcode>" +
      passcode +
      "</passcode>";
    if (session.pin) {
      subjectHeader += "<pin>" + session.pin + "</pin>";
    }
    subjectHeader +=
      "<DataConfID>" +
      session.dataConfID +
      "</DataConfID>" +
      "<HybridCallMsgOverride>" +
      webRTCConfig.webRTCConnectMessage +
      "</HybridCallMsgOverride>";

    options.extraHeaders = ["Subject: " + subjectHeader];
    options.pcConfig = {
      iceServers: webRTCConfig.iceServers
    };

    // Wait for the WebSocket to connect before initiating the call. Otherwise,
    // the SIP INVITE may be sent before the WebSocket is connected if the TLS
    // TURN candidate is not defined or the ICE gathering timeout is reached
    // before the WebSocket is connected.
    userAgent.on("connected", () => {
      if (window.webRTCSession) {
        // The WebSocket may be reconnecting
        return;
      }

      window.webRTCSession = userAgent.call(
        webRTCConfig.webRTCDestinationAddress,
        options
      );

      window.sendStats = async () => {
        const statistics = [];
        const results = await window.webRTCSession.connection.getStats();
        for (const report of results.values()) {
          if (
            !report.id.startsWith("RTCCertificate") &&
            !report.id.startsWith("RTCCodec")
          ) {
            statistics.push(report);
          }
        }
        const uri = `/users/audio_statistics/${session.userId}/1`;
        try {
          await contexWebRest.post(uri, statistics);
        } catch (error) {
          console.log(error);
        }

        window.sendStatsTimeout = setTimeout(
          window.sendStats,
          webRTCConfig.clientAudioStatisticsPeriod * 1000
        );
      };

      window.webRTCSession.connection.addEventListener("track", e => {
        webRTCMedia.srcObject = e.streams[0];
        // The currently selected audio output device must be set again on the
        // webRTCMedia tag since the stream was changed.
        this.props.setAudioOutput(this.props.session.audioOutput);
        webRTCMedia.play();
      });
      window.webRTCSession.connection.addEventListener(
        "iceconnectionstatechange",
        () => {
          const state = window.webRTCSession.connection.iceConnectionState;
          console.log("iceconnectionstatechange: " + state);

          if (state === "disconnected") {
            window.webRTCAudioTimeout = setTimeout(() => {
              if (window.webRTCSession) {
                window.webRTCSession.terminate();
              }
            }, 10000);
          } else if (state === "connected") {
            clearTimeout(window.webRTCAudioTimeout);
          }
        }
      );

      window.webRTCSession.on("icecandidate", event => {
        // Time out the ICE candidate gathering after the first ICE candidate is
        // received. Otherwise, the ICE candidate gathering may delay the call
        // for at least 40 seconds when a STUN or TURN server is enabled in the
        // following cases:
        // 1) There is another network interface which cannot reach the STUN or
        // TURN server. An example of this would be when using VPN or if
        // virtualization software is enabled.
        // 2) A STUN or TURN candidate cannot be reached. An example of this
        // would be if UDP port 3478 is blocked and only the TURN candidate on
        // port 443 can be reached.
        setTimeout(() => {
          event.ready();
        }, webRTCConfig.iceGatheringTimeout);
      });
      window.webRTCSession.on("connecting", () => {
        this.props.updateWebRTCCallState("connecting");
      });
      window.webRTCSession.on("confirmed", () => {
        if (webRTCConfig.clientAudioStatisticsEnabled) {
          window.sendStatsTimeout = setTimeout(window.sendStats, 2000);
        }
        this.props.updateWebRTCCallState("connected");
        this.props.notifyServerUsingWebRTCCall(session.userId, true); //Notify CONTEXWeb this user starts using WebRTC call
      });
      window.webRTCSession.on("ended", () => {
        clearTimeout(window.sendStatsTimeout);
        clearTimeout(window.webRTCAudioTimeout);
        this.props.updateWebRTCCallState("disconnected");
        userAgent.stop(); // Disconnect the WebSocket
        window.webRTCSession = null;
        this.props.notifyServerUsingWebRTCCall(session.userId, false); //Notify CONTEXWeb this user stops using WebRTC call
      });
      window.webRTCSession.on("failed", () => {
        clearTimeout(window.sendStatsTimeout);
        clearTimeout(window.webRTCAudioTimeout);
        this.props.updateWebRTCCallState("disconnected");
        userAgent.stop(); // Disconnect the WebSocket
        window.webRTCSession = null;
        this.props.notifyServerUsingWebRTCCall(session.userId, false); //Notify CONTEXWeb this user stops using WebRTC call
      });
    });

    userAgent.on("disconnected", () => {
      if (window.webRTCSession) {
        // The WebSocket may be reconnecting
        return;
      }

      this.props.updateWebRTCCallState("disconnected");
      userAgent.stop(); // Disconnect the WebSocket
    });
  };

  errorCallback = error => {
    logger.error("Error: %o", error);
    this.props.updateWebRTCCallState("disconnected");
  };

  render() {
    const { classes, session } = this.props;
    let webRTCCallState = session.webRTCCallState;
    if (webRTCCallState === "disconnected") {
      return (
        <Fragment>
          <LocalizedText
            className={classes.root}
            value="connectWithDevice"
            variant="h6"
          />
          <TextButton onClick={() => this.handleAudioTestClick()}>
            <LocalizedText value="audioTest" />
          </TextButton>
          <TextButton
            onClick={() => this.makeWebRTCCall()}
            disabled={session.dataConfID == null}
          >
            <LocalizedText value="connect" />
          </TextButton>
        </Fragment>
      );
    } else if (webRTCCallState === "connecting") {
      return (
        <Fragment>
          <LocalizedText
            className={classes.root}
            value="connectWithDevice"
            variant="h6"
          />
          <TextButton color="secondary" disabled={true}>
            <LocalizedText value="connectingDotDotDot" />
          </TextButton>
        </Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = ({ session, conference }) => ({
  session,
  conference
});

const mapDispatchToProps = dispatch => ({
  createDataConfIDParty: (party, userId) =>
    dispatch(createDataConfIDParty(party, userId)),
  updateWebRTCCallState: callState =>
    dispatch(updateWebRTCCallState(callState)),
  showSettingsMenu: () => dispatch(showSettingsMenu()),
  setShakeConferenceSecureIcon: isShaking =>
    dispatch(setShakeConferenceSecureIcon(isShaking)),
  notifyServerUsingWebRTCCall: (userId, isUsingWebRTCCall) =>
    dispatch(notifyServerUsingWebRTCCall(userId, isUsingWebRTCCall)),
  setAudioInput: audioInput => dispatch(setAudioInput(audioInput)),
  setAudioOutput: audioOutput => dispatch(setAudioOutput(audioOutput)),
  setAudioOff: () => dispatch(setAudioOff())
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ConnectWithDevice)
);
