import {
  INTERNAL_DRAWER_HIDE,
  INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST,
  INTERNAL_DRAWER_SHOW_CHAT,
  INTERNAL_DRAWER_SHOW_VIDEO,
  INTERNAL_DRAWER_SHOW_SETTINGS,
  USER_LOGIN,
  USER_LOGOUT,
  AUTHENTICATE_USER,
  STOP_SHARING,
  CONFERENCE_STATE_UPDATE
} from "../actions/types";
import { InternalDrawerState } from "../components/layouts/internalDrawerStates";
import { VideoPopoutState } from "../components/mediasoup/components/PopoutWindow/component";

export default function (
  state = InternalDrawerState.HIDDEN,
  sessionState,
  videoPopoutWindowState,
  action
) {
  let newState = {};
  switch (action.type) {
    case INTERNAL_DRAWER_HIDE:
      newState = InternalDrawerState.HIDDEN;
      return newState;
    case INTERNAL_DRAWER_SHOW_PARTICIPANT_LIST:
      newState = InternalDrawerState.PARTICIPANT_LIST;
      return newState;
    case INTERNAL_DRAWER_SHOW_CHAT:
      newState = InternalDrawerState.CHAT;
      return newState;
    case INTERNAL_DRAWER_SHOW_VIDEO:
      newState = InternalDrawerState.VIDEO;
      return newState;
    case INTERNAL_DRAWER_SHOW_SETTINGS:
      newState = InternalDrawerState.SETTINGS;
      return newState;
    case USER_LOGIN:
      switch (action.payload.expandList) {
        case "participant":
          newState = InternalDrawerState.PARTICIPANT_LIST;
          return newState;
        case "chat":
          newState = InternalDrawerState.CHAT;
          return newState;
        case "video":
          newState = InternalDrawerState.VIDEO;
          return newState;
        case "settings":
          newState = InternalDrawerState.SETTINGS;
          return newState;
        default:
          newState = InternalDrawerState.HIDDEN;
          return newState;
      }
    case USER_LOGOUT:
      newState = { ...state };
      newState = InternalDrawerState.HIDDEN;
      return newState;
    case AUTHENTICATE_USER:
    case STOP_SHARING:
      if (state === InternalDrawerState.VIDEO) {
        newState = InternalDrawerState.HIDDEN;
        return newState;
      }
      return state;
    case CONFERENCE_STATE_UPDATE:
      newState = { ...state };
      if (
        action.payload.videoEnabled &&
        sessionState.sharingActive &&
        state !== InternalDrawerState.VIDEO &&
        videoPopoutWindowState.windowStatus !== VideoPopoutState.OPEN
      ) {
        newState = InternalDrawerState.VIDEO;
        return newState;
      }
      return state;
    default:
      return state;
  }
}
