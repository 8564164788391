import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import classNames from "classnames";
import { setQAWindowExpand, handleQuestionSend } from "./actions";
import AudioQALightView from "./AudioQALightView";
import WebQALightView from "./WebQALightView";
import { QAWindowPopoutState } from "./";

const styles = theme => ({
  widthExpand: {
    width: "800px"
  },
  widthCollapse: {
    width: "540px"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  hrDivPrimary: {
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  footer: {
    marginTop: "3px"
  },
  mainMixContainer: {
    height: "397px"
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row"
  },
  msgBox: {
    color: theme.colors.primaryTextColor
  },
  vtDiv: {
    borderLeft: `1px solid ${theme.colors.primaryMainColor}`,
    margin: "5px 2px 5px 2px"
  },
  subViewContainer: {
    padding: "2px",
    width: "50%"
  },
  fullHeight: {
    height: "100%"
  },
  flexGrow: {
    flexGrow: 1
  }
});

class MixQAView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionMessage: ""
    };

    this.webQAMessageBoxRef = React.createRef();
  }

  componentDidUpdate() {
    const { qa } = this.props;
    const { expand, QAWindowTabIndex } = qa;

    if (QAWindowTabIndex === 2 && expand === false) {
      this.props.setQAWindowExpand(true);
    }
  }

  getHorizontalDividerControl = () => {
    const { classes, qa, qaPopoutWindow } = this.props;
    const { expand } = qa;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    return (
      <div
        className={classNames(
          classes.hrDivPrimary,
          qaPopoutVisible
            ? undefined
            : expand
            ? classes.widthExpand
            : classes.widthCollapse
        )}
      />
    );
  };

  //Mix Control
  getMainMixControl = () => {
    const { classes, qaPopoutWindow } = this.props;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    return (
      <div
        className={classNames(
          classes.displayFlexRow,
          qaPopoutVisible ? classes.fullHeight : classes.mainMixContainer
        )}
      >
        {this.getAudioViewContainerControl()}
        {this.getAudioWebContainerDivider()}
        {this.getWebViewContainerControl()}
      </div>
    );
  };

  getAudioViewContainerControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.subViewContainer}>
        <AudioQALightView />
      </div>
    );
  };

  getWebViewContainerControl = () => {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.subViewContainer, classes.fullHeight)}>
        <WebQALightView />
      </div>
    );
  };

  getAudioWebContainerDivider = () => {
    const { classes } = this.props;

    return <div className={classes.vtDiv} />;
  };

  //Footer Control
  getFooterControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.footer}>{this.getHorizontalDividerControl()}</div>
    );
  };

  //function
  setQuestionMessage = event => {
    this.setState({
      questionMessage: event.target.value
    });
  };

  handleQuestionMessageBoxKeyPress = event => {
    const { qa } = this.props;
    const { dataQAState } = qa;

    if (dataQAState && event.key === "Enter") {
      this.handleQuestionSend(this.state.questionMessage);
      event.preventDefault();
    }
  };

  handleQuestionSend = question => {
    const { session } = this.props;
    const { userId } = session;

    if (question != null && question !== "") {
      this.props.handleQuestionSend(userId, this.state.questionMessage);
      this.setState({ questionMessage: "" });
    }
    this.webQAMessageBoxRef.current.focus();
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className={classNames(classes.displayFlexColumn, classes.fullHeight)}
      >
        {this.getMainMixControl()}
        {this.getFooterControl()}
      </div>
    );
  }
}

const mapStateToProps = ({ qa, session, qaPopoutWindow }) => ({
  qa,
  session,
  qaPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  setQAWindowExpand: isExpanded => dispatch(setQAWindowExpand(isExpanded)),
  handleQuestionSend: (userId, question) =>
    dispatch(handleQuestionSend(userId, question))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(MixQAView))
  )
);
