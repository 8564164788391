import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import {
  setQAWindowStatus,
  startAudioQA,
  stopAudioQA,
  startWebQA,
  stopWebQA,
  resetUnreadDataQA
} from "./actions";
import {
  Tabs,
  Tab,
  MenuItem,
  MenuList,
  Backdrop,
  TextField
} from "@material-ui/core";
import SvgIcon from "../Icons/SvgIcon";
import LocalizedText from "../reusable/LocalizedText";
import TextButton from "../reusable/TextButton";
import classNames from "classnames";
import { getIntl } from "../../utils";
import { isQAActive } from "../../utils";
import AudioQAView from "./AudioQAView";
import WebQAView from "./WebQAView";
import MixQAView from "./MixQAView";
import { setQAWindowTabIndex } from "./actions";
import SwipeableViews from "react-swipeable-views";
import { showQAWindowPopout, QAWindowPopoutState } from "./";
import { qaPopoutWindowName } from "./PopoutWindow/component";
import QuestionCard from "./QuestionCard";
import QuestionCardOptionMenu from "./QuestionCardOptionMenu";
import { isSafari } from "react-device-detect";

export const QAWindowState = {
  CLOSE: 0,
  OPEN: 1
};

export const SessionState = {
  CLOSED: 0,
  INACTIVE: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3
};

export const ActiveSessionType = {
  NOSESSION: 0,
  QAACTIVE: 1,
  VOTEACTIVE: 2
};

export const QAPartyState = {
  AUDIENCE: "AUDIENCE",
  MODERATOR: "MODERATOR",
  FLOORPARTY: "FLOORPARTY",
  PARTY: "PARTY"
};

const styles = theme => ({
  root: {
    height: "460px"
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  widthExpand: {
    width: "800px"
  },
  widthCollapse: {
    width: "540px"
  },
  fullWidth: {
    width: "100%"
  },
  fullHeight: {
    height: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "10px"
  },
  headerIcon: {
    width: "2em"
  },
  headerText: {
    marginLeft: "10px"
  },
  headerButton: {
    alignSelf: "flex-end",
    marginRight: "20px",
    minWidth: "140px",
    minHeight: "30px"
  },
  smallTextButton: {
    minWidth: "120px"
  },
  headerTabContainer: {
    marginLeft: "15px"
  },
  headerTabWrapper: {
    "& span": {
      padding: "0"
    }
  },
  headerTabRoot: {
    minWidth: "50px",
    minHeight: "21px"
  },
  headerTabsRoot: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.colors.primaryBackgroundColor,
    borderRadius: "25px",
    minHeight: "35px"
  },
  tabIndicator: {
    height: "100%",
    opacity: "0.4",
    borderRadius: "25px"
  },
  tabText: {
    height: "35px",
    paddingTop: "8px !important"
  }
});

class HostQAWindow extends Component {
  componentDidMount() {
    this.resetUnreadData();
  }

  componentDidUpdate = () => {
    this.resetUnreadData();
  };

  overrideHandleClose = () => {
    this.props.setQAWindowStatus(false);
  };

  //Window header controls
  getAudioStartControl = () => {
    const { classes, conference, qa, intl, theme, session } = this.props;
    const { expand } = qa;
    const qaInProgress = isQAActive(conference);

    let disabled = session.mergedCallState !== "connected";

    return (
      <TextButton
        className={classNames(
          classes.headerButton,
          !expand && classes.smallTextButton
        )}
        color={qaInProgress ? "error" : "primary"}
        onClick={event => {
          if (!disabled) {
            this.handleAudioStartStopButtonOnClick(qaInProgress);
          } else {
            document.getElementById("connectPopperButton").click();
          }
        }}
        style={{ pointerEvents: "auto" }}
        title={
          disabled
            ? intl.formatMessage(getIntl("connectYourAudioToEnable"))
            : qaInProgress
            ? intl.formatMessage(getIntl("endAudioQA"))
            : intl.formatMessage(getIntl("startAudioQA"))
        }
      >
        <LocalizedText
          value={qaInProgress ? "endAudioQA" : "startAudioQA"}
          color={
            qaInProgress ? theme.errorTextColor : theme.primaryMainTextColor
          }
        />
      </TextButton>
    );
  };

  getWebStartControl = () => {
    const { classes, qa, intl, theme } = this.props;
    const { expand, dataQAState } = qa;

    return (
      <TextButton
        className={classNames(
          classes.headerButton,
          !expand && classes.smallTextButton
        )}
        color={dataQAState ? "error" : "primary"}
        onClick={event => {
          this.handleWebStartStopButtonOnClick(dataQAState);
        }}
        style={{ pointerEvents: "auto" }}
        title={
          dataQAState
            ? intl.formatMessage(getIntl("endWebQA"))
            : intl.formatMessage(getIntl("startWebQA"))
        }
      >
        <LocalizedText
          value={dataQAState ? "endWebQA" : "startWebQA"}
          color={
            dataQAState ? theme.errorTextColor : theme.primaryMainTextColor
          }
        />
      </TextButton>
    );
  };

  getWindowHeaderControl = () => {
    const { classes, qa } = this.props;
    const { QAWindowTabIndex } = qa;

    return (
      <div className={classes.header}>
        <div className={classes.headerIconContainer}>
          <SvgIcon iconName="qaGeneral" className={classes.headerIcon} />

          <LocalizedText
            value="questionQueue"
            variant="h6"
            className={classes.headerText}
          />
          <div className={classes.headerTabContainer}>
            <Tabs
              value={qa.QAWindowTabIndex}
              onChange={this.handleQAWindowTabSelectChange}
              textColor="inherit"
              indicatorColor="primary"
              classes={{
                root: classes.headerTabsRoot,
                indicator: classes.tabIndicator
              }}
            >
              <Tab
                classes={{
                  wrapper: classes.headerTabWrapper,
                  root: classes.headerTabRoot
                }}
                label={
                  <LocalizedText
                    value="audio"
                    variant="caption"
                    className={classes.tabText}
                  />
                }
              />
              <Tab
                classes={{
                  wrapper: classes.headerTabWrapper,
                  root: classes.headerTabRoot
                }}
                label={
                  <LocalizedText
                    value="web"
                    variant="caption"
                    className={classes.tabText}
                  />
                }
              />
              <Tab
                classes={{
                  wrapper: classes.headerTabWrapper,
                  root: classes.headerTabRoot
                }}
                label={
                  <LocalizedText
                    value="mix"
                    variant="caption"
                    className={classes.tabText}
                  />
                }
              />
            </Tabs>
          </div>
        </div>
        <div>
          {QAWindowTabIndex === 0 && this.getAudioStartControl()}
          {QAWindowTabIndex === 1 && this.getWebStartControl()}
          {QAWindowTabIndex === 2 && this.getAudioStartControl()}
          {QAWindowTabIndex === 2 && this.getWebStartControl()}
        </div>
      </div>
    );
  };

  //functions
  handleAudioStartStopButtonOnClick = qaInProgress => {
    const { session } = this.props;

    if (qaInProgress) {
      this.props.stopAudioQA(session.userId);
    } else {
      this.props.startAudioQA(session.userId);
    }
  };

  handleWebStartStopButtonOnClick = dataQAState => {
    const { session } = this.props;

    if (dataQAState) {
      this.props.stopWebQA(session.userId);
    } else {
      this.props.startWebQA(session.userId);
    }
  };

  handleQAWindowTabSelectChange = (event, tabIndex) => {
    this.props.setQAWindowTabIndex(tabIndex);
  };

  handlePopoutClick = () => {
    this.props.showQAWindowPopout();
    this.props.setQAWindowStatus(false);
  };

  // Force Material-UI to load styles needed in new window
  getClassesForPopout = () => {
    return (
      <div style={{ display: "none" }}>
        <AudioQAView />
        <WebQAView />
        <MixQAView />
        <QuestionCard questionId={-1} />
        <QuestionCardOptionMenu open={false} />
        <MenuList />
        <MenuItem />
        <Backdrop open={false} />
        <TextField />
      </div>
    );
  };

  resetUnreadData = () => {
    const { qa } = this.props;
    const { unreadDataQAEntries, QAWindowTabIndex } = qa;

    if (
      unreadDataQAEntries != null &&
      unreadDataQAEntries.size > 0 &&
      (QAWindowTabIndex === 1 || QAWindowTabIndex === 2)
    ) {
      this.props.resetUnreadDataQA();
    }
  };

  render() {
    const { classes, qa, qaPopoutWindow } = this.props;
    const { expand, QAWindowTabIndex } = qa;
    const qaPopoutVisible =
      qaPopoutWindow.windowStatus === QAWindowPopoutState.OPEN;

    const component = (
      <div
        className={classNames(
          classes.displayFlexColumn,
          qaPopoutVisible ? classes.fullHeight : classes.root,
          qaPopoutVisible
            ? classes.fullWidth
            : expand
            ? classes.widthExpand
            : classes.widthCollapse
        )}
      >
        {this.getWindowHeaderControl()}
        <div className={classes.fullHeight}>
          <SwipeableViews
            index={QAWindowTabIndex}
            containerStyle={{ height: "100%" }}
            slideStyle={{ overflow: "hidden" }}
          >
            <AudioQAView />
            <WebQAView />
            <MixQAView />
          </SwipeableViews>
        </div>
        {this.getClassesForPopout()}
      </div>
    );

    return (
      <CTXBaseWindow
        component={component}
        overrideHandleClose={this.overrideHandleClose}
        disableClickAwayClose
        hidePopoutButton={isSafari || qaPopoutVisible}
        hideCloseButton={qaPopoutVisible}
        fullWidth={qaPopoutVisible}
        fullHeight={qaPopoutVisible}
        handlePopoutClick={this.handlePopoutClick}
      />
    );
  }
}

const mapStateToProps = ({
  conference,
  qa,
  participants,
  session,
  qaPopoutWindow
}) => ({
  conference,
  qa,
  participants,
  session,
  qaPopoutWindow
});

const mapDispatchToProps = dispatch => ({
  setQAWindowStatus: isVisible => dispatch(setQAWindowStatus(isVisible)),
  startAudioQA: userId => dispatch(startAudioQA(userId)),
  stopAudioQA: userId => dispatch(stopAudioQA(userId)),
  setQAWindowTabIndex: index => dispatch(setQAWindowTabIndex(index)),
  startWebQA: userId => dispatch(startWebQA(userId)),
  stopWebQA: userId => dispatch(stopWebQA(userId)),
  showQAWindowPopout: () => dispatch(showQAWindowPopout(qaPopoutWindowName)),
  resetUnreadDataQA: () => dispatch(resetUnreadDataQA())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(HostQAWindow))
  )
);
