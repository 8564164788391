import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { PortalContent } from "../../components/layouts";
import AppLogo from "../Icons/AppLogo";
import PoweredByCpx from "../Icons/PoweredByCpx";
import LocalizedText from "./../reusable/LocalizedText";
import SentimentVeryDissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";

const styles = theme => ({
  root: {
    height: "100%"
  },
  baseComponent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  notSupportedText: {
    color: theme.colors.primaryBackgroundImageTextColor
  },
  iconMargin: {
    marginRight: "10px"
  },
  notSupportedBrowserText: {
    display: "flex",
    flexDirection: "row"
  },
  logo: {
    maxHeight: "200px",
    width: "330px",
    objectFit: "contain",
    marginBottom: "20px"
  },
  compunetix: {
    position: "fixed",
    bottom: "2em",
    right: "6em",
    opacity: 100
  },
  compunetixDimension: {
    height: "20px"
  }
});

class UnsupportedBrowserPage extends Component {
  getComponent = () => {
    const { classes } = this.props;

    return (
      <div className={classes.baseComponent}>
        <AppLogo classes={{ root: classes.logo }} logoType="body" />
        <div className={classes.notSupportedText}>
          <div className={classes.notSupportedBrowserText}>
            <SentimentVeryDissatisfied
              color="error"
              fontSize="large"
              className={classes.iconMargin}
            />
            <LocalizedText value="notSupportedBrowser" variant="h6" />
          </div>
          <br />
          <LocalizedText value="connectNowWorksOn" variant="h6" />
        </div>
        <div className={classes.compunetix}>
          <PoweredByCpx classes={{ root: classes.compunetixDimension }} />
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    const component = this.getComponent();

    return (
      <Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <PortalContent component={component} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = theme => ({ theme });

const mapDispatchToProps = dispatch => ({});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UnsupportedBrowserPage)
);
