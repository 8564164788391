import {
  SET_QA_WINDOW_VISIBILITY,
  UPDATE_QA_PARTY_STATE,
  UPDATE_QA_QUEUE,
  SET_QA_WINDOW_EXPAND,
  SET_QA_POPPER_OPEN_STATE,
  SET_QA_POPPER_CONTENTS,
  SET_DATA_QA_STATE,
  UPDATE_DATA_QA_ENTRY,
  REMOVE_DATA_QA_ENTRY,
  SET_QA_WINDOW_TAB_INDEX,
  RESET_UNREAD_DATA_QA_ENTRY
} from "./actions";
import { QAPartyState, SessionState, ActiveSessionType } from "./HostQAWindow";
import { QAPopperContents } from "../poppers/QAPopper";
import {
  PARTICIPANTS_BUFFERED_UPDATE,
  USER_LOGOUT,
  CONFERENCE_UPDATE
} from "../../actions/types";

var floorPartyTalkingState;

export default function(
  state = {
    QAWindowStatus: 0,
    QAWindowTabIndex: 0,
    expand: true,
    parties: new Map(),
    floorPartyId: null,
    floorPartyTalking: false,
    queue: [],
    moderators: [],
    popperOpen: false,
    popperContents: QAPopperContents.DEFAULT,
    dataQAState: false,
    dataQAEntries: new Map(),
    unreadDataQAEntries: new Set()
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case SET_QA_WINDOW_VISIBILITY:
      newState = { ...state, QAWindowStatus: action.payload };
      return newState;
    case UPDATE_QA_PARTY_STATE:
      newState = {
        ...state
      };
      //Remove old QA party state
      if (newState.parties.has(action.payload.id)) {
        switch (newState.parties.get(action.payload.id)) {
          case QAPartyState.FLOORPARTY:
            newState.floorPartyId = null;
            newState.floorPartyTalking = false;
            floorPartyTalkingState = false;
            break;
          case QAPartyState.MODERATOR:
            let modIndex = newState.moderators.findIndex(
              modId => modId === action.payload.id
            );
            newState.moderators.splice(modIndex, 1);
            break;
          case QAPartyState.AUDIENCE:
            break;
          case QAPartyState.PARTY:
            let partyIndex = newState.queue.findIndex(
              partyId => partyId === action.payload.id
            );
            newState.queue.splice(partyIndex, 1);
            break;
          default:
            break;
        }
      }
      //Set new QA party state
      newState.parties.set(action.payload.id, action.payload.qaState);
      //Based on new QA party state, update qa store
      switch (action.payload.qaState) {
        case QAPartyState.FLOORPARTY:
          newState.floorPartyId = action.payload.id;
          newState.floorPartyTalking = false;
          floorPartyTalkingState = false;
          break;
        case QAPartyState.MODERATOR:
          newState.moderators.push(action.payload.id);
          break;
        case QAPartyState.PARTY:
          newState.queue.splice(
            action.payload.qaConfPosition - 1,
            0,
            action.payload.id
          );
          break;
        case QAPartyState.AUDIENCE:
        default:
          break;
      }
      return newState;
    case UPDATE_QA_QUEUE:
      newState = {
        ...state
      };

      let partyIndex = newState.queue.findIndex(
        partyId => partyId === action.payload.id
      );
      newState.queue.splice(partyIndex, 1);
      newState.queue.splice(
        action.payload.qaConfPosition - 1,
        0,
        action.payload.id
      );

      return newState;
    case CONFERENCE_UPDATE:
      newState = {
        ...state
      };

      if (
        //QA session ends
        action.payload.sessionState !== undefined &&
        action.payload.sessionState === SessionState.CLOSED &&
        action.payload.activeSessionType !== undefined &&
        action.payload.activeSessionType === ActiveSessionType.NOSESSION
      ) {
        newState.parties = new Map();
        newState.floorPartyId = null;
        newState.floorPartyTalking = false;
        floorPartyTalkingState = false;
        newState.queue = [];
        newState.moderators = [];
        if (newState.popperContents !== QAPopperContents.DEFAULT) {
          //prevent displaying QA end notice during initial load
          newState.popperContents = QAPopperContents.QA_END;
          newState.popperOpen = true;
        }
      } else if (
        //QA session starts
        action.payload.sessionState !== undefined &&
        (action.payload.sessionState === SessionState.INACTIVE ||
          action.payload.sessionState === SessionState.IN_PROGRESS) &&
        action.payload.activeSessionType !== undefined &&
        action.payload.activeSessionType === ActiveSessionType.QAACTIVE
      ) {
        newState.popperOpen = true;
        newState.popperContents = QAPopperContents.QA_START;
      }

      return newState;
    case SET_QA_WINDOW_EXPAND:
      newState = {
        ...state
      };
      newState.expand = action.payload;
      return newState;
    case SET_QA_POPPER_OPEN_STATE:
      newState = {
        ...state
      };
      newState.popperOpen = action.payload;
      return newState;
    case SET_QA_POPPER_CONTENTS:
      newState = {
        ...state
      };
      newState.popperContents = action.payload;
      return newState;
    case PARTICIPANTS_BUFFERED_UPDATE:
      newState = {
        ...state
      };

      updateFloorPartyTalkingState(action.payload, newState.floorPartyId);
      if (floorPartyTalkingState !== undefined) {
        newState.floorPartyTalking = floorPartyTalkingState;
      }

      return newState;
    case USER_LOGOUT:
      newState = {
        QAWindowStatus: 0,
        QAWindowTabIndex: 0,
        expand: true,
        parties: new Map(),
        floorPartyId: null,
        floorPartyTalking: false,
        queue: [],
        moderators: [],
        popperOpen: false,
        popperContents: QAPopperContents.DEFAULT,
        dataQAState: false,
        dataQAEntries: new Map(),
        unreadDataQAEntries: new Set()
      };

      return newState;
    case SET_DATA_QA_STATE:
      newState = {
        ...state
      };

      newState.dataQAState = action.payload;

      if (newState.dataQAState === true) {
        if (!state.dataQAState) {
          newState.popperOpen = true;
          newState.popperContents = QAPopperContents.WEB_QA_START;
        }
      } else {
        if (state.dataQAState) {
          //prevent displaying QA end notice during initial load
          newState.popperOpen = true;
          newState.popperContents = QAPopperContents.WEB_QA_END;
        }
      }

      return newState;
    case UPDATE_DATA_QA_ENTRY:
      newState = {
        ...state
      };

      //update unreadDataQAEntries
      if (
        action.payload != null &&
        action.payload.id != null &&
        !newState.dataQAEntries.has(action.payload.id)
      ) {
        newState.unreadDataQAEntries.add(action.payload.id);
      }

      //update dataQAEntries
      if (action.payload != null && action.payload.id != null) {
        newState.dataQAEntries.set(action.payload.id, action.payload);
      }

      return newState;
    case REMOVE_DATA_QA_ENTRY:
      newState = {
        ...state
      };

      if (
        action.payload != null &&
        newState.dataQAEntries.has(action.payload)
      ) {
        newState.dataQAEntries.delete(action.payload);
      }

      //update unreadDataQAEntries
      if (
        action.payload != null &&
        newState.unreadDataQAEntries.has(action.payload)
      ) {
        newState.unreadDataQAEntries.delete(action.payload);
      }

      return newState;
    case SET_QA_WINDOW_TAB_INDEX:
      newState = {
        ...state
      };
      newState.QAWindowTabIndex = action.payload;
      return newState;
    case RESET_UNREAD_DATA_QA_ENTRY:
      newState = {
        ...state
      };
      newState.unreadDataQAEntries = new Set();
      return newState;
    default:
      return state;
  }
}

function updateFloorPartyTalkingState(partiesArray, targetPartyId) {
  if (
    partiesArray === undefined ||
    targetPartyId === undefined ||
    partiesArray.length === 0
  ) {
    return;
  }

  partiesArray.forEach(partyObj => {
    if (
      partyObj !== undefined &&
      partyObj.id !== undefined &&
      partyObj.id === targetPartyId
    ) {
      if (partyObj.talking !== undefined) {
        floorPartyTalkingState = partyObj.talking;
        return;
      }
    }
  });
}
