import {
  QA_WINDOW_POPOUT_SHOW,
  QA_WINDOW_POPOUT_HIDE,
  QA_WINDOW_POPOUT_RESIZE_IN_PROGRESS
} from "./actions";
import { QAWindowPopoutState } from "./component";
import { USER_LOGOUT } from "../../../actions/types";

export default function(
  state = {
    windowStatus: QAWindowPopoutState.CLOSE,
    windowName: undefined
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case QA_WINDOW_POPOUT_SHOW:
      newState = {
        ...state,
        windowStatus: QAWindowPopoutState.OPEN,
        windowName: action.payload
      };
      return newState;
    case QA_WINDOW_POPOUT_RESIZE_IN_PROGRESS:
      newState = {
        ...state
      };
      return newState;
    case USER_LOGOUT:
    case QA_WINDOW_POPOUT_HIDE:
      newState = {
        ...state,
        windowStatus: QAWindowPopoutState.CLOSE,
        windowName: undefined
      };
      return newState;
    default:
      return state;
  }
}
