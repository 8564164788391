import {
  USER_LOGIN,
  SET_CONNECT_POPPER_EXPANSION,
  SET_SHARE_POPPER_EXPANSION,
  SET_MORE_POPPER_EXPANSION,
  SET_AUDIO_POPPER_EXPANSION,
  SET_SIGNAL_OPERATOR_POPPER_EXPANSION
} from "../actions/types";

export default function (
  state = {
    connect: { expandConnectPopper: false },
    share: { expandSharePopper: false },
    more: { expandMorePopper: false },
    audio: { expandAudioPopper: false },
    signalOperator: { expandSignalOperatorPopper: false }
  },
  action
) {
  let newState = {};
  switch (action.type) {
    case USER_LOGIN:
      newState = {
        connect: { expandConnectPopper: action.payload.expandConnectPopper },
        share: { expandSharePopper: false },
        more: { expandMorePopper: false },
        audio: { expandAudioPopper: false },
        signalOperator: { expandSignalOperatorPopper: false }
      };
      return newState;
    case SET_CONNECT_POPPER_EXPANSION:
      newState = {
        ...state,
        connect: { expandConnectPopper: action.payload }
      };
      return newState;
    case SET_SHARE_POPPER_EXPANSION:
      newState = {
        ...state,
        share: { expandSharePopper: action.payload }
      };
      return newState;
    case SET_MORE_POPPER_EXPANSION:
      newState = {
        ...state,
        more: { expandMorePopper: action.payload }
      };
      return newState;
    case SET_AUDIO_POPPER_EXPANSION:
      newState = {
        ...state,
        audio: { expandAudioPopper: action.payload }
      };
      return newState;
    case SET_SIGNAL_OPERATOR_POPPER_EXPANSION:
      newState = {
        ...state,
        signalOperator: { expandSignalOperatorPopper: action.payload }
      };
      return newState;
    default:
      return state;
  }
}
