import React, { Component } from "react";
import { connect } from "react-redux";

class PortalLogo extends Component {
  render() {
    const { classes, logoType } = this.props;
    let rootStyle;
    if (classes != null) {
      rootStyle = classes.root;
    }

    var logoSrc;
    if (logoType === "header") {
      logoSrc =
        "/" +
        this.props.theme.imagesLocation +
        "/" +
        window.CtxThemeConfigurations.portalHeaderLogo;
    } else if (logoType === "body") {
      logoSrc =
        "/" +
        this.props.theme.imagesLocation +
        "/" +
        window.CtxThemeConfigurations.portalLogo;
    }

    return <img src={logoSrc} className={rootStyle} alt="ConnectNow Portal" />;
  }
}

const mapStateToProps = ({ theme }) => ({ theme });

export default connect(mapStateToProps)(PortalLogo);
