import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  AudioPopper,
  ConnectPopper,
  MorePopper,
  SharePopper,
  QAPopper,
  VideoPopper,
  SignalOperatorPopper
} from "../poppers";
import {
  ConferenceRecordingIndicator,
  ConferenceQAIndicator
} from "../statusIndicators";
import FullScreenIcon from "../Icons/FullScreenIcon";
import { isMobileOrTablet, isVideoEnabled } from "../../utils";
import classNames from "classnames";

const styles = theme => ({
  root: {
    gridColumn: "3",
    gridRow: "2",
    msGridColumn: "3",
    msGridRow: "2",
    backgroundColor: theme.colors.sideBarBackgroundColor,
    display: "flex",
    flexDirection: "column",
    zIndex: 1
  },
  conferenceIndicatorContainer: {
    display: "block",
    height: "64px"
  },
  popperButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center"
  },
  transparent: {
    opacity: 0.95
  }
});

//TODO - handle disabled states correctly
class RightBar extends Component {
  render() {
    const { classes, displayContent, displayVideoPopper, isHost, session } =
      this.props;

    return (
      <div
        className={classNames(
          classes.root,
          session.fullScreenMode && classes.transparent
        )}
      >
        {displayContent && (
          <Fragment>
            <div className={classes.conferenceIndicatorContainer}>
              <ConferenceRecordingIndicator />
              <ConferenceQAIndicator />
            </div>
            <div className={classes.popperButtonsContainer}>
              <div>
                {isVideoEnabled(session.videoConfig) && <VideoPopper />}
                {window.CtxAppConfigurations.customer !== "TCAD" && (
                  <>
                    <AudioPopper />
                    <ConnectPopper />
                  </>
                )}
                {navigator.mediaDevices.getDisplayMedia && (
                  <SharePopper
                    disabled={session.sharingActive && !session.isSharer}
                  />
                )}
                {window.CtxAppConfigurations.customer !== "TCAD" && (
                  <>
                    <SignalOperatorPopper />
                    {!isHost && <QAPopper />}
                    {!isMobileOrTablet() && isHost && <MorePopper />}
                  </>
                )}
              </div>
            </div>
            <div className={classes.conferenceIndicatorContainer}>
              <FullScreenIcon />
            </div>
          </Fragment>
        )}
        {displayVideoPopper && isVideoEnabled(session.videoConfig) && (
          <div className={classes.popperButtonsContainer}>
            <VideoPopper />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(connect(mapStateToProps)(RightBar));
