import contexWebRest from "../../api/contexWebRest";
import { InviteByPhoneParticipantState } from "./InviteByPhoneWindow";

//-----------Action types----------//

export const SHOW_INVITE_PARTICIPANT = "SHOW_INVITE_PARTICIPANT";
export const HIDE_INVITE_PARTICIPANT = "HIDE_INVITE_PARTICIPANT";
export const SHOW_INVITE_BY_PHONE = "SHOW_INVITE_BY_PHONE";
export const HIDE_INVITE_BY_PHONE = "HIDE_INVITE_BY_PHONE";
export const UPDATE_INVITE_BY_PHONE_PARTICIPANT =
  "UPDATE_INVITE_BY_PHONE_PARTICIPANT";

//---------Action creators-------------//

export const showInviteParticipant = payload => ({
  type: SHOW_INVITE_PARTICIPANT,
  payload
});

export const hideInviteParticipant = payload => ({
  type: HIDE_INVITE_PARTICIPANT,
  payload
});

export const showInviteByPhone = payload => ({
  type: SHOW_INVITE_BY_PHONE,
  payload
});

export const hideInviteByPhone = payload => ({
  type: HIDE_INVITE_BY_PHONE,
  payload
});

export const updateInviteByPhoneParticipant = payload => ({
  type: UPDATE_INVITE_BY_PHONE_PARTICIPANT,
  payload
});

export const createAndCallParty =
  (party, userSessionID, associate, userID) => async (dispatch, getState) => {
    const requestBody = party;
    const headers = {
      "Content-Type": "application/json"
    };
    try {
      let requestURL = [];
      requestURL.push("/party/create_party_call/");
      requestURL.push(userSessionID);
      requestURL.push(
        "/1?join=true&forceDisconnect=true&blocking=true&associate="
      );
      requestURL.push(associate);
      if (associate) {
        requestURL.push("&userID=");
        requestURL.push(userID);
      }
      requestURL = requestURL.join("");

      let response = await contexWebRest.post(requestURL, requestBody, {
        headers: headers
      });

      dispatch({
        type: UPDATE_INVITE_BY_PHONE_PARTICIPANT,
        payload: {
          state: InviteByPhoneParticipantState.CALLING,
          partyid: response.data.id
        }
      });
      return response.data.id;
    } catch (error) {
      console.log("Exception: invite participant by phone.", error);
    }
  };

export const disconnectAndRemoveParty =
  (partyids, userid) => async dispatch => {
    let partyIds = "";

    if (partyids instanceof Array) {
      for (var i = 0; i < partyids.length; i++) {
        partyIds += "partyIds[]=" + partyids[i];
        if (i < partyids.length - 1) {
          partyIds += "&";
        }
      }
    } else {
      partyIds = "partyIds[]=" + partyids;
    }

    const requestBody = partyIds + "&reqSeq=1";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    try {
      await contexWebRest.post("/party/destroy/" + userid, requestBody, {
        headers: headers
      });
    } catch (error) {
      dispatch({
        type: UPDATE_INVITE_BY_PHONE_PARTICIPANT,
        payload: { state: InviteByPhoneParticipantState.ERROR }
      });

      console.error(
        error + "-- while cancelling outgoing call by destroying party."
      );
    }
  };
