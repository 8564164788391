import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { RightBarDummy, LeftBar, MainContent } from "../../components/layouts";
import Toolbar from "@material-ui/core/Toolbar";
import LocalizedText from "./../reusable/LocalizedText";

const styles = theme => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "64px 1fr 64px",
    gridTemplateRows: "1fr",
    msGridColumns: "64px 1fr 64px",
    msGridRows: "1fr"
  },
  headerSpacer: {
    gridColumn: "1 / span 3",
    gridRow: "1",
    msGridColumn: "1 / span 3",
    msGridRow: "1"
  },
  logoDimension: {
    height: "30px",
    maxWidth: "180px",
    objectFit: "contain"
  },
  logo: {
    height: "100%",
    width: "180px",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    flexShrink: 0
  },
  logoBody: {
    maxHeight: "200px",
    width: "100%",
    objectFit: "contain",
    marginBottom: "20px"
  },
  component: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "330px"
  },
  conferenceName: {
    margin: "0 4px"
  }
});

class MainPageDummy extends Component {
  render() {
    const { classes } = this.props;
    const component = (
      <div className={classes.component}>
        <img
          src={"/" + this.props.imagesLocation + "/" + this.props.bodyLogo}
          className={classes.logoBody}
          alt="ConnectNow"
        />
        <LocalizedText
          value="connectNowGreeting"
          align="center"
          variant="h6"
          style={{ color: this.props.color4 }}
        />
      </div>
    );

    return (
      <Fragment>
        <div
          className={classes.headerSpacer}
          style={{ backgroundColor: this.props.color1 }}
        >
          <Toolbar disableGutters={true}>
            <div className={classes.logo}>
              <img
                src={
                  "/" + this.props.imagesLocation + "/" + this.props.headerLogo
                }
                className={classes.logoDimension}
                alt="ConnectNow"
              />
            </div>
            <LocalizedText
              value="connectNowConferenceName"
              align="center"
              variant="h6"
              style={{ color: this.props.color3 }}
              className={classes.conferenceName}
            />
            <div className={classes.logo} />
          </Toolbar>
        </div>
        <div className={classes.root} id="mainContent">
          <LeftBar displayContent={true} isHost={true} />
          <MainContent
            component={component}
            style={{
              backgroundImage:
                "url('/" +
                this.props.imagesLocation +
                "/" +
                this.props.backgroundImage +
                "')"
            }}
          />
          <RightBarDummy
            displayContent={true}
            colors={[this.props.color1, this.props.color2, this.props.color3]}
            rightBarWithButtons={this.props.rightBarWithButtons}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session, popper }) => ({ session, popper });

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(MainPageDummy))
);
