import React, { Component } from "react";
import TextButton from "../reusable/TextButton";
import { connect } from "react-redux";
import { disconnectParty } from "../../actions";
import { withStyles } from "@material-ui/core";
import LocalizedText from "./../reusable/LocalizedText";

const styles = theme => ({
  root: {
    alignSelf: "flex-start",
    marginBottom: "10px"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  }
});

class DisconnectCall extends Component {
  handleYesClick = () => {
    const { session } = this.props;
    if (session.mergedCallState === "connected") {
      if (session.isWebRTCCall) {
        if (window.webRTCSession) {
          window.webRTCSession.terminate();
        }
      } else {
        this.props.disconnectCall(session.partyID, session.userId);
      }
    }
    this.props.handleClickAway();
  };

  handleNoClick = () => {
    this.props.handleClickAway();
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <LocalizedText
          className={classes.root}
          value="disconnectAudio"
          variant="h6"
        />
        <TextButton onClick={this.handleYesClick} color="error">
          <LocalizedText value="yes" className={classes.errorTextColor} />
        </TextButton>
        <TextButton onClick={this.handleNoClick} color="secondary">
          <LocalizedText value="no" className={classes.primaryTextColor} />
        </TextButton>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  disconnectCall: (partyID, userId) =>
    dispatch(disconnectParty(partyID, userId)).then(party => {
      console.log("Disconnected the call " + JSON.stringify(party));
    })
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DisconnectCall)
);
