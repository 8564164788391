import jsCookie from "js-cookie";

const USER_COOKIE = "mediasoup-demo.user";
const DEVICES_COOKIE = "mediasoup-demo.devices";
const VIDEO_INPUT_COOKIE = "mediasoup-demo.videoInput";
const EXPIRATION = 365;

export function getUser() {
  return jsCookie.getJSON(USER_COOKIE);
}

export function setUser({ displayName }) {
  jsCookie.set(
    USER_COOKIE,
    { displayName },
    { expires: EXPIRATION, sameSite: "Lax", secure: true }
  );
}

export function getDevices() {
  return jsCookie.getJSON(DEVICES_COOKIE);
}

export function setDevices({ webcamEnabled }) {
  console.log("Cookie fn setDevices: ", webcamEnabled);
  jsCookie.set(
    DEVICES_COOKIE,
    { webcamEnabled },
    { expires: EXPIRATION, sameSite: "Lax", secure: true }
  );
}

export function getVideoInputDeviceId() {
  return jsCookie.getJSON(VIDEO_INPUT_COOKIE);
}

export function setVideoInputDeviceId({ videoInputDeviceId }) {
  console.log("Cookie fn setVideoInputDeviceId: ", videoInputDeviceId);
  jsCookie.set(
    VIDEO_INPUT_COOKIE,
    { videoInputDeviceId },
    { expires: EXPIRATION, sameSite: "Lax", secure: true }
  );
}
