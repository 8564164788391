import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, withTheme } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import {
  hideInternalDrawer,
  displayParticipantListInternalDrawer,
  displayChatInternalDrawer,
  displayVideoInternalDrawer,
  displaySettingsInternalDrawer,
  setMainRoom,
  resetMainRoom
} from "../../actions";
import SvgIcon from "../Icons/SvgIcon";
import { InternalDrawerState } from "../layouts/internalDrawerStates";
import { injectIntl } from "react-intl";
import {
  isHoldStateApplicable,
  getIntl,
  TitleNewLine,
  isVideoEnabled
} from "../../utils";
import classNames from "classnames";

const styles = theme => ({
  root: {
    gridColumn: "1",
    gridRow: "2",
    msGridColumn: "1",
    msGridRow: "2",
    backgroundColor: theme.colors.sideBarBackgroundColor,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1
  },
  svg: {
    width: "100%"
  },
  overlayIcon: {
    height: "20%"
  },
  badgeRoot: {
    width: "100%",
    display: "inline",
    position: "relative",
    verticalAlign: "middle"
  },
  badgeColor: {
    backgroundColor: theme.colors.errorMainColor,
    color: theme.colors.errorTextColor
  },
  transparent: {
    opacity: 0.95
  },
  iconButton: {
    width: "100%"
  }
});

class LeftBar extends Component {
  handleParticipantList = () => {
    const { participantListPopoutWindow, internalDrawer, session } = this.props;

    if (participantListPopoutWindow.windowName) {
      window.open("", participantListPopoutWindow.windowName);
    } else {
      if (internalDrawer === InternalDrawerState.PARTICIPANT_LIST) {
        this.props.hideInternalDrawer();
      } else {
        if (!session.sharingActive) {
          this.props.setMainRoom();
        }
        this.props.displayParticipantListInternalDrawer();
      }
    }
  };

  handleChat = () => {
    const { chatPopoutWindow, internalDrawer, session } = this.props;

    if (chatPopoutWindow.windowName) {
      window.open("", chatPopoutWindow.windowName);
    } else {
      if (internalDrawer === InternalDrawerState.CHAT) {
        this.props.hideInternalDrawer();
      } else {
        if (!session.sharingActive) {
          this.props.setMainRoom();
        }
        this.props.displayChatInternalDrawer();
      }
    }
  };

  handleVideo = () => {
    const { videoPopoutWindow, internalDrawer, session } = this.props;
    if (videoPopoutWindow.windowName) {
      window.open("", videoPopoutWindow.windowName);
      this.props.resetMainRoom();
    } else {
      if (internalDrawer === InternalDrawerState.VIDEO) {
        if (!session.sharingActive) {
          this.props.setMainRoom();
        }
        this.props.hideInternalDrawer();
      } else {
        this.props.displayVideoInternalDrawer();
        this.props.resetMainRoom();
      }
    }
  };

  handleSettings = () => {
    const { internalDrawer, session } = this.props;
    if (internalDrawer === InternalDrawerState.SETTINGS) {
      this.props.hideInternalDrawer();
    } else {
      if (!session.sharingActive) {
        this.props.setMainRoom();
      }
      this.props.displaySettingsInternalDrawer();
    }
  };

  render() {
    const {
      classes,
      displayContent,
      participantListPopoutWindow,
      chatPopoutWindow,
      videoPopoutWindow,
      internalDrawer,
      participants,
      unreadChats,
      conference,
      session,
      intl
    } = this.props;

    const unreadChatCount = unreadChats.unreadCount;
    const waitingRoomPartyCount = participants.waitingRoomParties.length;

    const participantListActive =
      participantListPopoutWindow.windowName ||
      internalDrawer === InternalDrawerState.PARTICIPANT_LIST;

    const chatActive =
      chatPopoutWindow.windowName ||
      internalDrawer === InternalDrawerState.CHAT;

    const videoActive =
      videoPopoutWindow.windowName ||
      internalDrawer === InternalDrawerState.VIDEO;

    const settingsActive = internalDrawer === InternalDrawerState.SETTINGS;

    const holdState = isHoldStateApplicable(conference, session);

    const participantsIcon = participantListActive ? (
      <Fragment>
        <svg className={classes.svg} viewBox="0 0 1 1">
          <SvgIcon iconName="circle" viewBox="none" />
          <SvgIcon iconName="participantList" color="inactive" viewBox="none" />
        </svg>
      </Fragment>
    ) : (
      <Fragment>
        <svg className={classes.svg} viewBox="0 0 1 1">
          <SvgIcon iconName="circle" viewBox="none" />
          <SvgIcon iconName="participantList" color="sideBar" viewBox="none" />
        </svg>
      </Fragment>
    );

    const participantsIconWithBadge = (
      <Badge
        badgeContent={waitingRoomPartyCount}
        max={99}
        color="primary"
        classes={{
          root: classes.badgeRoot,
          colorPrimary: classes.badgeColor
        }}
      >
        {participantsIcon}
      </Badge>
    );

    const chatIcon = chatActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="chat" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="chat" color="sideBar" viewBox="none" />
      </svg>
    );

    const chatIconWithBadge = (
      <Badge
        badgeContent={unreadChatCount}
        max={99}
        color="primary"
        classes={{
          root: classes.badgeRoot,
          colorPrimary: classes.badgeColor
        }}
      >
        {chatIcon}
      </Badge>
    );

    const videoIcon = holdState ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="video" color="inactive" viewBox="none" />
      </svg>
    ) : videoActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="video" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="video" color="sideBar" viewBox="none" />
      </svg>
    );

    const settingsIcon = settingsActive ? (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="settings" color="inactive" viewBox="none" />
      </svg>
    ) : (
      <svg className={classes.svg} viewBox="0 0 1 1">
        <SvgIcon iconName="circle" viewBox="none" />
        <SvgIcon iconName="settings" color="sideBar" viewBox="none" />
      </svg>
    );

    return (
      <div
        className={classNames(
          classes.root,
          session.fullScreenMode && classes.transparent
        )}
      >
        {displayContent && (
          <div>
            {window.CtxAppConfigurations.customer !== "TCAD" && (
              <>
                <IconButton
                  id="handleParticipantList"
                  className={classes.iconButton}
                  disabled={false}
                  onClick={() => this.handleParticipantList()}
                  title={
                    intl.formatMessage(getIntl("participantList")) +
                    TitleNewLine +
                    (participantListPopoutWindow.windowName
                      ? intl.formatMessage(getIntl("currentlyInPopoutWindow")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToFocusWindow"))
                      : internalDrawer === InternalDrawerState.PARTICIPANT_LIST
                      ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToCollapseDrawer"))
                      : intl.formatMessage(getIntl("currentlyInactive")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToShowInDrawer")))
                  }
                >
                  {participantsIconWithBadge}
                </IconButton>
                <IconButton
                  id="handleChat"
                  className={classes.iconButton}
                  disabled={false}
                  onClick={() => this.handleChat()}
                  title={
                    intl.formatMessage(getIntl("chat")) +
                    TitleNewLine +
                    (chatPopoutWindow.windowName
                      ? intl.formatMessage(getIntl("currentlyInPopoutWindow")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToFocusWindow"))
                      : internalDrawer === InternalDrawerState.CHAT
                      ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToCollapseDrawer"))
                      : intl.formatMessage(getIntl("currentlyInactive")) +
                        TitleNewLine +
                        intl.formatMessage(getIntl("clickToShowInDrawer")))
                  }
                >
                  {chatIconWithBadge}
                </IconButton>
              </>
            )}
            {isVideoEnabled(session.videoConfig) && (
              <IconButton
                id="handleVideo"
                className={classes.iconButton}
                disabled={holdState}
                onClick={() => this.handleVideo()}
                title={
                  intl.formatMessage(getIntl("video")) +
                  TitleNewLine +
                  (holdState
                    ? intl.formatMessage(getIntl("controlDisabled"))
                    : videoPopoutWindow.windowName
                    ? intl.formatMessage(getIntl("currentlyInPopoutWindow")) +
                      TitleNewLine +
                      intl.formatMessage(getIntl("clickToFocusWindow"))
                    : internalDrawer === InternalDrawerState.VIDEO
                    ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                      TitleNewLine +
                      intl.formatMessage(getIntl("clickToCollapseDrawer"))
                    : intl.formatMessage(getIntl("currentlyInactive")) +
                      TitleNewLine +
                      intl.formatMessage(getIntl("clickToShowInDrawer")))
                }
                style={holdState ? { pointerEvents: "auto" } : undefined}
              >
                {videoIcon}
              </IconButton>
            )}
            {false && (
              <IconButton
                id="handleSettings"
                className={classes.iconButton}
                disabled={false}
                onClick={() => this.handleSettings()}
                title={
                  intl.formatMessage(getIntl("settings")) +
                  TitleNewLine +
                  (internalDrawer === InternalDrawerState.SETTINGS
                    ? intl.formatMessage(getIntl("currentlyInDrawer")) +
                      TitleNewLine +
                      intl.formatMessage(getIntl("clickToCollapseDrawer"))
                    : intl.formatMessage(getIntl("currentlyInactive")) +
                      TitleNewLine +
                      intl.formatMessage(getIntl("clickToShowInDrawer")))
                }
              >
                {settingsIcon}
              </IconButton>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  internalDrawer,
  participantListPopoutWindow,
  chatPopoutWindow,
  videoPopoutWindow,
  participants,
  unreadChats,
  conference,
  session
}) => ({
  internalDrawer,
  participantListPopoutWindow,
  chatPopoutWindow,
  videoPopoutWindow,
  participants,
  unreadChats,
  conference,
  session
});

const mapDispatchToProps = dispatch => ({
  hideInternalDrawer: () => dispatch(hideInternalDrawer()),
  displayParticipantListInternalDrawer: () =>
    dispatch(displayParticipantListInternalDrawer()),
  displayChatInternalDrawer: () => dispatch(displayChatInternalDrawer()),
  displayVideoInternalDrawer: () => dispatch(displayVideoInternalDrawer()),
  displaySettingsInternalDrawer: () =>
    dispatch(displaySettingsInternalDrawer()),
  setMainRoom: () => dispatch(setMainRoom()),
  resetMainRoom: () => dispatch(resetMainRoom())
});

export default withTheme(
  withStyles(styles)(
    injectIntl(connect(mapStateToProps, mapDispatchToProps)(LeftBar))
  )
);
