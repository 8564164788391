import {
  USER_LOGIN,
  AUTHENTICATE_USER,
  USERS_UPDATE,
  USER_LOGOUT,
  USER_LOGIN_FAIL,
  AUTHENTICATE_USER_FAIL,
  CLEAR_LOGIN_ERROR,
  SET_USER_ID,
  CONFERENCE_UPDATE,
  DATA_CONF_ID_UPDATE,
  CALL_STATE_UPDATE,
  SET_TIMEOUT,
  PARTICIPANTS_BUFFERED_UPDATE,
  STOP_SHARING,
  CONFERENCE_STATE_UPDATE,
  UPDATE_WEBRTC_CALL_STATE,
  HIDE_SETTINGS_MENU,
  SHOW_SETTINGS_MENU,
  SET_AUDIO_INPUT,
  SET_AUDIO_OUTPUT,
  SET_PERSISTENT_SPACE_AND_CONFIGS,
  LOGIN_PORTAL_USER,
  LOGIN_PORTAL_USER_FAIL,
  AUTHENTICATE_PORTAL_USER,
  AUTHENTICATE_PORTAL_USER_FAIL,
  LOGOUT_PORTAL_USER,
  UPDATE_PORTAL_USER,
  SHAKE_CONF_SECURE_ICON,
  PARTICIPANTS_UPDATE,
  SET_TALKER_INDICATOR_OPTION,
  SET_TALKER_INDICATOR_MAX_COUNT,
  SET_IS_LOGIN_FORM_SUBMITTING,
  SET_SHARING_USER_ID,
  SET_MAIN_ROOM,
  RESET_MAIN_ROOM,
  SET_VIDEO_INPUT,
  SET_BLUR,
  SET_WEBCAM_ON,
  SET_WEBCAM_OFF,
  SET_AUDIO_ON,
  SET_AUDIO_OFF,
  SET_CHANGING_WEBCAM,
  SET_UPDATING_VIDEO_INPUT,
  SET_WEBCAM_BUTTON_ENABLED,
  SET_FULL_SCREEN_MODE,
  SET_HEADER_BARS_VISIBILITY,
  SET_HAMBURG_MENU_STATE,
  SET_LOGOUT_MODAL_STATE,
  SET_HAS_HOST_JOINED
} from "../actions/types";
import { UPDATE_PORTAL_USER_PERSISTENT_SPACES } from "../components/portalComponents/actions";
import { handleLoginError, handlePortalLoginError } from "../errors";

const initialState = {
  propertyID: null,
  agentID: null,
  pin: null,
  userRole: null,
  isLoggedIn: undefined,
  isPortalUserLoggedIn: null,
  confActive: false,
  recordingActive: false,
  sharingActive: false,
  isSharer: false,
  guid: undefined,
  audioInput: "",
  audioOutput: "",
  videoInput: "default",
  blur: localStorage.getItem("backgroundBlur")
    ? parseInt(localStorage.getItem("backgroundBlur"))
    : 0,
  persistentSpaceName: null,
  callState: "NotConnected",
  portalSessionId: null,
  portalUser: null,
  isWebRTCCall: false,
  webRTCCallState: "disconnected",
  mergedCallState: "disconnected",
  shakeSecureIcon: false,
  callMeConfig: false,
  webRTCCallConfig: true,
  videoConfig: true,
  pinRequired: false,
  vettingConfig: false,
  talkerPartySet: new Set(),
  talkerIndicatorOption: 2,
  talkerIndicatorMaxCount: 2,
  isLoginFormSubmitting: false,
  numberOfCamsPublished: 0,
  isMainRoom: false,
  webcamEnabled: true,
  // Whether to automatically connect the WebRTC audio call
  audioEnabled: true,
  changingWebcam: false,
  updatingVideoInput: false,
  webcamButtonEnabled: true,
  fullScreenMode: false,
  isHeaderBarsVisible: true,
  isHamburgMenuOpen: false,
  isLogOutModalOpen: false,
  hasHostJoined: false,
  operatorSignaled: false,
  vetted: false,
  recorder: false
};

export default function (
  state = initialState,
  participantsState,
  newParticipantsState,
  action
) {
  let newState = {};
  switch (action.type) {
    case USER_LOGIN:
    case AUTHENTICATE_USER:
      newState = { ...state };
      if (action.payload.userId !== undefined) {
        if (action.payload.userId !== null) {
          newState.id = action.payload.id;
          newState.userId = action.payload.userId;
          newState.userLevel = action.payload.userLevel;
          newState.confName = action.payload.confName;
          newState.propertyID = action.payload.propertyID;
          newState.agentID = action.payload.agentID;
          if (newState.propertyID != null) {
            newState.userRole = "propertyOwner";
          } else if (newState.agentID != null) {
            newState.userRole = "agent";
          }
          newState.pin = action.payload.pin;
          newState.isLoggedIn = true;
          newState.error = undefined;
          newState.sharingActive = false;
          newState.isSharer = false;
          newState.recordingActive = false;
          newState.talkerPartySet = new Set();
          newState.talkerIndicatorOption = 2;
          newState.talkerIndicatorMaxCount = 2;
          newState.isLoginFormSubmitting = false;
          newState.isMainRoom = true;
          if (action.type === AUTHENTICATE_USER) {
            // The audioEnabled setting should only be automatically enabled on
            // the login page.
            newState.audioEnabled = false;
          }
          newState.changingWebcam = false;
          newState.webcamButtonEnabled = true;
          newState.fullScreenMode = false;
          newState.isHeaderBarsVisible = true;
          newState.isHamburgMenuOpen = false;
          newState.isLogOutModalOpen = false;
          newState.vetted = action.payload.vetted;
          // Reset webRTCCallConfig to false during login so that the call does
          // not automatically connect when logging in from the generic login
          // page into a conference that has Connect with Device disabled.
          newState.webRTCCallConfig = false;
          newState.videoConfig = false;
        } else {
          newState.isLoggedIn = false;
        }
      }
      return newState;
    case USER_LOGIN_FAIL:
    case AUTHENTICATE_USER_FAIL:
      newState = { ...state };
      newState.isLoggedIn = false;
      newState.error = handleLoginError(action.payload.error);
      return newState;
    case CLEAR_LOGIN_ERROR:
      newState = { ...state };
      newState.error = null;
      return newState;
    case USER_LOGOUT:
      newState = { ...state };
      if (newState.isLoggedIn) {
        newState = { ...action.payload };
        newState.propertyID = null;
        newState.agentID = null;
        newState.pin = null;
        newState.userRole = null;
        newState.isLoggedIn = false;
        newState.sharingActive = false;
        newState.recordingActive = false;
        newState.guid = undefined;
        newState.numberOfCamsPublished = 0;
        newState.persistentSpaceName = null;
        newState.callState = "NotConnected";
        newState.webRTCCallState = "disconnected";
        newState.mergedCallState = "disconnected";
        newState.talkerPartySet = new Set();
        newState.isMainRoom = false;
        newState.audioInput = state.audioInput;
        newState.audioOutput = state.audioOutput;
        newState.videoInput = state.videoInput;
        newState.blur = state.blur;
        newState.talkerIndicatorOption = 2;
        newState.talkerIndicatorMaxCount = 2;
        newState.webcamEnabled = true;
        newState.audioEnabled = true;
        newState.changingWebcam = false;
        newState.updatingVideoInput = false;
        newState.webcamButtonEnabled = true;
        newState.fullScreenMode = false;
        newState.isHeaderBarsVisible = true;
        newState.isHamburgMenuOpen = false;
        newState.isLogOutModalOpen = false;
        newState.hasHostJoined = false;
        newState.vetted = false;
        newState.webRTCCallConfig = true;
        newState.videoConfig = true;
        newState.pinRequired = false;
        newState.vettingConfig = false;
      }
      return newState;
    case SET_USER_ID:
      newState = { ...state };
      newState.id = action.payload;
      return newState;
    case CONFERENCE_UPDATE:
      if (
        action.payload.active !== undefined &&
        state.confActive !== action.payload.active
      ) {
        newState = { ...state, confActive: action.payload.active };
        if (!action.payload.active) {
          newState.dataConfID = null;
        }
        return newState;
      } else {
        return state;
      }
    case DATA_CONF_ID_UPDATE:
      newState = { ...state };
      newState.dataConfID = action.payload;
      return newState;
    case CALL_STATE_UPDATE:
      newState = { ...state };
      if (action.payload === "Connecting") {
        newState.callState = action.payload;
        newState.callPending = true;
      } else if (action.payload === "NotConnected") {
        if (state.callPending) {
          newState.callState = "NotConnected";
          newState.callPending = false;
        }
      }
      newState.mergedCallState = mergeCallState(
        newState.callState,
        newState.webRTCCallState
      );
      return newState;
    case SET_TIMEOUT:
      newState = { ...state };
      newState.timeout = action.payload;
      return newState;
    case PARTICIPANTS_BUFFERED_UPDATE:
    case USERS_UPDATE:
      newState = { ...state };

      if (action.type === USERS_UPDATE) {
        if (action.payload.id === state.id) {
          newState.username = action.payload.username;
          if (newState.username === "Recorder") {
            newState.recorder = true;
            newState.fullScreenMode = true;
          }
          newState.userDefined = action.payload.userDefined;
          newState.userDefined2 = action.payload.userDefined2;
          newState.userDefined3 = action.payload.userDefined3;
          newState.userDefined4 = action.payload.userDefined4;
          newState.partyID = action.payload.partyID;
          newState.vetted = action.payload.vetted;
        }
      }

      let oldCallState;
      for (let mergedParty of participantsState.merged) {
        if (state.id === mergedParty.id) {
          oldCallState = mergedParty.connectState;
          break;
        }
      }
      let newCallState;
      for (let mergedParty of newParticipantsState.merged) {
        if (state.id === mergedParty.id) {
          newCallState = mergedParty.connectState;
          newState.operatorSignaled = mergedParty.operatorSignal;
          break;
        }
      }
      if (newCallState !== oldCallState) {
        newState.callState = newCallState;
        newState.callPending = false;
        newState.mergedCallState = mergeCallState(
          newState.callState,
          newState.webRTCCallState
        );
      }
      return newState;
    case STOP_SHARING:
      newState = { ...state };
      newState.guid = action.payload.data.guid;
      newState.recordingActive = action.payload.data.recordingActive;
      newState.sharingActive = false;
      newState.isSharer = false;
      newState.isMainRoom = true;
      newState.numberOfCamsPublished =
        action.payload.data.numberOfCamsPublished;
      return newState;
    case CONFERENCE_STATE_UPDATE:
      newState = { ...state };
      newState.guid = action.payload.data.guid;
      newState.sharingActive = action.payload.data.sharingActive;
      newState.isMainRoom = !action.payload.data.sharingActive;
      newState.recordingActive = action.payload.data.recordingActive;
      newState.isSharer = action.payload.isHostView;
      newState.numberOfCamsPublished =
        action.payload.data.numberOfCamsPublished;
      newState.callMeConfig = action.payload.data.callMeConfig;
      newState.webRTCCallConfig = action.payload.data.webRTCCallConfig;
      newState.videoConfig = action.payload.data.videoConfig;
      newState.vettingConfig = action.payload.data.vettingConfig;
      newState.persistentSpaceName = action.payload.data.persistentSpaceName;
      newState.confName = action.payload.data.confName;
      return newState;
    case UPDATE_WEBRTC_CALL_STATE:
      newState = { ...state };
      newState.webRTCCallState = action.payload;
      newState.isWebRTCCall = action.payload !== "disconnected";
      newState.mergedCallState = mergeCallState(
        newState.callState,
        newState.webRTCCallState
      );
      return newState;
    case HIDE_SETTINGS_MENU:
      newState = { ...state };
      newState.settingsMenuState = 0;
      return newState;
    case SHOW_SETTINGS_MENU:
      newState = { ...state };
      newState.settingsMenuState = 1;
      return newState;
    case SET_AUDIO_INPUT:
      newState = { ...state };
      newState.audioInput = action.payload;
      return newState;
    case SET_AUDIO_OUTPUT:
      newState = { ...state };
      newState.audioOutput = action.payload;
      return newState;
    case SET_PERSISTENT_SPACE_AND_CONFIGS:
      newState = { ...state };
      newState.persistentSpaceName = action.payload.name;
      newState.webRTCCallConfig = action.payload.webRTCCallConfig;
      newState.videoConfig = action.payload.videoConfig;
      newState.pinRequired = action.payload.pinRequired;
      return newState;
    case LOGIN_PORTAL_USER:
    case AUTHENTICATE_PORTAL_USER:
      newState = { ...state };
      newState.isPortalUserLoggedIn = true;
      newState.portalSessionId = action.payload.userID;
      newState.portalError = undefined;
      return newState;
    case LOGIN_PORTAL_USER_FAIL:
    case AUTHENTICATE_PORTAL_USER_FAIL:
      newState = { ...state };
      newState.isPortalUserLoggedIn = false;
      newState.portalError = handlePortalLoginError(action.payload.error);
      newState.portalUser = null;
      return newState;
    case UPDATE_PORTAL_USER:
      newState = { ...state };
      newState.portalUser = { ...newState.portalUser, ...action.payload };
      return newState;
    case UPDATE_PORTAL_USER_PERSISTENT_SPACES:
      newState = { ...state };
      newState.portalUser = { ...newState.portalUser };
      newState.portalUser.persistentSpaces = {};
      if (action.payload != null) {
        for (let [key, value] of Object.entries(action.payload)) {
          newState.portalUser.persistentSpaces.persistentSpaceName = key;
          for (let settingName in value) {
            newState.portalUser.persistentSpaces[settingName] =
              value[settingName];
          }
        }
      }
      return newState;
    case LOGOUT_PORTAL_USER:
      newState = { ...state };
      newState.isPortalUserLoggedIn = false;
      newState.portalSessionId = null;
      newState.portalUser = null;
      return newState;
    case SHAKE_CONF_SECURE_ICON:
      newState = { ...state };
      newState.shakeSecureIcon = action.payload;
      return newState;
    case PARTICIPANTS_UPDATE:
      newState = { ...state };
      if (action.payload && action.payload.id) {
        if (action.payload.talking === true) {
          newState.talkerPartySet.add(action.payload.id);
        } else if (
          action.payload.talking === false &&
          newState.talkerPartySet.has(action.payload.id)
        ) {
          newState.talkerPartySet.delete(action.payload.id);
        }
      }
      return newState;
    case SET_TALKER_INDICATOR_OPTION:
      newState = { ...state };
      newState.talkerIndicatorOption = action.payload;
      return newState;
    case SET_TALKER_INDICATOR_MAX_COUNT:
      newState = { ...state };
      newState.talkerIndicatorMaxCount = action.payload;
      return newState;
    case SET_IS_LOGIN_FORM_SUBMITTING:
      newState = { ...state };
      newState.isLoginFormSubmitting = action.payload;
      return newState;
    case SET_MAIN_ROOM:
      newState = { ...state };
      newState.isMainRoom = true;
      return newState;
    case RESET_MAIN_ROOM:
      newState = { ...state };
      newState.isMainRoom = false;
      return newState;
    case SET_SHARING_USER_ID:
      newState = { ...state };
      newState.sharingUserId = action.payload;
      return newState;
    case SET_VIDEO_INPUT:
      newState = { ...state };
      newState.videoInput = action.payload;
      return newState;
    case SET_BLUR:
      newState = { ...state };
      newState.blur = action.payload;
      return newState;
    case SET_WEBCAM_ON:
      newState = { ...state };
      newState.webcamEnabled = true;
      return newState;
    case SET_WEBCAM_OFF:
      newState = { ...state };
      newState.webcamEnabled = false;
      return newState;
    case SET_AUDIO_ON:
      newState = { ...state };
      newState.audioEnabled = true;
      return newState;
    case SET_AUDIO_OFF:
      newState = { ...state };
      newState.audioEnabled = false;
      return newState;
    case SET_CHANGING_WEBCAM:
      newState = { ...state };
      newState.changingWebcam = action.payload;
      return newState;
    case SET_UPDATING_VIDEO_INPUT:
      newState = { ...state };
      newState.updatingVideoInput = action.payload;
      return newState;
    case SET_WEBCAM_BUTTON_ENABLED:
      newState = { ...state };
      newState.webcamButtonEnabled = action.payload;
      return newState;
    case SET_FULL_SCREEN_MODE:
      newState = { ...state };
      newState.fullScreenMode = action.payload;
      return newState;
    case SET_HEADER_BARS_VISIBILITY:
      newState = { ...state };
      newState.isHeaderBarsVisible = action.payload;
      return newState;
    case SET_HAMBURG_MENU_STATE:
      newState = { ...state };
      newState.isHamburgMenuOpen = action.payload;
      return newState;
    case SET_LOGOUT_MODAL_STATE:
      newState = { ...state };
      newState.isLogOutModalOpen = action.payload;
      return newState;
    case SET_HAS_HOST_JOINED:
      newState = { ...state };
      newState.hasHostJoined = true;
      return newState;
    default:
      return state;
  }
}

function mergeCallState(callState, webRTCCallState) {
  if (
    webRTCCallState === "connected" ||
    (callState !== "NotConnected" &&
      callState !== "Disconnecting" &&
      callState !== "Connecting" &&
      callState !== "Removed")
  ) {
    return "connected";
  } else if (callState === "Connecting" || webRTCCallState === "connecting") {
    return "connecting";
  } else {
    return "disconnected";
  }
}
