import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { WaitingRoomGreeting } from "../greetings";
import { Header, RightBar, LeftBar, MainContent } from "../layouts";
import { authenticateUser } from "../../actions";
import {
  getUrlParam,
  redirectUnsupportedBrowsers,
  isVideoEnabled,
  modifyFilter,
  resetPreviewStream
} from "../../utils";
import * as cookiesManager from "../mediasoup/cookiesManager";
import { setVideoInput, setWebCamOn, setWebCamOff } from "../../actions";
import Logger from "../../Logger";
import { withRoomContext } from "../mediasoup/RoomContext";

const logger = new Logger("WaitingRoomPage");

const styles = theme => ({
  ...theme.style.basePageLayout,
  [theme.breakpoints.down("sm")]: {
    ...theme.style.mobileBasePageLayout
  },
  videoContainer: {
    position: "absolute",
    right: "70px",
    top: "70px",
    [theme.breakpoints.down("sm")]: {
      right: "5px"
    }
  },
  video: {
    width: "320px",
    height: "180px",
    borderColor: theme.colors.primaryMainColor,
    borderStyle: "solid"
  }
});

class WaitingRoomPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    redirectUnsupportedBrowsers();

    this.state = {
      videoInputDeviceId:
        cookiesManager.getVideoInputDeviceId() != null &&
        cookiesManager.getVideoInputDeviceId().videoInputDeviceId !== undefined
          ? cookiesManager.getVideoInputDeviceId().videoInputDeviceId
          : this.props.session.videoInput,
      webcamEnabled:
        cookiesManager.getDevices() != null
          ? cookiesManager.getDevices().webcamEnabled
          : this.props.session.webcamEnabled
    };
  }

  authenticate = (session, history) => {
    const u = getUrlParam("vi");
    const isLoggedIn = session.isLoggedIn;

    switch (isLoggedIn) {
      case false:
        console.log(
          "WaitingRoomPage not logged in - userId: " + session.userId,
          u
        );
        history.replace("login");
        break;
      case true:
        console.log("WaitingRoomPage userId: " + session.userId);
        break;
      case undefined:
      default:
        console.log(
          "WaitingRoomPage login undefined - userId: " + session.userId,
          u
        );
        this.props.authenticateUser(u);
        break;
    }
  };

  async componentDidMount() {
    const { session, history } = this.props;
    this._isMounted = true;

    if (this._isMounted) {
      this.authenticate(session, history);
      if (isVideoEnabled(session.videoConfig)) {
        try {
          let stream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: session.videoInput,
              width: 1280,
              height: 720
            }
          });
          stream.getTracks().forEach(track => {
            track.stop();
          });
        } catch (error) {
          logger.error("Error: %o", error);
        }

        await this.getDevices();

        if (this.state.webcamEnabled) {
          this.setPreviewStream(this.state.videoInputDeviceId);
          this.props.setWebCamOn();
        } else {
          cookiesManager.setDevices({ webcamEnabled: false });
          resetPreviewStream(this, "videoElement");
          this.props.setWebCamOff();
        }
      }
    }
  }

  getDevices = () => {
    this.enumeratorPromise = navigator.mediaDevices.enumerateDevices();
    this.enumeratorPromise
      .then(this.listVideoInputDevices)
      .catch(this.errorCallbackOnListDevices);
  };

  listVideoInputDevices = devices => {
    let videoInputDevices = [];
    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];
      if (device.kind === "videoinput") {
        videoInputDevices.push(device);
      }
    }

    let newDeviceId = this.state.videoInputDeviceId;
    let propsDeviceId = this.props.session.videoInput;
    if (propsDeviceId === "default" || propsDeviceId === "") {
      if (
        cookiesManager.getVideoInputDeviceId() == null ||
        cookiesManager.getVideoInputDeviceId().videoInputDeviceId ===
          "default" ||
        cookiesManager.getVideoInputDeviceId().videoInputDeviceId === ""
      ) {
        newDeviceId = videoInputDevices[0].deviceId;
        logger.debug("Updating cookie & session videoInput: %s", newDeviceId);
        this.props.setVideoInput(newDeviceId);
        cookiesManager.setVideoInputDeviceId({
          videoInputDeviceId: newDeviceId
        });
        this.setState({
          videoInputDeviceId: newDeviceId
        });
      } else {
        newDeviceId = cookiesManager.getVideoInputDeviceId().videoInputDeviceId;
        logger.debug("Updating session videoInput with: %s", newDeviceId);
        this.props.setVideoInput(newDeviceId);
        this.setState({
          videoInputDeviceId: newDeviceId
        });
      }
    }
  };

  errorCallbackOnListDevices = error => {
    logger.error("Error: %o", error);
    cookiesManager.setVideoInputDeviceId({ videoInputDeviceId: "default" });
  };

  adjustUrl = () => {
    const { session, history } = this.props;
    if (session.userId !== undefined && session.vetted) {
      history.push("/main?vi=" + session.userId);
    }
  };

  componentDidUpdate(prevProps) {
    const { session, history } = this.props;

    if (prevProps.session.isLoggedIn !== session.isLoggedIn) {
      this.authenticate(session, history);
    }

    if (
      prevProps.session.vetted !== this.props.session.vetted &&
      this.props.session.vetted
    ) {
      this.adjustUrl();
    }

    if (this._isMounted) {
      const { webcamEnabled, videoInput, videoConfig, blur } = session;

      if (
        prevProps.session.videoInput !== videoInput ||
        prevProps.session.webcamEnabled !== webcamEnabled ||
        isVideoEnabled(prevProps.session.videoConfig) !==
          isVideoEnabled(videoConfig)
      ) {
        if (webcamEnabled && isVideoEnabled(videoConfig)) {
          this.setPreviewStream(videoInput);
        } else {
          resetPreviewStream(this, "videoElement");
        }
      } else if (
        prevProps.session.blur !== blur &&
        webcamEnabled &&
        isVideoEnabled(videoConfig)
      ) {
        modifyFilter(this, blur, "videoElement");
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    resetPreviewStream(this, "videoElement");
  }

  setPreviewStream = deviceId => {
    logger.debug("setPreviewStream with deviceId: %s", deviceId);
    if (deviceId !== "default" && deviceId !== "") {
      var videoElement = document.getElementById("videoElement");
      if (videoElement) {
        if (this.previewPending) {
          return;
        } else {
          this.previewPending = true;
        }

        if (this.stream) {
          // Stop the unfiltered track
          this.stream.getVideoTracks().forEach(track => {
            track.stop();
          });
          this.stream = null;
        }

        navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: { exact: deviceId },
              width: 1280,
              height: 720
            }
          })
          .then(stream => {
            if (this._isMounted) {
              this.stream = stream;
              modifyFilter(this, this.props.session.blur, "videoElement");
            }
            this.previewPending = false;
          })
          .catch(error => {
            logger.error("Error: %o", error);
            this.previewPending = false;
          });
      }
    }
  };

  render() {
    const { classes, session } = this.props;
    const vetted = session.vetted;

    const component = (
      <Fragment>
        <div className={classes.videoContainer}>
          <video
            id="videoElement"
            poster={"/assets/videoMutePreview.png"}
            autoPlay
            className={classes.video}
          />
        </div>
        <WaitingRoomGreeting />
      </Fragment>
    );

    console.log("session.isLoggedIn: ", session.isLoggedIn);
    console.log("vetted: ", vetted);
    return (
      <Fragment>
        <CssBaseline />
        <Header displayLogout />
        <div className={classes.root} id="mainContent">
          <div className={classes.headerSpacer} />
          <LeftBar displayContent={vetted} />
          <MainContent component={component} />
          <RightBar displayContent={vetted} displayVideoPopper />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

const mapDispatchToProps = dispatch => ({
  authenticateUser: u => dispatch(authenticateUser(u)).then(),
  setVideoInput: videoInput => dispatch(setVideoInput(videoInput)),
  setWebCamOn: () => dispatch(setWebCamOn()),
  setWebCamOff: () => dispatch(setWebCamOff())
});

export default withStyles(styles)(
  withRouter(
    withRoomContext(
      connect(mapStateToProps, mapDispatchToProps)(WaitingRoomPage)
    )
  )
);
