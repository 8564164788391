import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import PortalHeader from "./PortalHeader";
import LocalizedText from "../reusable/LocalizedText";
import { Tabs, Tab } from "@material-ui/core";
import {
  selectPortalSettingTabView,
  getPortalUserAudioConferenceDetails
} from "./actions";
import ProfileView from "./ProfileView";
import ThemeView from "./ThemeView";
import ConferenceView from "./ConferenceView";
import PersistentSpaceSettingsView from "./PersistentSpaceSettingsView";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  header: {
    height: "50px",
    flexDirection: "row",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 25px 0px 15px"
  },
  mainContentTopContainer: {
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  bannerContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    justifyContent: "center",
    backgroundColor: theme.colors.primaryMainColor,
    color: theme.colors.primaryMainTextColor
  }
});

class PortalSettings extends Component {
  componentDidMount() {
    const { session } = this.props;
    this.props.getPortalUserAudioConferenceDetails(session.portalSessionId);
  }

  getTabControls = () => {
    const { portal } = this.props;
    const { profileSettings } = portal;
    const selectedMainTabIndex = profileSettings.mainTabIndex;

    return (
      <Tabs
        value={selectedMainTabIndex}
        onChange={this.handleMainTabSelectChange}
        textColor="inherit"
        indicatorColor="primary"
      >
        <Tab label={<LocalizedText value="profile" variant="caption" />} />
        <Tab label={<LocalizedText value="conference" variant="caption" />} />
        <Tab label={<LocalizedText value="theme" variant="caption" />} />
        <Tab label={<LocalizedText value="settings" variant="caption" />} />
      </Tabs>
    );
  };

  getMainView = () => {
    const { portal } = this.props;
    const { profileSettings, audioConferenceDetails } = portal;
    const selectedMainTabIndex = profileSettings.mainTabIndex;
    let res = undefined;

    if (selectedMainTabIndex === 0) {
      res = <ProfileView />;
    } else if (selectedMainTabIndex === 1) {
      res = <ConferenceView audioConferenceDetails={audioConferenceDetails} />;
    } else if (selectedMainTabIndex === 2) {
      res = <ThemeView />;
    } else if (selectedMainTabIndex === 3) {
      res = <PersistentSpaceSettingsView />;
    }

    return res;
  };

  //Banner control
  getBannerControl = () => {
    const { classes } = this.props;

    return (
      <div className={classes.bannerContainer}>
        <LocalizedText value="betaReleaseBannerText" variant="body2" />
      </div>
    );
  };

  //functions
  handleMainTabSelectChange = (event, tabIndex) => {
    this.props.selectPortalSettingTabView(tabIndex);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <PortalHeader title={"profileAndSettings"} />
        </div>
        <div
          className={classNames(
            classes.mainContentContainer,
            classes.scrollBar
          )}
        >
          {this.getBannerControl()}
          <div className={classes.mainContentTopContainer}>
            {this.getTabControls()}
          </div>
          {this.getMainView()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({
  selectPortalSettingTabView: index =>
    dispatch(selectPortalSettingTabView(index)),
  getPortalUserAudioConferenceDetails: portalSessionId =>
    dispatch(getPortalUserAudioConferenceDetails(portalSessionId))
});

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PortalSettings)
  )
);
