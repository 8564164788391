import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import LocalizedText from "../reusable/LocalizedText";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "../Icons/SvgIcon";
import Input from "@material-ui/core/Input";
import InputBase from "@material-ui/core/InputBase";
import Textarea from "react-textarea-autosize";
import Badge from "@material-ui/core/Badge";
import { injectIntl } from "react-intl";
import { getIntl, isMobileOrTablet } from "../../utils";
import {
  hideInternalDrawer,
  updateChatReadState,
  updateSelectedUserIdForChatCount,
  updateCurrentSelectedUser,
  updateUserPriority
} from "../../actions";
import { showChatPopout, hideChatPopout, ChatPopoutState } from "./";
import { chatPopoutWindowName } from "./PopoutWindow/component";
import contexWebRest from "../../api/contexWebRest";
import classNames from "classnames";
import { ChatFooter } from "./";
import { Backdrop } from "@material-ui/core";
import { isSafari } from "react-device-detect";

const componentMinWidthPx = 375;
const headerHeight = "32px";

const styles = theme => ({
  root: {
    height: "100%",
    minWidth: componentMinWidthPx
  },
  paper: {
    backgroundColor: theme.colors.popoverBackgroundColor,
    color: theme.colors.primaryTextColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px"
  },
  selectContainer: {
    width: "100%",
    borderRadius: "10px",
    backgroundColor: theme.colors.primaryBackgroundColor,
    color: theme.colors.primaryTextColor,
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  list: {},
  menuItem: {
    display: "flex",
    color: theme.colors.primaryTextColor,
    paddingRight: "20px"
  },
  menuItemGroup: {
    overflowY: "auto",
    border: `3px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "5px",
    paddingLeft: "0px",
    paddingRight: "0px",
    width: "310px",
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  menuItemSelected: {
    display: "none"
  },
  menuItemName: {
    color: theme.colors.primaryTextColor,
    flexGrow: 0.7,
    flexBasis: 0,
    paddingRight: "10px"
  },
  menuItemCount: {
    color: theme.colors.primaryTextColor,
    textAlign: "right",
    flexGrow: 0.15,
    flexBasis: 0
  },
  menuItemIcon: {
    height: "1.75em",
    padding: "3px",
    flexGrow: 0.15,
    flexBasis: 0
  },
  selectIcon: {
    top: "calc(29% - 12px)",
    color: theme.colors.primaryTextColor,
    fontSize: "2.25em"
  },
  card: {
    padding: "5px 10px 5px 10px",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  receiverCard: {
    paddingRight: "20%"
  },
  senderCard: {
    paddingLeft: "20%"
  },
  cardContent: {
    borderRadius: "10px",
    padding: "12px",
    "&:last-child": {
      paddingBottom: "12px"
    }
  },
  receiverCardContent: {
    backgroundColor: theme.colors.primaryBackgroundColor
  },
  senderCardContent: {
    backgroundColor: theme.colors.primaryMainColor
  },
  receiverCardMsg: {
    color: theme.colors.primaryTextColor
  },
  senderCardMsg: {
    color: theme.colors.primaryMainTextColor
  },
  cardTimestamp: {
    fontSize: "0.75em"
  },
  drawerHeader: {
    width: "100%",
    textAlign: "right",
    height: headerHeight,
    padding: "3px 3px 3px 0px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "8px 8px 8px 8px"
  },
  closeHeaderIcon: {
    width: "0.7em"
  },
  header: {
    width: "100%",
    textAlign: "left",
    padding: "16px 40px 8px 20px",
    height: headerHeight,
    lineHeight: "25px",
    fontSize: "1em",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  headerLabel: {
    order: 1
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  menuSeparator: {
    width: "75%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`,
    pointerEvents: "none",
    marginLeft: "10px"
  },
  chatSection: {
    display: "flex",
    flex: "1 1",
    minHeight: "0px",
    height: "0px",
    flexDirection: "column",
    width: "100%",
    padding: "8px 20px 0px 20px"
  },
  chatContent: {
    padding: "8px",
    height: "85%",
    overflowY: "auto"
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  msgBox: {
    border: `2px solid ${theme.colors.secondaryMainColor}`,
    borderRadius: "10px",
    color: theme.colors.primaryTextColor
  },
  msgBoxSelect: {
    marginBottom: "6px"
  },
  badgeRoot: {
    width: "100%",
    order: 2,
    display: "inline",
    paddingLeft: "20px"
  },
  badgeColor: {
    backgroundColor: theme.colors.disconnectIconColor
  }
});

class Chat extends Component {
  constructor(props) {
    super(props);
    this.moment = require("moment-timezone");
    this.timezone = this.moment.tz.guess();
    this.userIDNameMap = new Map();
    this.chatCountMap = new Map();
    this.readChatIds = [];
    this.senderId = 0;
    this.updateUserIDNameMap(this.props.users, false);
    this.updateChatCountMap(this.props.chats);
    this.state = {
      open: false,
      toUserId: this.props.userPriority.currentSelectedUserId,
      toUserName: this.props.userPriority.currentSelectedUserName
    };
  }

  componentDidMount() {
    const toUserId = this.state.toUserId;
    this.scrollToBottom();
    this.props.updateSelectedUserIdForChatCount(toUserId);
    this.updateChatsReadStateForSelectedUser(toUserId);

    if (this.props.session.sharingActive) {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const open = this.state.open;
    if (nextProps.users !== this.props.users) {
      this.updateUserIDNameMap(nextProps.users, open);
    }
    if (nextProps.chats !== this.props.chats) {
      this.updateChatCountMap(nextProps.chats);
    }
    if (
      nextProps.userPriority.currentSelectedUserId !==
      this.props.userPriority.currentSelectedUserId
    ) {
      this.setState({
        toUserId: nextProps.userPriority.currentSelectedUserId,
        toUserName: nextProps.userPriority.currentSelectedUserName
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.props !== this.props) {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    this.props.updateSelectedUserIdForChatCount(-1);

    if (this.props.session.sharingActive) {
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    }
  }

  updateUserIDNameMap = (users, open) => {
    let myUserId = this.props.session.id;

    //Add 'Everyone' with id=0 to the user map
    this.userIDNameMap.set(0, this.props.userPriority.groupUserName);

    Object.entries(users).forEach(user => {
      if (user[1] !== undefined && user[1].connected) {
        //Include all users but this session's user
        if (user[1].id !== myUserId) {
          this.userIDNameMap.set(user[1].id, user[1].username);
        }
        //Set the senderId for current user; check just once when this.senderId has not been set yet
        else if (this.senderId === 0) {
          this.senderId = user[1].id;
        }
      } else if (
        user[1] === undefined &&
        this.userIDNameMap.has(Number(user[0]))
      ) {
        //remove the logged out user from the list
        this.userIDNameMap.delete(Number(user[0]));
      }
    });

    if (this.userIDNameMap.size <= 1 && open) {
      this.setState({
        open: false
      });
    }
  };

  updateChatCountMap = chats => {
    const unreadChatMap = this.props.unreadChats.unreadChatMap;
    let senderUnreadChatArray = [];
    let senderId;

    //Fill chatCountMap for each user's unread message
    Object.entries(chats).forEach(chat => {
      if (chat[1] !== undefined) {
        //Increase count only if the message hasn't been read yet
        if (!unreadChatMap[chat[1].id]) {
          //find senderId for the chat
          if (chat[1].receiverId === 0) {
            senderId = 0;
          } else if (chat[1].receiverId === this.senderId) {
            senderId = chat[1].senderId;
          } else {
            return;
          }

          //create an unread array of chatIds for all senders
          //required so that same chats are not being read more than once when chats props update
          senderUnreadChatArray =
            this.chatCountMap.get(senderId) === undefined
              ? []
              : (senderUnreadChatArray = this.chatCountMap.get(senderId));

          //add chatId to sender's unread array
          if (!senderUnreadChatArray.includes(chat[1].id)) {
            senderUnreadChatArray.push(chat[1].id);
          }
          this.chatCountMap.set(senderId, senderUnreadChatArray);
        }
      } else {
        let chatId = Number(chat[0]);
        if (!unreadChatMap[chatId]) {
          this.props.updateChatReadState(chatId, null, true);
        }
      }
    });
  };

  scrollToBottom = () => {
    this.chatEnd.scrollIntoView({ behavior: "auto", block: "end" });
  };

  getDrawerHeader = () => {
    const { isDrawer, classes, intl } = this.props;
    let content;
    if (isDrawer) {
      content = (
        <div className={classes.drawerHeader}>
          {!isSafari && this.getPopoutIcon()}
          <IconButton
            className={classes.closeHeaderButton}
            disabled={false}
            onClick={() => this.handleCloseWindow()}
            title={intl.formatMessage(getIntl("close"))}
          >
            <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
          </IconButton>
        </div>
      );
    } else {
      content = null;
    }
    return content;
  };

  handleCloseWindow = () => {
    const { isDrawer } = this.props;
    if (isDrawer) {
      this.props.hideInternalDrawer();
    } else {
      this.props.hideChatPopout();
    }
  };

  getPopoutIcon = () => {
    const { classes, isDrawer, intl } = this.props;

    return isDrawer ? (
      <IconButton
        className={classes.closeHeaderButton}
        onClick={() => this.handlePopoutClick()}
        title={intl.formatMessage(getIntl("popoutIntoNewWindow"))}
      >
        <SvgIcon
          iconName="popout"
          color={"active"}
          className={classes.closeHeaderIcon}
        />
      </IconButton>
    ) : null;
  };

  handlePopoutClick = () => {
    this.props.showChatPopout();
    this.props.hideInternalDrawer();
  };

  // Force Material-UI to load styles needed in new window
  getClassesForPopout = () => {
    return (
      <div style={{ display: "none" }}>
        <Select value="" />
        <List />
        <MenuItem />
        <Backdrop open={false} />
      </div>
    );
  };

  handleSelectChange = event => {
    const userId = event.target.value;
    const userName = this.userIDNameMap.get(userId);
    this.setState({
      toUserId: userId,
      toUserName: userName
    });
    this.props.updateCurrentSelectedUser(userId, userName);
    this.props.updateSelectedUserIdForChatCount(userId);
    this.updateChatsReadStateForSelectedUser(userId);
  };

  handleSelectClose = () => {
    this.setState({ open: false });
  };

  handleSelectOpen = () => {
    if (this.userIDNameMap.size > 1) {
      this.setState({ open: true });
    }
  };

  updateChatsReadStateForSelectedUser = toUserId => {
    const chats = this.props.chats;
    const unreadChatMap = this.props.unreadChats.unreadChatMap;

    Object.entries(chats).forEach(chat => {
      if (
        chat[1] !== undefined &&
        ((toUserId === 0 && chat[1].receiverId === 0) ||
          (chat[1].receiverId === this.senderId &&
            chat[1].senderId === toUserId))
      ) {
        if (!unreadChatMap[chat[1].id]) {
          this.readChatIds.push(chat[1].id);
        }
      }
    });

    this.readChatIds.forEach(chatId => {
      this.props.updateChatReadState(chatId, null, true);
    });
    this.readChatIds.length = 0;
  };

  getUserNames = (open, toUserId) => {
    let classes = this.props.classes;
    let userPriorityList = this.props.userPriority.priorityList;
    let menuItems = [];
    let msgCount = 0;
    let isSelectedUser = false;

    userPriorityList.forEach(userId => {
      let userName = this.userIDNameMap.get(userId);
      if (userName !== undefined) {
        isSelectedUser = userId === toUserId; //because usernames can't be unique
        msgCount = this.chatCountMap.has(userId)
          ? this.chatCountMap.get(userId).length
          : 0;
        menuItems.push(
          this.getMenuItem(userId, userName, msgCount, isSelectedUser, classes)
        );

        if (userId === 0 && !isSelectedUser && this.userIDNameMap.size > 2) {
          menuItems.push(
            <div key="menuSeparator" className={classes.menuSeparator} />
          );
        }
      }
    });

    for (let [userId, userName] of this.userIDNameMap) {
      if (!userPriorityList.includes(userId) && userName !== undefined) {
        isSelectedUser = userId === toUserId; //because usernames can't be unique
        msgCount = this.chatCountMap.has(userId)
          ? this.chatCountMap.get(userId).length
          : 0;
        menuItems.push(
          this.getMenuItem(userId, userName, msgCount, isSelectedUser, classes)
        );
      }
    }

    return (
      <Select
        open={open}
        value={toUserId}
        onClose={this.handleSelectClose}
        onOpen={this.handleSelectOpen}
        onChange={this.handleSelectChange}
        classes={{
          icon: classes.selectIcon,
          root: classes.selectContainer,
          select: classes.menuItem
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          classes: { paper: classes.menuItemGroup }
        }}
        input={
          <InputBase
            id="currentSelectedUserName"
            fullWidth={true}
            className={classNames(classes.msgBox, classes.msgBoxSelect)}
          />
        }
      >
        {menuItems}
      </Select>
    );
  };

  getMenuItem = (userId, userName, msgCount, isSelectedUser, classes) => {
    return isSelectedUser ? (
      <MenuItem
        key={userId}
        value={userId}
        classes={{ selected: classes.menuItemSelected }}
      >
        <Typography className={classes.menuItem} variant="subtitle1" noWrap>
          {userName}
        </Typography>
      </MenuItem>
    ) : msgCount <= 0 ? (
      <MenuItem
        key={userId}
        value={userId}
        classes={{ root: classes.menuItem }}
      >
        <Typography className={classes.menuItem} variant="subtitle1" noWrap>
          {userName}
        </Typography>
      </MenuItem>
    ) : (
      <MenuItem
        key={userId}
        value={userId}
        classes={{ root: classes.menuItem }}
      >
        <Typography className={classes.menuItemName} variant="subtitle1" noWrap>
          {userName}
        </Typography>
        <Typography className={classes.menuItemCount} variant="subtitle1">
          {msgCount}
        </Typography>
        <SvgIcon
          iconName="chat"
          color="active"
          className={classes.menuItemIcon}
        />
      </MenuItem>
    );
  };

  getChatBubbles = toUserId => {
    const noResizeStyle = {
      resize: "none",
      paddingTop: "0px",
      paddingBottom: "6px"
    };

    //let isNewMessageNotification = false;
    let chatCards = [];
    let chats = this.props.chats;
    let classes = this.props.classes;
    let cardKey, cardContentKey, cardMsgKey, cardMetaKey;
    let cardClasses, contentClasses, cardMsg, cardTimestamp;
    let senderCardClasses = [classes.card, classes.senderCard];
    let receiverCardClasses = [classes.card, classes.receiverCard];
    let senderContentClasses = [classes.cardContent, classes.senderCardContent];
    let receiverContentClasses = [
      classes.cardContent,
      classes.receiverCardContent
    ];

    Object.entries(chats).forEach(chat => {
      if (
        chat[1] !== undefined &&
        ((toUserId === 0 && chat[1].receiverId === 0) ||
          (chat[1].receiverId === toUserId &&
            chat[1].senderId === this.senderId) ||
          (chat[1].receiverId === this.senderId &&
            chat[1].senderId === toUserId))
      ) {
        cardKey = `cardKey${chat[1].id}`;
        cardContentKey = `contentKey${chat[1].id}`;
        cardMsgKey = `msgKey${chat[1].id}`;
        cardMetaKey = `metaKey${chat[1].id}`;

        if (chat[1].senderId === this.senderId) {
          cardClasses = senderCardClasses;
          contentClasses = senderContentClasses;
          cardMsg = classes.senderCardMsg;
        } else {
          cardClasses = receiverCardClasses;
          contentClasses = receiverContentClasses;
          cardMsg = classes.receiverCardMsg;
        }
        cardTimestamp = [cardMsg, classes.cardTimestamp];

        chatCards.push(
          <Card key={cardKey} className={classNames(cardClasses)}>
            <CardContent
              key={cardContentKey}
              className={classNames(contentClasses)}
            >
              <Input
                key={cardMsgKey}
                fullWidth={true}
                inputProps={{
                  style: noResizeStyle
                }}
                inputComponent={Textarea}
                className={cardMsg}
                value={chat[1].chatMessage}
                disableUnderline={true}
              />
              <div style={{ display: "flex" }}>
                <Typography
                  key={cardMetaKey}
                  className={classNames(cardTimestamp)}
                  noWrap
                  variant="body2"
                >
                  {this.moment(chat[1].sendTime)
                    .tz(this.timezone)
                    .format("hh:mm A z")}{" "}
                  - {chat[1].senderName}
                </Typography>
              </div>
            </CardContent>
          </Card>
        );
      }
    });

    //Clear the chat count for the selected user after the chats have been rendered
    this.chatCountMap.set(toUserId, []);

    return (
      <div className={classNames([classes.chatContent, classes.scrollBar])}>
        {chatCards.slice(-50)}
        <div
          style={{ float: "left", clear: "both" }}
          ref={chatEnd => {
            this.chatEnd = chatEnd;
          }}
        />
      </div>
    );
  };

  handleChatSend = (chatMessage, toUserId) => {
    if (chatMessage !== "") {
      this.handleChatApiRequest(toUserId, chatMessage);
      this.props.updateUserPriority(toUserId);
    }
  };

  handleChatApiRequest = (toUserId, chatMessage) => {
    const myUserId = this.props.session.userId;
    const uri = `/users/${myUserId}/chat`;
    const to = toUserId;
    const toFormat = toUserId === 0 ? "to" : "to[]";
    const reqSeq = 1;
    const encodedChatMessage = encodeURIComponent(chatMessage);
    const requestBody = `chat=${encodedChatMessage}&${toFormat}=${to}&reqSeq=${reqSeq}`;

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    try {
      contexWebRest.post(uri, requestBody, {
        headers: headers
      });
    } catch (error) {
      console.log(error);
    }
  };

  getIdByName(userName) {
    for (let [key, value] of this.userIDNameMap) {
      if (value === userName) {
        return key;
      }
    }
    return 0;
  }

  isAnyNewMessage(toUserId) {
    for (let [key, value] of this.chatCountMap) {
      if (key !== toUserId && value.length > 0) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { classes, unreadChats, chatPopoutWindow } = this.props;
    const { open, toUserId } = this.state;
    const unreadChatCount = unreadChats.unreadCount;
    const windowStatus = chatPopoutWindow.windowStatus;

    const chatHeader = (
      <LocalizedText
        value="chat"
        variant="h6"
        className={classes.headerLabel}
      />
    );

    const unreadCountInBadge =
      windowStatus === ChatPopoutState.OPEN && unreadChatCount > 0 ? (
        <Fragment>
          <div className={classes.header}>
            {chatHeader}
            <Badge
              badgeContent={unreadChatCount}
              max={99}
              color="primary"
              classes={{
                root: classes.badgeRoot,
                colorPrimary: classes.badgeColor
              }}
            >
              <Fragment />
            </Badge>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.header}>{chatHeader}</div>
        </Fragment>
      );

    return (
      <div className={classes.root}>
        <Paper
          className={classes.paper}
          style={{ height: "100%", width: "100%" }}
        >
          {!isMobileOrTablet() && this.getDrawerHeader()}
          {unreadCountInBadge}
          <div className={classes.chatSection}>
            {this.getUserNames(open, toUserId)}
            <div className={classes.hrDiv} />
            {this.getChatBubbles(toUserId)}
            <div className={classes.hrDiv} />
            <ChatFooter
              handleChatSend={value => this.handleChatSend(value, toUserId)}
            />
          </div>
        </Paper>
        {this.getClassesForPopout()}
      </div>
    );
  }
}

const mapStateToProps = ({
  session,
  chatPopoutWindow,
  chats,
  users,
  unreadChats,
  userPriority
}) => ({
  session,
  chatPopoutWindow,
  chats,
  users,
  unreadChats,
  userPriority
});

const mapDispatchToProps = dispatch => ({
  showChatPopout: () => dispatch(showChatPopout(chatPopoutWindowName)),
  hideInternalDrawer: () => dispatch(hideInternalDrawer()),
  hideChatPopout: () => dispatch(hideChatPopout()),
  updateChatReadState: (chatId, senderId, status) =>
    dispatch(updateChatReadState(chatId, senderId, status)),
  updateSelectedUserIdForChatCount: userId =>
    dispatch(updateSelectedUserIdForChatCount(userId)),
  updateCurrentSelectedUser: (userId, userName) =>
    dispatch(updateCurrentSelectedUser(userId, userName)),
  updateUserPriority: senderId => dispatch(updateUserPriority(senderId))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        Chat
      )
    )
  )
);
