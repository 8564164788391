import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppLogo from "../Icons/AppLogo";
import { AppDrawerMenu } from "../menus";
import { LogoutModal } from "../modals";
import { ConferenceLockIndicator } from "../statusIndicators";
import ParticipantTalkingIndicator from "../statusIndicators/ParticipantTalkingIndicator";
import { getIntl } from "../../utils";

const styles = theme => ({
  logoDimension: {
    height: "30px",
    maxWidth: "180px",
    objectFit: "contain"
  },
  logo: {
    height: "100%",
    width: "180px",
    display: "flex",
    alignItems: "center",
    flexShrink: 0
  },
  talkersAndHeading: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    color: theme.colors.primaryMainTextColor,
    paddingLeft: "20px",
    paddingRight: "20px",
    overflow: "hidden"
  },
  statusIndicator: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    width: "2em"
  },
  talkerIndicator: {
    width: "30%",
    maxWidth: "160px",
    flexShrink: 0
  },
  heading: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden"
  },
  headingLine: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

class Header extends Component {
  getTalkerPartyNames = () => {
    const { session } = this.props;
    const { talkerPartySet, talkerIndicatorMaxCount } = session;
    let res = [];

    if (talkerPartySet) {
      for (let partyId of talkerPartySet) {
        const partyName = this.getPartyNameById(partyId);

        if (partyName) {
          if (res.length >= talkerIndicatorMaxCount) {
            res.shift();
          }
          res.push(partyName);
        }
      }
    } else {
      return res;
    }

    return res;
  };

  getPartyNameById = partyId => {
    const { participants } = this.props;
    const { merged } = participants;

    if (merged.length === 0) {
      return undefined;
    } else {
      const resPartyObj = merged.find(function (currentPartyObj) {
        return currentPartyObj.partyID === partyId;
      });

      if (resPartyObj) {
        return resPartyObj.name;
      } else {
        return undefined;
      }
    }
  };

  render() {
    const { classes, displayLogout, intl, sharerName } = this.props;
    const { confName, propertyID, agentID } = this.props.session;

    let heading;
    let sharingHeading;
    if (confName) {
      heading = confName;
      if (sharerName) {
        sharingHeading =
          intl.formatMessage(getIntl("sharingHeading")) +
          " (" +
          sharerName +
          ")";
      }
    } else if (propertyID) {
      heading = intl.formatMessage(getIntl("propertyID")) + " " + propertyID;
    } else if (agentID) {
      heading = intl.formatMessage(getIntl("agentID")) + " " + agentID;
    }

    return (
      <AppBar position="fixed">
        <Toolbar disableGutters={true}>
          <AppDrawerMenu />
          <div className={classes.logo}>
            <AppLogo
              classes={{ root: classes.logoDimension }}
              logoType="header"
            />
          </div>

          <div className={classes.talkersAndHeading}>
            {window.CtxAppConfigurations.customer !== "TCAD" && (
              <div className={classes.talkerIndicator}>
                {this.props.enableTalkerIndicator && (
                  <ParticipantTalkingIndicator
                    fullBorderRadius
                    partyName={this.getTalkerPartyNames()}
                    noPadding
                    noBackground
                    primaryMainTextColor
                  />
                )}
              </div>
            )}
            <div className={classes.heading}>
              <div className={classes.statusIndicator}>
                <ConferenceLockIndicator />
              </div>
              <Typography
                component="div"
                variant="h6"
                color="inherit"
                align="center"
                noWrap
              >
                <div className={classes.headingLine}>{heading}</div>
                <div className={classes.headingLine}>{sharingHeading}</div>
              </Typography>
              <div className={classes.statusIndicator} />
            </div>
            {window.CtxAppConfigurations.customer !== "TCAD" && (
              <div className={classes.talkerIndicator} />
            )}
          </div>

          <div className={classes.logo} />
          {displayLogout && <LogoutModal />}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ session, participants }) => {
  let sharerName;
  if (session.sharingActive) {
    const sharer = participants.merged.find(
      participant => participant.id === session.sharingUserId
    );
    if (sharer) {
      sharerName = sharer.name;
    }
  }

  return {
    session,
    participants,
    sharerName
  };
};

const mapDispatchToProps = dispatch => ({});

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header))
);
