import React, { Component } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import classNames from "classnames";
import ThemeDetailsCard from "./ThemeDetailsCard";

const styles = theme => ({
  root: {
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: theme.colors.popoverBackgroundColor
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px"
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.colors.secondaryMainColor,
      borderRadius: "4px",
      border: "none"
    },
    "&::-webkit-scrollbar-track-piece": {
      background: theme.colors.sideBarBackgroundColor,
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-corner": {
      background: theme.colors.sideBarBackgroundColor
    },
    scrollbarColor: `${theme.colors.secondaryMainColor} ${theme.colors.popoverBackgroundColor}`,
    scrollbarWidth: "thin"
  },
  userPrompt: {
    height: "30px"
  },
  userPromptInfo: {
    color: theme.colors.primaryTextColor
  },
  userPromptError: {
    color: theme.colors.errorMainColor
  },
  userPromptSuccess: {
    color: theme.colors.primaryMainColor
  },
  alignSelfFlexStart: {
    alignSelf: "flex-start"
  }
});

class ThemeView extends Component {
  getThemeDetails = () => {
    const { session } = this.props;
    const { persistentSpaces } = session.portalUser;

    if (persistentSpaces != null) {
      return <ThemeDetailsCard theme={persistentSpaces.persistentSpaceTheme} />;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.root, classes.scrollBar)}>
        {this.getThemeDetails()}
      </div>
    );
  }
}

const mapStateToProps = ({ session, portal }) => ({ session, portal });

const mapDispatchToProps = dispatch => ({});

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ThemeView))
);
